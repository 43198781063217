import { fetchAuthSession } from 'aws-amplify/auth';
import { get } from 'aws-amplify/api';

export const fetchCustomers = async (searchTerm: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'customers',
      path: `/customers${searchTerm ? `?search=${encodeURIComponent(searchTerm)}` : ''}`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch customers:', error);
    throw error;
  }
};