import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { Customer } from '../customers';

const { Text } = Typography;

interface VehicleDetailProps {
  vehicle: {
    id: string;
    applicationModuleId: string;
    customerId: string | null;
    manufacturerId: string | null;
    type: string | null;
    plate: string;
  };
  customers: Customer[]
}

export function VehicleDetail({ vehicle, customers }: VehicleDetailProps) {

  const customer = customers.find((customer) => customer.id === vehicle.customerId);
  const customerName = customer 
    ? `${customer.name} ${customer.surname}` 
    : 'Non disponibile';

  return (
    <Card
      title={`Dettagli Veicolo`}
      bordered={false}
      style={{ margin: '20px 0' }}
    >
      <Descriptions column={{ xs: 1, sm: 2 }}>
        <Descriptions.Item label={<Text strong>Cliente</Text>} span={2}>
          {customerName}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Targa</Text>} span={1}>
          {vehicle.plate}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>ID Produttore</Text>} span={1}>
          {vehicle.manufacturerId || 'Non disponibile'}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Tipo</Text>} span={1}>
          {vehicle.type || 'Non disponibile'}
        </Descriptions.Item>
        {/* <Descriptions.Item label={<Text strong>ID Cliente</Text>} span={1}>
          {vehicle.customerId || 'Non disponibile'}
        </Descriptions.Item> */}
      </Descriptions>
    </Card>
  );
};