import React from 'react';
import { Table, Spin, Space, Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { Customer } from '../customers/types/type';
import { Vehicle, VehicleManufacturer } from './types';
import { ISelect } from '../../types';


interface VehiclesTableProps {
  dataSource: Vehicle[];
  vehicleTypes: ISelect[];
  vehicleManufacturers: VehicleManufacturer[];
  customers: Customer[];
  loading: boolean;
  rowSelection: TableRowSelection<Vehicle>;
  onEdit: (id: string) => void;
  onContact: (id: string) => void;
  onDelete: (id: string) => void;
}

export function VehiclesTable({
  dataSource,
  vehicleTypes,
  vehicleManufacturers,
  customers,
  loading,
  rowSelection,
  onEdit,
  onContact,
  onDelete,
}: VehiclesTableProps): JSX.Element {

  const getVehicleTypeLabel = (typeValue: string) => {
    const typeObject = vehicleTypes.find((type) => type.value === typeValue);

    return typeObject ? typeObject.label : typeValue;
  };

  const getManufacturerLabel = (manufacturerId: string) => {
    return (vehicleManufacturers.find((manufaturer) => manufaturer.id === manufacturerId))?.name;
  };

  const getCustomerName = (customerId: string) => {
    const customer = customers.find((customer) => customer.id === customerId);
    return customer ? `${customer.name} ${customer.surname}` : 'N/D';
  };

  const columns: ColumnProps<Vehicle>[] = [
    {
      title: 'Tipo Veicolo',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => getVehicleTypeLabel(text),
    },
    {
      title: 'Produttore',
      dataIndex: 'manufacturerId',
      key: 'manufacturerId',
      render: (id: string) => getManufacturerLabel(id),
    },
    {
      title: 'Targa',
      dataIndex: 'plate',
      key: 'place',
    },
    {
      title: 'Cliente',
      dataIndex: 'customerId',
      key: 'customerId',
      render: (id: string) => getCustomerName(id),
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record.id)}>Modifica</Button>
          <Button type="link" onClick={() => onContact(record.id)}>Contatta</Button>
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
    }
  ];

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: dataSource.length,
        }}
        rowKey="id"
        rowClassName={(record: Vehicle) => (record.id && `row-${record.id}`)}
        rowSelection={rowSelection}
        size='small'
      />
    </Spin>
  );
}
