import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Card, Col, Modal, Row, Tabs, notification } from 'antd';
import type { FormInstance, TabsProps } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { fetchAuthSession } from 'aws-amplify/auth';
import { get, post, put, del } from 'aws-amplify/api';

import { Supplier, SuppliersSearch, SupplierForm, SuppliersTable } from '../components/suppliers';
import { CirclePlusIcon, EditSupplierIcon, EmailIcon } from '../components/IconWrapper';
import { PageTabs } from '../constants/NavigationTabs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { applicationModuleId } from '../config/config';


const { confirm } = Modal;

export function Suppliers() {
  const [cardActions, setCardActions] = useState<JSX.Element[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const formRef = useRef<FormInstance>(null);

  const { supplierId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const isEditPage = location.pathname.includes('/edit');
  const isNewPage = location.pathname.endsWith('/new');

  const getActiveTabKeyFromPath = () => {
    if (isNewPage) return PageTabs.New;
    return PageTabs.List;
  };

  const [activeTabKey, setActiveTabKey] = useState<string>(getActiveTabKeyFromPath());

  useEffect(() => {
    let actions: JSX.Element[] = [];

    if (isNewPage) {
      actions = [
        <div className='action-icon' onClick={() => handleSubmit()} >
          <CirclePlusIcon size='xl' />
        </div>
      ];
    } else if (isEditPage) {
      actions = [
        <div className='action-icon' onClick={() => handleSubmit()} >
          <EditSupplierIcon size='xl' />
        </div>,
          <div 
          className='action-icon'
          onClick={() => console.log('Copia E-mail')
        }>
          <EmailIcon size='xl' />
        </div>
      ];
    }

    setCardActions(actions);

    if (location.pathname === '/suppliers') {
      fetchSuppliers();
    }

  }, [location.pathname, isNewPage, isEditPage]);

  const fetchSuppliers = async () => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      const operation = get({
        apiName: 'suppliers',
        path: `/suppliers${(searchTerm !== '') ? `?search=${encodeURIComponent(searchTerm)}` : ''}`,
        options: {
          headers: {
            Authorization: accessToken
          }
        }
      });

      const { body } = await operation.response;
      const response = await body.json();

      setSuppliers(response as unknown as Supplier[]);
    } catch (error) {
      console.error('Failed to fetch Suppliers:', error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const createSupplier = async (data: Supplier) => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      data['applicationModuleId'] = applicationModuleId;

      const operation = post({
        apiName: 'suppliers',
        path: '/suppliers',
        options: {
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json'
          },
          body: data as any
        }
      });

      await operation.response;

      notification.success({
        message: 'Fornitore Aggiunto',
        description: 'Il Fornitore è stato aggiunto con successo.',
      });
    } catch (error) {
      console.error('Failed to add Supplier:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'aggiunta del Fornitore.',
      });
    } finally {
      setLoading(false);
    }
  };

  const editSupplier = async (data: Supplier) => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      const operation = put({
        apiName: 'suppliers',
        path: `/suppliers/${supplierId}`,
        options: {
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json'
          },
          body: data as any
        }
      });

      await operation.response;

      notification.success({
        message: 'Fornitore Modificato',
        description: 'Il Fornitore è stato modificato con successo.',
      });
    } catch (error) {
      console.error('Failed to edit Suppliers:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante la modifica del Fornitore.',
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteSupplier = async (id: string) => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';
  
      const operation = del({
        apiName: 'suppliers',
        path: `/suppliers/${id}`,
        options: {
          headers: {
            Authorization: accessToken,
          },
        },
      });

      await operation.response;
  
      notification.success({
        message: 'Fornitore Eliminato',
        description: 'Il Fornitore è stato eliminato con successo.',
      });
  
      fetchSuppliers();
    } catch (error) {
      console.error('Failed to delete Supplier:', error);
      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'eliminazione del Fornitore.',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection: TableRowSelection<Supplier> = {
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    selectedRowKeys,
  };

  const handleClickAdd = (): void => {
    setActiveTabKey(PageTabs.New);
    navigate('/suppliers/new');
  };

  const handleClickEdit = (id: string) => {
    navigate(`/suppliers/edit/${id}`);
  };

  const handleClickContact = (id: string) => {
    console.log("🚀 ~ handleContact:", id)
  };

  const handleClickDelete = (id: string) => {
    confirm({
      title: 'Sei sicuro di voler cancellare questo Fornitore?',
      icon: <ExclamationCircleOutlined />,
      content: 'Questa azione è irreversibile',
      okText: 'Sì, cancella',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await deleteSupplier(id);
      },
      onCancel() {
        console.log('Delete Cancelled');
      },
    });
  };

  const tabItems: TabsProps['items'] = [
    {
      label: 'Lista Fornitori',
      key: PageTabs.List,
      children: (
        <>
          <SuppliersSearch 
            searchTerm={searchTerm} 
            setSearchTerm={setSearchTerm} 
            onSearch={fetchSuppliers}
            onAdd={handleClickAdd}
          />
          <SuppliersTable 
            dataSource={suppliers}
            loading={loading}
            onEdit={handleClickEdit}
            onContact={handleClickContact}
            onDelete={handleClickDelete}
            rowSelection={rowSelection}
          />
        </>
      ),
    },
    {
      label: 'Aggiungi Fornitore',
      key: PageTabs.New
    },
  ];

  const onTabChange: TabsProps['onChange'] = (key: string) => {

    setActiveTabKey(key);

    switch (key) {
      case PageTabs.List:
        navigate('/suppliers');
        break;
      case PageTabs.New:
        navigate('/suppliers/new');
        break;
      default:
        break;
    }
  };

  const handleAddSupplierFormSubmit = async (formData: Supplier) => {
    await createSupplier(formData);
  };

  const handleEditSupplierFormSubmit = async (formData: Supplier) => {
    await editSupplier(formData);
  };

  const handleSubmit = () => {
    formRef.current?.submit();
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title={isEditPage ? 'Modifica Fornitore' : undefined}
          extra={isEditPage ? <Link to="/suppliers">Torna alla lista</Link> : undefined}
          actions={cardActions}
        >
          {!isEditPage && (
            <Tabs defaultActiveKey={PageTabs.List} activeKey={activeTabKey} onChange={onTabChange} items={tabItems} />
          )}
          {isNewPage && <SupplierForm mode='add' ref={formRef} onSubmit={handleAddSupplierFormSubmit} />}
          {isEditPage && <SupplierForm mode='edit' ref={formRef} supplierId={supplierId} initialData={suppliers.find(supplier => supplier.id === supplierId)} onSubmit={handleEditSupplierFormSubmit} />}
        </Card>
      </Col>
    </Row>
  );
}
