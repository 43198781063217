import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row, Tabs } from 'antd';
import type { TabsProps } from 'antd';

import { PageTabs } from '../components/settings/constants/NavigationTabs';
import SettingsUser from '../components/settings/SettingsUser';


export function Settings() {

  // const [loading, setLoading] = useState<boolean>(false);

  // const location = useLocation();
  const navigate = useNavigate();

  // const isUserPage = location.pathname.endsWith('/user');

  const getActiveTabKeyFromPath = () => {
    return PageTabs.User;
  };

  const [activeTabKey, setActiveTabKey] = useState<string>(getActiveTabKeyFromPath());

  const tabItems: TabsProps['items'] = [
    {
      label: 'Utente',
      key: PageTabs.User,
      children: (
        <>
          <SettingsUser></SettingsUser>
        </>
      ),
    },
  ];

  const onTabChange: TabsProps['onChange'] = (key: string) => {

    setActiveTabKey(key);

    switch (key) {
      case PageTabs.User:
        navigate('/settings/user');
        break;
      default:
        break;
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title="Impostazioni"
          // extra={isEditPage ? <Link to="/suppliers">Torna alla lista</Link> : undefined}
          // actions={cardActions}
        >
          <Tabs defaultActiveKey={PageTabs.User} activeKey={activeTabKey} onChange={onTabChange} items={tabItems} style={{ marginBottom: '8px' }} />
        </Card>
      </Col>
    </Row>
  );
}
