import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt, faHandshake, faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faBoxOpen,
  faCar,
  faCircle,
  faCirclePlus,
  faCog,
  faDolly,
  faEnvelope,
  faFileInvoiceDollar,
  faLock,
  faPencilAlt,
  faPlus,
  faSearch,
  faTruckMoving,
  faUserTie,
  faUsers,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';


interface IconWrapperProps {
  size?: SizeProp;
  style?: CSSProperties;
}

interface IconProps {
  icon: IconDefinition;
  size?: SizeProp;
  style?: CSSProperties;
}

export const Icon: React.FC<IconProps> = ({ icon, style, ...props }) => (
  <FontAwesomeIcon icon={icon} style={style} {...props} />
);

export const AppointmentsCalendarIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faCalendarAlt} {...props} />;
export const ArticlesIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faBoxOpen} {...props} />;
export const CirclePlusIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faCirclePlus} {...props} />;
export const CreateQuoteIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faFileInvoiceDollar} {...props} />;
export const DeliveriesIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faDolly} {...props} />;
export const EmailIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faEnvelope} {...props} />;
export const OperatorIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faUserTie} {...props} />;
export const PartnershipIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faHandshake} {...props} />;
export const PasswordIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faLock} {...props} />;
export const PlusIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faPlus} {...props} />;
export const SearchIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faSearch} {...props} />;
export const SettingsIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faCog} {...props} />;
export const SuppliersIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faTruckMoving} {...props} />;
export const TyreListIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faCircle} {...props} />;
export const UserIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faUser} {...props} />;
export const UsersIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faUsers} {...props} />;
export const VehiclesIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faCar} {...props} />;
export const WarehouseIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => <Icon style={{...style}} icon={faWarehouse} {...props} />;

export const AddUserIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => (
  <div style={{ display: 'inline-flex', alignItems: 'center', ...style }}>
    <Icon icon={faUser} {...props} />
    <Icon icon={faPlus} style={{ fontSize: '0.75em', marginLeft: '2px' }} {...props} />
  </div>
);
export const EditUserIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => (
  <div style={{ display: 'inline-flex', alignItems: 'center', ...style }}>
    <Icon icon={faUser} {...props} />
    <Icon icon={faPencilAlt} style={{ fontSize: '0.75em', marginLeft: '2px' }} {...props} />
  </div>
);
export const AddVehicleIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => (
  <div style={{ display: 'inline-flex', alignItems: 'center', ...style }}>
    <Icon icon={faCar} {...props} />
    <Icon icon={faPlus} style={{ fontSize: '0.75em', marginLeft: '2px' }} {...props} />
  </div>
);
export const EditSupplierIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => (
  <div style={{ display: 'inline-flex', alignItems: 'center', ...style }}>
    <Icon icon={faTruckMoving} {...props} />
    <Icon icon={faPlus} style={{ fontSize: '0.75em', marginLeft: '2px' }} {...props} />
  </div>
);
export const EditVehicleIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => (
  <div style={{ display: 'inline-flex', alignItems: 'center', ...style }}>
    <Icon icon={faCar} {...props} />
    <Icon icon={faPencilAlt} style={{ fontSize: '0.75em', marginLeft: '2px' }} {...props} />
  </div>
);
export const EditWarehouseIcon: React.FC<IconWrapperProps> = ({ style, ...props }) => (
  <div style={{ display: 'inline-flex', alignItems: 'center', ...style }}>
    <Icon icon={faWarehouse} {...props} />
    <Icon icon={faPencilAlt} style={{ fontSize: '0.75em', marginLeft: '2px' }} {...props} />
  </div>
);