import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Card, Col, Modal, Row, Tabs, notification } from 'antd';
import type { FormInstance, TabsProps } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { 
  fetchCustomers,
  fetchVehicles,
  fetchVehicleManufacturers,
  createVehicle,
  editVehicle,
  deleteVehicle,
  createVehicleManufacturer,
} from '../api/index';
import { Vehicle, VehiclesSearch, VehicleForm, VehiclesTable, VehicleManufacturer } from '../components/vehicles';
import { Customer } from '../components/customers';
import { CirclePlusIcon, EditVehicleIcon } from '../components/IconWrapper';
import { PageTabs } from '../constants/NavigationTabs';
import VehicleTypesSelectValues from '../components/vehicles/constants';


const { confirm } = Modal;

export function Vehicles() {
  const [cardActions, setCardActions] = useState<JSX.Element[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [vehicleManufacturers, setVehicleManufacturers] = useState<VehicleManufacturer[]>([]);
  const [vehicleManufacturer, setVehicleManufacturer] = useState<VehicleManufacturer | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(false);

  // const [selectedCustomer, setSelectedCustomer] = useState<string | undefined>(undefined);

  const formRef = useRef<FormInstance>(null);

  const vehicleTypes = VehicleTypesSelectValues;

  const { vehicleId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const isEditPage = location.pathname.includes('/edit');
  const isNewPage = location.pathname.endsWith('/new');

  const getActiveTabKeyFromPath = () => {
    if (isNewPage) return PageTabs.New;
    return PageTabs.List;
  };

  const [activeTabKey, setActiveTabKey] = useState<string>(getActiveTabKeyFromPath());

  useEffect(() => {
    let actions: JSX.Element[] = [];

    if (isNewPage) {
      actions = [
        <div className='action-icon' onClick={() => handleSubmit()} >
          <CirclePlusIcon size='xl' />
        </div>
      ];
    } else if (isEditPage) {
      actions = [
        <div className='action-icon' onClick={() => handleSubmit()} >
          <EditVehicleIcon size='xl' />
        </div>
      ];
    }

    setCardActions(actions);

    if (location.pathname === '/vehicles') {
      _fetchVehicles();
    }

  }, [location.pathname, isNewPage, isEditPage]);

  const _fetchCustomers = async () => {
    const response = await fetchCustomers('');

    setCustomers(response as unknown as Customer[]);
  };

  const _fetchVehicles = async () => {
    setLoading(true);

    const response = await fetchVehicles(searchTerm);

    setVehicles(response as unknown as Vehicle[]);

    setLoading(false);
  };

  const _fetchVehicleManufacturers = async () => {
    const response = await fetchVehicleManufacturers();

    setVehicleManufacturers(response as unknown as VehicleManufacturer[]);
  };

  useEffect(() => {
    _fetchCustomers();
    _fetchVehicleManufacturers();

    _fetchVehicles();
  }, []);

  const _createVehicle = async (data: Vehicle) => {
    setLoading(true);

    try {      
      const vehicleCreated = await createVehicle(data);

      if (vehicleCreated) {
        notification.success({
          message: 'Veicolo Aggiunto',
          description: 'Il Veicolo è stato aggiunto con successo.',
        });
      }

    } catch (error) {
      console.error('Failed to add Vehicle:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'aggiunta del Veicolo.',
      });
    } finally {
      setLoading(false);
    }
  };

  const _editVehicle = async (data: Vehicle) => {

    if (vehicleId) {
      
      setLoading(true);

      try {      
        const vehicleEdited = await editVehicle(vehicleId, data);
  
        if (vehicleEdited) {
          notification.success({
            message: 'Veicolo Modificato',
            description: 'Il Veicolo è stato modificato con successo.',
          });
  
          await _fetchVehicles();
        }
  
      } catch (error) {
        console.error('Failed to edit Vehicles:', error);

        notification.error({
          message: 'Errore',
          description: 'Si è verificato un errore durante la modifica del Veicolo.',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const _deleteVehicle = async (id: string) => {
    setLoading(true);

    try {      
      const vehicleDeleted = await deleteVehicle(id);

      if (vehicleDeleted) {
        notification.success({
          message: 'Veicolo Eliminato',
          description: 'Il Veicolo è stato eliminato con successo.',
        });

        await _fetchVehicles();
      }

    } catch (error) {
      console.error('Failed to delete Vehicle:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'eliminazione del Veicolo.',
      });
    } finally {
      setLoading(false);
    }
  };

  const _createVehicleManufacturer = async (name: string) => {
    
    setLoading(true);

    try {      
      const vehicleManufacturerCreated = (await createVehicleManufacturer({ name, country: '' }))[0];
      if (vehicleManufacturerCreated) {
        notification.success({
          message: 'Marca Veicolo Aggiunta',
          description: 'La marca del Veicolo è stata aggiunta con successo.',
        });

        await _fetchVehicleManufacturers();

        setVehicleManufacturer(vehicleManufacturerCreated as unknown as VehicleManufacturer);
      }

    } catch (error) {
      console.error('Failed to edit Tyre:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'aggiunta della Marca del Veicolo.',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection: TableRowSelection<Vehicle> = {
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    selectedRowKeys,
  };

  const handleClickAdd = (): void => {
    setActiveTabKey(PageTabs.New);
    navigate('/vehicles/new');
  };

  const handleClickAddTyre = (): void => {
    const keys = rowSelection.selectedRowKeys ?? [];

    if (keys.length === 1) {
      const vehicleId = keys[0].toString();
      const vehicle = vehicles.find(vehicle => vehicle.id === vehicleId);

      navigate('/tyres/new', { state: { vehicle: vehicle } });
    } else {
      navigate('/tyres/new');
    }
  };

  const handleClickEdit = (id: string) => {
    navigate(`/vehicles/edit/${id}`);
  };

  const handleClickContact = (id: string) => {
    console.log("🚀 ~ handleContact:", id)
  };

  const handleClickDelete = (id: string) => {
    confirm({
      title: 'Sei sicuro di voler cancellare questo Veicolo?',
      icon: <ExclamationCircleOutlined />,
      content: 'Questa azione è irreversibile',
      okText: 'Sì, cancella',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        if (id) { await _deleteVehicle(id); }
      },
      onCancel() {
        console.log('Delete Cancelled');
      },
    });
  };

  const tabItems: TabsProps['items'] = [
    {
      label: 'Lista Veicoli',
      key: PageTabs.List,
      children: (
        <>
          <VehiclesSearch 
            searchTerm={searchTerm} 
            setSearchTerm={setSearchTerm} 
            onSearch={_fetchVehicles}
            onAdd={handleClickAdd}
            onAddTyre={handleClickAddTyre}
          />
          <VehiclesTable 
            dataSource={vehicles}
            vehicleTypes={vehicleTypes}
            vehicleManufacturers={vehicleManufacturers}
            customers={customers}
            loading={loading}
            onEdit={handleClickEdit}
            onContact={handleClickContact}
            onDelete={handleClickDelete}
            rowSelection={rowSelection}
          />
        </>
      ),
    },
    {
      label: 'Aggiungi Veicolo',
      key: PageTabs.New
    },
  ];

  const onTabChange: TabsProps['onChange'] = (key: string) => {

    setActiveTabKey(key);

    switch (key) {
      case PageTabs.List:
        navigate('/vehicles');
        break;
      case PageTabs.New:
        navigate('/vehicles/new');
        break;
      default:
        break;
    }
  };

  const handleAddVehicleFormSubmit = async (formData: Vehicle) => {
    await _createVehicle(formData);
  };

  const handleEditVehicleFormSubmit = async (formData: Vehicle) => {
    await _editVehicle(formData);
  };

  const handleSubmit = () => {
    formRef.current?.submit();
  };

  const handleAddNewVehicleManufacturer = (name: string): void => {
    _createVehicleManufacturer(name);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title={isEditPage ? 'Modifica Veicolo' : undefined}
          extra={isEditPage ? <Link to="/vehicles">Torna alla lista</Link> : undefined}
          actions={cardActions}
        >
          {!isEditPage && (
            <Tabs defaultActiveKey={PageTabs.List} activeKey={activeTabKey} onChange={onTabChange} items={tabItems} />
          )}

          {isNewPage && 
            <VehicleForm 
              mode='add' 
              ref={formRef} 
              customers={customers}
              vehicleTypes={vehicleTypes}
              vehicleManufacturers={vehicleManufacturers}
              onSubmit={handleAddVehicleFormSubmit} 
              onAddNewVehicleManufacturer={handleAddNewVehicleManufacturer}
              vehicleManufacturer={vehicleManufacturer} />}

          {isEditPage && 
            <VehicleForm 
              mode='edit' 
              ref={formRef} 
              vehicleId={vehicleId} 
              initialData={vehicles.find(vehicle => vehicle.id === vehicleId)} 
              customers={customers}
              vehicleTypes={vehicleTypes}
              vehicleManufacturers={vehicleManufacturers}
              onSubmit={handleEditVehicleFormSubmit}
              onAddNewVehicleManufacturer={handleAddNewVehicleManufacturer}
              vehicleManufacturer={vehicleManufacturer} />}
        </Card>
      </Col>
    </Row>
  );
}
