import React , { useState, useEffect }from 'react';
import { Form, Input, Button } from 'antd';
import { EditUser } from '../types';


interface EditUserFormProps {
  onSubmit: (values: EditUser) => void;
  initialValues?: EditUser;
}

const EditUserForm: React.FC<EditUserFormProps> = ({ onSubmit, initialValues }) => {

  const [form] = Form.useForm();

  const [initialValuesFromProps, setInitialValuesFromProps] = useState<EditUser | undefined>(undefined);
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      setInitialValuesFromProps(initialValues);
      setIsSubmitting(true);
    }
  }, [initialValues, form]);

  const onFinish = (values: EditUser) => {
    onSubmit(values);
  };

  const handleNameChange = (value: string) => {
    const editedName = value !== initialValuesFromProps?.name;
    const editedSurname = form.getFieldValue('surname') !== initialValuesFromProps?.surname;

    setIsSubmitting(!(editedName || editedSurname));
  };

  const handleSurnameChange = (value: string) => {
    const editedSurname = value !== initialValuesFromProps?.surname;
    const editedName = form.getFieldValue('name') !== initialValuesFromProps?.name;

    setIsSubmitting(!(editedName || editedSurname));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="edit_user_form"
      onFinish={onFinish}
    >
      <Form.Item
        name="name"
        label="Name"
      >
        <Input onChange={(e) => handleNameChange(e.target.value)} />
      </Form.Item>
      <Form.Item
        name="surname"
        label="Surname"
      >
        <Input onChange={(e) => handleSurnameChange(e.target.value)} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isSubmitting}>
          Modifica Utente
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditUserForm;
