import React from 'react';
import { Alert, Button, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

// export interface ChangePasswordInterface {
//   password: string;
//   newPassword: string;
// }

interface FirstAccessFormProps {
  // onLogin: (values: { email: string; password: string }) => Promise<void>;
  onChangePassword: (values: {
    oldPassword: string;
    newPassword: string;
  }) => Promise<void>;

  errorMessage?: string;
}

export const FirstAccessForm: React.FC<FirstAccessFormProps> = ({
  onChangePassword,
  errorMessage,
}) => {
  const onFinish = (values: { password: string; newPassword: string }) => {
    onChangePassword({
      oldPassword: values.password,
      newPassword: values.newPassword,
    });
  };

  return (
    <>
      <h2>Primo accesso: Cambia Password</h2>
      
      <Form name="first_access_form" layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
          label="Vecchia Password"
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          rules={[{ required: true, message: 'Please input your New Password!' }]}
          label="Nuova Password"
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <div style={{ minHeight: '50px', margin: '8px 0 16px' }}>
          <Alert
            message={errorMessage || ' '}
            type="error"
            style={{ visibility: errorMessage ? 'visible' : 'hidden', margin: 0 }}
            showIcon
          />
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="first-access-form-button"
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
