import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { ISelect } from '../../types';
import { Vehicle, VehicleManufacturer } from './types';
import { Customer } from '../customers';


interface VehicleFormProps {
  mode: 'add' | 'edit';
  onSubmit: (values: Vehicle) => void;
  onAddNewVehicleManufacturer: (values: string) => void;
  vehicleId?: string;
  initialData?: Vehicle;
  customers: Customer[];
  vehicleManufacturers: VehicleManufacturer[];
  vehicleTypes?: ISelect[];
  vehicleManufacturer: VehicleManufacturer | undefined;
}

export const VehicleForm = forwardRef<FormInstance, VehicleFormProps>(({
  mode,
  onSubmit,
  onAddNewVehicleManufacturer,
  vehicleId,
  initialData,
  customers,
  vehicleManufacturers,
  vehicleTypes,
  vehicleManufacturer
}, ref) => {
  const [form] = Form.useForm();

  const [newVehicleManufacturer, setNewVehicleManufacturer] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string | undefined>(undefined);
  const [selectedVehicleManufacturer, setSelectedVehicleManufacturer] = useState<string | undefined>(undefined);

  useImperativeHandle(ref, () => form);

  const onFinish = (values: Vehicle) => {
    onSubmit(values);
  };

  const onDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      setNewVehicleManufacturer('');
    }
  };

  const handleAddNewOption = () => {
    onAddNewVehicleManufacturer(newVehicleManufacturer);
    setNewVehicleManufacturer('');
    setDropdownVisible(false);
  };

  useEffect(() => {
    if (vehicleManufacturer && vehicleManufacturer.id) {
      form.setFieldsValue({ manufacturerId: vehicleManufacturer.id });
      setSelectedVehicleManufacturer(vehicleManufacturer.id);
    }
  }, [form, vehicleManufacturer]);

  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 };
  const rowStyle = { marginTop: '8px', marginBottom: '8px' };

  return (
    <Form
      form={form}
      initialValues={ vehicleId ? initialData : undefined }
      layout="vertical"
      name="add_vehicle_form"
      onFinish={onFinish}
    >
      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item name="type" label="Tipologia Veicolo">
            <Select options={vehicleTypes} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="manufacturerId" label="Casa Automobilistica">
            <Select
              placeholder="Seleziona o aggiungi un marchio"
              onChange={(value) => setSelectedVehicleManufacturer(value)}
              onDropdownVisibleChange={onDropdownVisibleChange}
              dropdownRender={menu => (
                <>
                  {menu}
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <Input value={newVehicleManufacturer} onChange={e => setNewVehicleManufacturer(e.target.value)} style={{ width: 'calc(100% - 60px)', marginRight: 8 }} />
                    <Button type="primary" onClick={handleAddNewOption} disabled={!newVehicleManufacturer.trim()}>
                      Aggiungi
                    </Button>
                  </div>
                </>
              )}
              value={selectedVehicleManufacturer}
            >
              {vehicleManufacturers.map((manufacturer: VehicleManufacturer) => (
                <Select.Option key={manufacturer.id} value={manufacturer.id}>
                  {manufacturer.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item name="plate" label="Targa" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="customerId" label="Cliente">
            <Select
              placeholder="Seleziona un cliente"
              onChange={(value) => setSelectedCustomer(value)}
              value={selectedCustomer}
            >
              {customers.map((customer: Customer) => (
                <Select.Option key={customer.id} value={customer.id}>
                  {customer.name} {customer.surname}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
