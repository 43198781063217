import React from 'react';
import { Card, Typography, Progress } from 'antd';
import { WarehouseStats } from '../types';

import './WarehousesStats.css';


interface WarehouseAvailabilityCardProps {
  stats: WarehouseStats;
}

const { Text } = Typography;

export const WarehouseAvailabilityCard = ({ stats }: WarehouseAvailabilityCardProps) => {
  const { total_slots: totalSlots, occupied_slots: occupiedSlots, free_slots: freeSlots } = stats;

  const occupiedPercentage = Math.round((occupiedSlots / totalSlots) * 100);
  const freePercentage = 100 - occupiedPercentage;

  return (
    <Card title="Disponibilità del Magazzino" className='stats-card' style={{ flex: 1 }}>
      <Text strong type="secondary">Slot Occupati</Text>
      <Progress percent={occupiedPercentage} status="exception" format={percent => <strong>{percent}%</strong>} />
      <Text strong type="secondary">Slot Liberi</Text>
      <Progress percent={freePercentage} status="success" format={percent => <strong>{percent}%</strong>} />
    </Card>
  );
};
