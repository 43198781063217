import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Modal, Row, Tabs, notification } from 'antd';
import type { FormInstance, TabsProps } from 'antd';
import { Rule } from 'antd/lib/form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { fetchAuthSession } from 'aws-amplify/auth';
import { del, get, post, put } from 'aws-amplify/api';

import { CreateWarehousePayload, RackConfiguration, UpdateWarehousePayload, Warehouse, WarehouseForm, WarehouseEditForm, WarehouseWithStats, WarehousesSearch, WarehousesStats, WarehousesTable, Rack, CreateRackPayload } from '../components/warehouses';
import { CirclePlusIcon, EditWarehouseIcon } from '../components/IconWrapper';
import { PageTabs } from '../constants/NavigationTabs';
import { getWarehouseDetails } from '../api';
import { applicationModuleId } from '../config/config';


const showCleanOrphans: boolean = false;

export const makeRackNameIsUnique = (warehouseId: string) => {
  return async (rule: Rule, rackName: string): Promise<void> => {
    try {
      if (!rackName) {
        return Promise.reject('Il nome della rastrelliera è richiesto');
      }
  
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';
  
      const operation = await get({
        apiName: 'warehouses',
        path: `/warehouses/${warehouseId}/rack/check-unique?name=${encodeURIComponent(rackName)}`,
        options: {
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json'
          },
        }
      });
  
      const { body } = await operation.response;
      const response = await body.json();
  
      if (response === null || typeof response !== 'object') {
        throw new Error('Invalid response format');
      }
  
      if ('isUnique' in response) {
        return response.isUnique ? Promise.resolve() : Promise.reject('Il nome inserito è già stato utilizzato.');
      }
  
      // return Promise.resolve();
    } catch (error) {
      console.error('Failed to check rack name uniqueness:', error);
      
      throw new Error('Errore durante il controllo del nome');
    }
  };
};

const { confirm } = Modal;

export function Warehouses() {
  const [cardActions, setCardActions] = useState<JSX.Element[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [warehouses, setWarehouses] = useState<WarehouseWithStats[]>([]);
  const [currentWarehouse, setCurrentWarehouse] = useState<WarehouseWithStats | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const formRef = useRef<FormInstance>(null);

  const { warehouseId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const isEditPage = location.pathname.includes('/edit');
  const isNewPage = location.pathname.endsWith('/new');

  const getActiveTabKeyFromPath = () => {
    if (isNewPage) return PageTabs.New;
    return PageTabs.List;
  };

  const [activeTabKey, setActiveTabKey] = useState<string>(getActiveTabKeyFromPath());

  useEffect(() => {
    let actions: JSX.Element[] = [];

    if (isNewPage) {
      actions = [
        <div className='action-icon' onClick={() => handleSubmit()} >
          <CirclePlusIcon size='xl' />
        </div>
      ];
    } else if (isEditPage) {
      actions = [
        <div className='action-icon' onClick={() => handleSubmit()} >
          <EditWarehouseIcon size='xl' />
        </div>,
      ];
    }

    if (showCleanOrphans) {
      actions.push(
        <Button key="clean-orphans" onClick={cleanOrphans} loading={loading}>
          Pulisci Orfani
        </Button>
      );
    }

    setCardActions(actions);

    if (location.pathname === '/warehouse') {
      fetchWarehouses();
    }

  }, [location.pathname, isNewPage, isEditPage]);

  const fetchWarehouses = async () => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      const operation = get({
        apiName: 'warehouses',
        path: `/warehouses${(searchTerm !== '') ? `?search=${encodeURIComponent(searchTerm)}` : ''}`,
        options: {
          headers: {
            Authorization: accessToken
          }
        }
      });

      const { body } = await operation.response;
      const response = await body.json();

      setWarehouses(response as unknown as WarehouseWithStats[]);
    } catch (error) {
      console.error('Failed to fetch warehouses:', error);
    }

    setLoading(false);
  };

  const _getWarehouseDetails = async (warehouseId: string) => {
    setLoading(true);

    try {
      const response = await getWarehouseDetails(warehouseId);

      if (response && Array.isArray(response) && response.length > 0) {
        const firstItem = response[0];

        if (typeof firstItem === 'object' && firstItem !== null && 'Warehouse' in firstItem) {
          const warehouseDetails = firstItem.Warehouse;

          if (typeof warehouseDetails === 'object' && warehouseDetails !== null) {
            const warehouse: any = {
              ...warehouseDetails,
              racks: [],
              islands: []
            };

            const racksInfo: Rack[] = [];

            response.forEach((item: any) => {
              const { id: rackId, name: rackName, totalSlots: rackSlots, type: rackType } = item.Rack;

              racksInfo.push({
                id: rackId,
                name: rackName,
                type: rackType,
                slots: rackSlots,
              });
            });

            racksInfo.forEach((item: Rack) => {
              switch (item.type) {
                case 'standard':
                  warehouse.racks.push(item);
                  break;
                case 'island':
                  warehouse.islands.push(item);
                  break;
                default:
                  break;
              }
            });

            if (warehouse.racks.length === 0) {
              warehouse.racks.push({
                name: undefined,
                slots: 0
              });
            }

            if (warehouse.islands.length === 0) {
              warehouse.islands.push({
                name: undefined,
                slots: 0
              });
            }

            setCurrentWarehouse(warehouse);
          }
        }
      } else {
        console.error('Empty response or response format is not as expected');
      }
    } catch (error) {
      console.error('Failed to fetch warehouse details:', error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchWarehouses();
  }, []);

  useEffect(() => {
    const loadWarehouseDetails = async () => {
      if (isEditPage && warehouseId) {
        await _getWarehouseDetails(warehouseId);
      }
    };
  
    loadWarehouseDetails();
  }, [isEditPage, warehouseId]);

  // useEffect(() => {

  //   // const selectedWarehouse = warehouses.find(warehouse => warehouse.id === warehouseId);
  //   // console.log("🚀 ~ selectedWarehouse:", selectedWarehouse)

  //   // noop

  //   // const loadWarehouseDetails = async () => {
  //   //   if ((isShowPage || isEditPage) && warehouseId) {
  //   //     await getWarehouseDetails(warehouseId);
  //   //   }
  //   // };
  
  //   // loadWarehouseDetails();

  // }, [isShowPage, warehouseId]);

  const createWarehouse = async (data: CreateWarehousePayload) => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      const operation = post({
        apiName: 'warehouses',
        path: '/warehouses',
        options: {
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json'
          },
          body: data as any
        }
      });

      await operation.response;

      notification.success({
        message: 'Magazzino Aggiunto',
        description: 'Il Magazzino è stato aggiunto con successo.',
      });
    } catch (error) {
      console.error('Failed to add Warehouse:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'aggiunta del Magazzino.',
      });
    } finally {
      setLoading(false);
    }
  };

  const editWarehouse = async (data: UpdateWarehousePayload) => {

    // setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      const operation = put({
        apiName: 'warehouses',
        path: `/warehouses/${warehouseId}`,
        options: {
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json'
          },
          body: { ...data.warehouseDetails } as any
        }
      });

      await operation.response;

      if (!data.hasRackInfo) {
        notification.success({
          message: 'Magazzino Modificato',
          description: 'Il Magazzino è stato modificato con successo.',
        });
      }

      navigate('/warehouse');

      // if (warehouseId) {
      //   await _getWarehouseDetails(warehouseId)
      // }
    } catch (error) {
      console.error('Failed to edit Warehouse:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante la modifica del Magazzino.',
      });
    }
  };

  const deleteWarehouse = async (id: string) => {
    setLoading(true);
  
    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';
    
      const operation = del({
        apiName: 'warehouses',
        path: `/warehouses/${id}`,
        options: {
          headers: {
            Authorization: accessToken,
          },
        },
      });
  
      await operation.response;
    
      notification.success({
        message: 'Magazzino Eliminato',
        description: 'Il Magazzino è stato eliminato con successo.',
      });
    
      fetchWarehouses();
    } catch (error) {
      console.error('Failed to delete Warehouse:', error);
      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'eliminazione del Magazzino.',
      });
    } finally {
      setLoading(false);
    }
  };

  const createRacks = async (data: CreateRackPayload) => {
    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      const operation = post({
        apiName: 'warehouses',
        path: `/warehouses/${warehouseId}/rack`,
        options: {
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json'
          },
          body: { ...data } as any
        }
      });

      await operation.response;

      notification.success({
        message: 'Magazzino Modificato',
        description: 'Il Magazzino è stato modificato con successo.',
      });
    } catch (error) {
      console.error('Failed to edit Warehouse:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante la modifica del Magazzino.',
      });
    }
  };

  const editRackName = async (rackId: string, data: Partial<Rack>) => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      const operation = put({
        apiName: 'warehouses',
        path: `/warehouses/${warehouseId}/rack/${rackId}`,
        options: {
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json'
          },
          body: data as any
        }
      });

      await operation.response;

      notification.success({
        message: 'Rack Modificato',
        description: 'Il Rack è stato modificato con successo.',
      });
    } catch (error) {
      console.error('Failed to edit Rack:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante la modifica del Rack.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClickAdd = (): void => {
    setActiveTabKey(PageTabs.New);
    navigate('/warehouse/new');
  };

  const handleClickShow = (id: string) => {
    navigate(`/warehouse/show/${id}`);
  };
  
  const handleClickEdit = (id: string) => {
    navigate(`/warehouse/edit/${id}`);
  };

  const handleClickEditRackName = (id: string, name: string) => {
    confirm({
      title: 'Sei sicuro di voler modificare il nome?',
      icon: <ExclamationCircleOutlined />,
      content: 'Questa azione è irreversibile',
      okText: 'Sì, modifica',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await editRackName(id, { name });
      },
      async onCancel() {
        console.log('Edit Rack Cancelled');
      },
    });
  };

  const handleClickDelete = (id: string) => {
    confirm({
      title: 'Sei sicuro di voler cancellare questo Magazzino?',
      icon: <ExclamationCircleOutlined />,
      content: 'Questa azione è irreversibile',
      okText: 'Sì, cancella',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await deleteWarehouse(id);
      },
      onCancel() {
        console.log('Delete Cancelled');
      },
    });
  };

  const tabItems: TabsProps['items'] = [
    {
      label: 'Dati Magazzini',
      key: PageTabs.List,
      children: (
        <>
          <WarehousesSearch 
            searchTerm={searchTerm} 
            setSearchTerm={setSearchTerm} 
            onSearch={fetchWarehouses}
            onAdd={handleClickAdd}
          />
          <WarehousesTable 
            dataSource={warehouses}
            loading={loading}
            onShow={handleClickShow}
            onEdit={handleClickEdit}
            onDelete={handleClickDelete}
          />
        </>
      ),
    },
    {
      label: 'Stats Magazzino',
      key: PageTabs.Stats,
      children: (
        <>
          <WarehousesStats 
            warehouses={warehouses}
            onShow={handleClickShow}
            onEdit={handleClickEdit}
          />
        </>
      ),
    },
    {
      label: 'Aggiungi Magazzino',
      key: PageTabs.New
    },
  ];

  const onTabChange: TabsProps['onChange'] = (key: string) => {

    setActiveTabKey(key);

    switch (key) {
      case PageTabs.List:
        navigate('/warehouse');
        break;
      case PageTabs.New:
        navigate('/warehouse/new');
        break;
      case PageTabs.Stats:
        navigate('/warehouse/stats');
        break;
      default:
        break;
    }
  };

  const handleAddWarehouseFormSubmit = async (formData: Warehouse) => {

    const { name, address, city, cap, province, region, nation, defaultSlotCost, defaultSaleSlotCost, racks, islands } = formData;

    const warehouseDetails = {
      name,
      address,
      city,
      cap,
      province,
      region,
      nation,
      defaultSlotCost,
      defaultSaleSlotCost,
      applicationModuleId: applicationModuleId
    };
  
    const racksInfo = [
      ...racks
        .filter((rack: RackConfiguration) => Object.values(rack).every(value => value !== undefined && value !== 0))
        .map((rack: RackConfiguration) => ({ type: "standard", total: rack.total, slots: rack.slots })),
      ...islands
        .filter((island: RackConfiguration) => Object.values(island).every(value => value !== undefined && value !== 0))
        .map((island: RackConfiguration) => ({ type: "island", total: island.total, slots: island.slots }))
    ];
  
    await createWarehouse({
      warehouseDetails,
      racksInfo
    });
  };

  const handleEditWarehouseFormSubmit = async (formData: Warehouse) => {

    if (!loading) {
      setLoading(true);
    }

    const { name, address, city, cap, province, region, nation, defaultSlotCost, defaultSaleSlotCost, racks, islands } = formData;

    const warehouseDetails = {
      name,
      address,
      city,
      cap,
      province,
      region,
      nation,
      defaultSlotCost,
      defaultSaleSlotCost
    };

    const racksInfo = [
      ...racks
        .filter((rack: RackConfiguration) => Object.values(rack).every(value => value !== undefined && value !== 0 && !('id' in rack)))
        .map((rack: RackConfiguration) => ({ type: "standard", name: rack.name || '', totalSlots: rack.slots, ...rack })),
      ...islands
        .filter((island: RackConfiguration) => Object.values(island).every(value => value !== undefined && value !== 0 && !('id' in island)))
        .map((island: RackConfiguration) => ({ type: "island", name: island.name || '', totalSlots: island.slots, ...island }))
    ];

    await editWarehouse({ warehouseDetails, hasRackInfo: !!racksInfo.length });

    if (racksInfo.length > 0) {
      await createRacks({
        racksInfo
      });
    }
    
    if (loading) {
      setLoading(false);
    }
  };

  const cleanOrphans = async () => {
    setLoading(true);
    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      const operation = get({
        apiName: 'warehouses',
        path: '/warehouses/clean-orphans',
        options: {
          headers: {
            Authorization: accessToken,
          },
        },
      });

      await operation.response;

      notification.success({
        message: 'Pulizia Completata',
        description: 'Gli orfani sono stati eliminati con successo dal database.',
      });
    } catch (error) {
      console.error('Failed to clean orphans:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante la pulizia degli orfani.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    formRef.current?.submit();
  };
  
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title={isEditPage ? 'Modifica Magazzino' : undefined}
          extra={isEditPage ? <Link to="/warehouse">Torna alla lista</Link> : undefined}
          actions={cardActions}
        >
          {!isEditPage && (
            <Tabs activeKey={activeTabKey} onChange={onTabChange} items={tabItems} />
          )}          
          {isNewPage && <WarehouseForm ref={formRef} onSubmit={handleAddWarehouseFormSubmit} />}
          {isEditPage && <WarehouseEditForm ref={formRef} warehouseId={warehouseId} initialData={currentWarehouse} onEdit={handleClickEditRackName} onSubmit={handleEditWarehouseFormSubmit} />}
        </Card>
      </Col>
    </Row>
  );
}
