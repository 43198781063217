import React from 'react';
import { Table, Spin, Space, Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { Tyre, TyreBrand } from './types';
import { Vehicle } from '../vehicles';
// import { TyreSeason } from './enums/TyreSeasons';
import { ISelect } from '../../types';


interface TyresTableProps {
  dataSource: Tyre[];
  tyreBrands: TyreBrand[];
  vehicles: Vehicle[];
  tyreSeasons: ISelect[];
  loading: boolean;
  rowSelection: TableRowSelection<Tyre>;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export function TyresTable({
  dataSource,
  tyreBrands,
  tyreSeasons,
  vehicles,
  loading,
  rowSelection,
  onEdit,
  onDelete,
}: TyresTableProps): JSX.Element {

  const getVehiclePlate = (vehicleId?: string) => {

    const vehicleObject = vehicles.find((vehicle) => vehicle.id === vehicleId);

    return vehicleObject?.plate;
  };

  const getTyreBrandLabel = (brandId: string) => {

    const manufacturerObject = tyreBrands.find((brand) => brand.id === brandId);

    return manufacturerObject?.name;
  };

  // TODO: Implement getSeasonIcon
  const getSeasonLabel = (seasonValue: string) => {

    const seasonObject = tyreSeasons.find((season) => season.value === seasonValue);
    
    return seasonObject?.label || 'Stagione sconosciuta';
  };

  const getTyreInfo = (tyre: Tyre) => {
    return (tyre && tyre.width && tyre.aspectRatio && tyre.diameter) 
      ? `${tyre.width}/${tyre.aspectRatio}/R${tyre.diameter}` 
      : 'N/D';
  };

  const getCircleInfo = (circle?: boolean | null) => {
    if (circle === true) return 'Sì';
    if (circle === false) return 'No';
    return;
  };

  const columns: ColumnProps<Tyre>[] = [
    {
      title: 'Targa Veicolo',
      dataIndex: 'vehicleId',
      key: 'vehicleId',
      render: (_, tyre) => getVehiclePlate(tyre?.vehicleId),
    },
    {
      title: 'Marca',
      dataIndex: 'brandId',
      key: 'brandId',
      render: (_, tyre) => getTyreBrandLabel(tyre.tyreBrandId),
    },
    {
      title: 'Stagione',
      dataIndex: 'seasonId',
      key: 'seasonId',
      render: (_, tyre) => getSeasonLabel(tyre.season),
    },
    {
      title: 'Cerchio',
      dataIndex: 'circle',
      key: 'circle',
      render: (_, tyre) => getCircleInfo(tyre.circle),
    },
    {
      title: 'Misure',
      dataIndex: 'tyreInfo',
      key: 'tyreInfo',
      render: (_, tyre) => getTyreInfo(tyre),
    },
    {
      title: 'Anno',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record.id)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
    }
  ];

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: dataSource.length,
        }}
        rowKey="id"
        rowClassName={(record: Tyre) => (record.id && `row-${record.id}`)}
        rowSelection={rowSelection}
        size='small'
      />
    </Spin>
  );
}
