import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Form, FormInstance, Input, Row, Button, Space, Card, Pagination, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import RackForm from './forms/RackForm';
import IslandForm from './forms/IslandForm';


interface WarehouseFormProps {
  // mode: 'add' | 'edit';
  onSubmit: (values: any) => void;
  // warehouseId?: string;
  // initialData?: any;
}

export const WarehouseForm = forwardRef<FormInstance, WarehouseFormProps>(({
  // mode,
  onSubmit,
  // warehouseId,
  // initialData,
}, ref) => {
  const [form] = Form.useForm();

  // const [racksDisabled, setRacksDisabled] = useState<boolean[]>([]);
  // const [islandsDisabled, setIslandsDisabled] = useState<boolean[]>([]);

  const [rackPage, setRackPage] = useState(1);
  const [islandPage, setIslandPage] = useState(1);

  const itemsPerPage = 1; 
  
  useImperativeHandle(ref, () => form);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     racks: [{ total: 0, slots: 0 }],
  //     islands: [{ total: 0, slots: 0 }],
  //   });
  // }, [form]);

  useEffect(() => {
    // if (initialData) {
    //   form.setFieldsValue({
    //     name: initialData.name,
    //     address: initialData.address,
    //     city: initialData.city,
    //     cap: initialData.cap,
    //     province: initialData.province,
    //     region: initialData.region,
    //     racks: initialData.racks || [{ total: undefined, slots: undefined }],
    //     islands: initialData.islands || [{ total: undefined, slots: undefined }],
    //   });



      // const racksDisabledStatus = initialData.racks ? initialData.racks.map((rack: any) => !!rack?.rackIds?.length) : [];
      // setRacksDisabled(racksDisabledStatus);

      // const islandsDisabledStatus = initialData.islands ? initialData.islands.map((island: any) => !!island?.rackIds?.length) : [];
      // setIslandsDisabled(islandsDisabledStatus);

    //   setRackPage(initialData.racks && initialData.racks.length > 0 ? 1 : 0);
    //   setIslandPage(initialData.islands && initialData.islands.length > 0 ? 1 : 0);
    // } else {
    form.setFieldsValue({
      racks: [{ total: 0, slots: 0 }],
      islands: [{ total: 0, slots: 0 }],
    });
    // }
  }, [form]);

  const handleAdd = (fieldType: 'racks' | 'islands') => {
    const fields = form.getFieldValue(fieldType);

    const updatedFields = [...fields, fields.length === 0 ? { total: 0, slots: 0 } : { total: undefined, slots: undefined}];

    form.setFieldsValue({ [fieldType]: updatedFields });

    fieldType === 'racks' ? setRackPage(updatedFields.length) : setIslandPage(updatedFields.length);
  };

  const handleRemove = (fieldType: 'racks' | 'islands', index: number) => {
    const fields = form.getFieldValue(fieldType);
    const updatedFields = fields.filter((_: any, i: number) => i !== index);

    form.setFieldsValue({ [fieldType]: updatedFields });

    if (fieldType === 'racks' && rackPage > updatedFields.length) {
      setRackPage(updatedFields.length);
    } else if (fieldType === 'islands' && islandPage > updatedFields.length) {
      setIslandPage(updatedFields.length);
    }
  };

  const onFinish = (values: any) => {

    const { racks, islands } = values;

    const firstRack = racks[0];
    const firstIsland = islands[0];

    const isValidFirstRack = firstRack.total > 0 && firstRack.slots > 0;
    const isValidFirstIsland = firstIsland.total > 0 && firstIsland.slots > 0;

    if (!isValidFirstRack && !isValidFirstIsland) {
      let errors = [];
      if (racks && racks.length > 0) {
        if (firstRack.total === 0) {
          errors.push({
            name: ['racks', 0, 'total'],
            errors: ['Almeno il primo rack o la prima isola deve avere "total" e "slots" maggiori di zero'],
          });
        }
        if (firstRack.slots === 0) {
          errors.push({
            name: ['racks', 0, 'slots'],
            errors: ['Almeno il primo rack o la prima isola deve avere "total" e "slots" maggiori di zero'],
          });
        }
      }
      if (islands && islands.length > 0) {
        if (firstIsland.total === 0) {
          errors.push({
            name: ['islands', 0, 'total'],
            errors: ['Almeno il primo rack o la prima isola deve avere "total" e "slots" maggiori di zero'],
          });
        }
        if (firstIsland.slots === 0) {
          errors.push({
            name: ['islands', 0, 'slots'],
            errors: ['Almeno il primo rack o la prima isola deve avere "total" e "slots" maggiori di zero'],
          });
        }
      }
      form.setFields(errors);

      return;
    }

    onSubmit(values);
  };

  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 };
  const rowStyle = { marginBottom: '16px' };

  return (
    <Form
      form={form}
      // initialValues={ warehouseId ? initialData : undefined }
      layout="vertical"
      name="warehouse_form"
      onFinish={onFinish}
    >
      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="name"
            label="Denominazione Magazzino"
            rules={[{ required: true, message: "Inserire la denominazione del magazzino!" }]}
          >
            <Input placeholder="Denominazione Magazzino" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="address"
            label="Indirizzo"
          >
            <Input placeholder="Indirizzo" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="city"
            label="Città"
          >
            <Input placeholder="Città" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="cap"
            label="CAP"
          >
            <Input placeholder="CAP" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="province"
            label="Provincia"
          >
            <Input placeholder="Provincia" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="region"
            label="Regione"
          >
            <Input placeholder="Regione" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24}>
          <Card title="Costi Predefiniti degli Slot">
            <Row gutter={rowGutter}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="defaultSlotCost"
                  label="Costo Slot"
                  // rules={[{ required: true, message: "Inserire il costo predefinito dello slot!" }]}
                >
                  <InputNumber
                    placeholder="Costo Slot" 
                    min={0}
                    precision={2} 
                    style={{ width: '100%' }}
                    formatter={value => `€ ${value}`}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="defaultSaleSlotCost"
                  label="Costo di Vendita Slot"
                  // rules={[{ required: true, message: "Inserire il costo di vendita predefinito dello slot!" }]}
                >
                  <InputNumber
                    placeholder="Costo di Vendita Slot" 
                    min={0}
                    precision={2} 
                    style={{ width: '100%' }}
                    formatter={value => `€ ${value}`}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Card title="Rastrelliere">
            <Form.List name="racks">
              {(fields) => (
                <>
                  {fields.map((field, index) => (
                    index + 1 === rackPage && (
                      <Space key={`rack-${field.key}`} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <RackForm field={field} isFirst={index === 0} />
                        { index > 0 && <MinusCircleOutlined onClick={() => handleRemove('racks', index)} /> }
                      </Space>
                    )
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => { handleAdd('racks'); }}
                      icon={<PlusOutlined />}
                      style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      Aggiungi Rastrelliera con Diversa Composizione
                    </Button>
                  </Form.Item>
                  <Pagination
                    current={rackPage}
                    pageSize={itemsPerPage}
                    total={fields.length}
                    onChange={setRackPage}
                  />
                </>
              )}
            </Form.List>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card title="Isole">
            <Form.List name="islands">
              {(fields) => (
                <>
                  {fields.map((field, index) => (
                    index + 1 === islandPage && (
                      <Space key={`island-${field.key}`} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <IslandForm field={field} isFirst={index === 0} /> 
                        { index > 0 && <MinusCircleOutlined onClick={() => handleRemove('islands', index)} /> }
                      </Space>
                    )
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => handleAdd('islands')}
                      icon={<PlusOutlined />}
                      style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      Aggiungi Isola con Diversa Composizione
                    </Button>
                  </Form.Item>
                  <Pagination
                    current={islandPage}
                    pageSize={itemsPerPage}
                    total={fields.length}
                    onChange={setIslandPage}
                  />
                </>
              )}
            </Form.List>
          </Card>
        </Col>
      </Row>
    </Form>
  );
});
