import React from 'react';
import { Table, Spin, Space, Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { RackSlot } from './types';
import { Customer } from '../customers';
import { Tyre } from '../tyres';
import { Vehicle } from '../vehicles';


interface RackSlotsTableProps {
  dataSource: RackSlot[];
  customers: Customer[];
  tyres: Tyre[];
  vehicles: Vehicle[];
  loading: boolean;
  onDelete: (id: string) => void;
}

export function RackSlotsTable({
  dataSource,
  customers,
  tyres,
  vehicles,
  loading,
  onDelete,
}: RackSlotsTableProps): JSX.Element {

  const getVehiclePlate = (vehicles: Vehicle[], slot: RackSlot) => {
    const tyre = tyres.filter((tyre) => tyre.id === slot.tyreId1 || tyre.id === slot.tyreId2)[0];
    const vehicleObject = vehicles.find((vehicle) => vehicle.id === tyre.vehicleId);

    return vehicleObject?.plate;
  };

  const getCustomerName = (vehicles: Vehicle[], customers: Customer[], slot: RackSlot) => {
    const tyre = tyres.find((tyre) => tyre.id === slot.tyreId1 || tyre.id === slot.tyreId2);
    const vehicleObject = vehicles.find((vehicle) => vehicle.id === tyre?.vehicleId);
    const customerObject = customers.find((customer) => customer.id === vehicleObject?.customerId);

    return customerObject ? `${customerObject.name} ${customerObject.surname}` : 'N/D';
  };

  // const getTyreInfo = (slot: RackSlot) => {
  //   const tyre = tyres.filter((tyre) => tyre.id === slot.tyreId1 || tyre.id === slot.tyreId2)[0];
  //   return (tyre && tyre.width && tyre.aspectRatio && tyre.diameter) 
  //     ? `${tyre.width}/${tyre.aspectRatio}/R${tyre.diameter}` 
  //     : 'N/D';
  // };

  const columns: ColumnProps<RackSlot>[] = [
    {
      title: 'Denominazione',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Costo Slot',
      dataIndex: 'slotCost',
      key: 'slotCost',
    },
    {
      title: 'Prezzo Affitto Slot',
      dataIndex: 'saleSlotCost',
      key: 'saleSlotCost',
    },
    {
      title: 'Targa Veicolo',
      dataIndex: 'vehiclePlate',
      key: 'vehiclePlate',
      render: (_, slot) => getVehiclePlate(vehicles, slot),
    },
    {
      title: 'Nome Cliente',
      dataIndex: 'customerName',
      key: 'customerName',
      render: (_, slot) => getCustomerName(vehicles, customers, slot),
    },
    // {
    //   title: 'Misure Pneumatici',
    //   dataIndex: 'tyreInfo',
    //   key: 'tyreInfo',
    //   render: (_, tyre) => getTyreInfo(tyre),
    // },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
    }
  ];

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: dataSource.length,
        }}
        rowKey="id"
        rowClassName={(record: RackSlot) => (record.id && `row-${record.id}`)}
        size='small'
      />
    </Spin>
  );
}
