import React from 'react';
import { Descriptions, Typography } from 'antd';
import {Warehouse, WarehouseWithStats } from '../warehouses/types';


const { Text } = Typography;

interface WarehouseDetailProps {
  warehouse: WarehouseWithStats | undefined;
}

const WarehouseDetail: React.FC<WarehouseDetailProps> = ({
  warehouse,
}) => {

  function formatAddress(warehouse: Warehouse) {
    let addressParts = [];

    if (warehouse.address) {
      addressParts.push(warehouse.address);
    }

    let cityInfo = [];
    if (warehouse.cap) {
      cityInfo.push(warehouse.cap);
    }
    if (warehouse.city) {
      cityInfo.push(warehouse.city);
    }
    if (warehouse.province) {
      cityInfo.push(`(${warehouse.province})`);
    }

    if (cityInfo.length > 0) {
      addressParts.push(cityInfo.join(' '));
    }

    if (warehouse.region) {
      addressParts.push(warehouse.region);
    }

    if (warehouse.nation) {
      addressParts.push(warehouse.nation);
    }
  
    return addressParts.length > 0 ? addressParts.join(' - ') : 'Indirizzo non disponibile';
  }

  return (
    <div>
      {warehouse && (
        <Descriptions column={{ xs: 1, sm: 2 }}>
          <Descriptions.Item label={<Text strong>Denominazione Magazzino</Text>} span={2}>
            {warehouse.name}
          </Descriptions.Item>

          <Descriptions.Item label={<Text strong>Numero Totale Slot</Text>} span={1}>
            {warehouse.statistics.total_slots || 0}
          </Descriptions.Item>

          <Descriptions.Item label={<Text strong>Indirizzo Magazzino</Text>} span={2}>
            {formatAddress(warehouse)}
          </Descriptions.Item>

          <Descriptions.Item label={<Text className="color-danger" strong>Slot Occupate</Text>} span={1}>
            {warehouse.statistics.occupied_slots || 0}
          </Descriptions.Item>
          
          <Descriptions.Item span={2}>
            <Descriptions layout="horizontal">
              <Descriptions.Item label={<Text strong>Rastrelliere</Text>}>{warehouse.total_racks}</Descriptions.Item>
              <Descriptions.Item label={<Text strong>Isole</Text>}>{warehouse.total_islands}</Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>

          <Descriptions.Item label={<Text className="color-success" strong>Slot Libere</Text>} span={1}>
            {warehouse.statistics.free_slots || 0}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
};

export default WarehouseDetail;
