import { RecoilRoot } from 'recoil';
import { ConfigProvider } from 'antd';
import { RootRoutes } from './components/routing/RootRoutes';
import './App.css';

function App() {
  return (
    <RecoilRoot>
      <ConfigProvider
        theme={{
          token: {
            colorTextBase: '#221E1E',  // --main-black-color
            // colorLink: '#179DC6',
          },
        }}
      >
        <RootRoutes />
      </ConfigProvider>
    </RecoilRoot>
  );
}

export default App;
