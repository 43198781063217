import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID as string,
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID as string,
    },
  },
  API: {
    REST: {
      appointments: {
        endpoint: process.env.REACT_APP_APPOINTMENTS_ENDPOINT as string,
        region: process.env.REACT_APP_REGION as string,
      },
      customers: {
        endpoint: process.env.REACT_APP_CUSTOMERS_ENDPOINT as string,
        region: process.env.REACT_APP_REGION as string,
      },
      services: {
        endpoint: process.env.REACT_APP_SERVICES_ENDPOINT as string,
        region: process.env.REACT_APP_REGION as string,
      },
      suppliers: {
        endpoint: process.env.REACT_APP_SUPPLIERS_ENDPOINT as string,
        region: process.env.REACT_APP_REGION as string,
      },
      tyres: {
        endpoint: process.env.REACT_APP_TYRES_ENDPOINT as string,
        region: process.env.REACT_APP_REGION as string,
      },
      vehicles: {
        endpoint: process.env.REACT_APP_VEHICLES_ENDPOINT as string,
        region: process.env.REACT_APP_REGION as string,
      },
      warehouses: {
        endpoint: process.env.REACT_APP_WAREHOUSES_ENDPOINT as string,
        region: process.env.REACT_APP_REGION as string,
      },
      settings: {
        endpoint: process.env.REACT_APP_SETTINGS_ENDPOINT as string,
        region: process.env.REACT_APP_REGION as string,
      },
    },
  }
});
