import React from 'react';
import { Badge, Card, Space, Statistic, Typography } from 'antd';
import { WarehouseCostsStats } from '../types';

import './WarehousesStats.css';


interface WarehouseValueCardProps {
  costsStats: WarehouseCostsStats;
}

const { Text } = Typography;

export const WarehouseValueCard = ({ costsStats }: WarehouseValueCardProps) => {
  const redDot = <Badge color="#ff4d4f" dot />;

  return (
    <Card title="Valore di Magazzino" className='stats-card' style={{ flex: 1 }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '75%' }}>
        <Space direction="vertical" size="large" style={{ textAlign: 'center' }}>
          <Statistic 
            title={<><span style={{ paddingRight: '8px' }}>{redDot}</span>Valore Netto Magazzino</>} 
            value={costsStats ? costsStats.total_slot_cost : 0} 
            prefix={'€'} 
          />
          <Statistic 
            title={<><span style={{ paddingRight: '8px' }}>{redDot}</span>Valore Lordo Magazzino</>} 
            value={costsStats ? costsStats.total_sale_slot_cost : 0} 
            prefix={'€'}
          />
        </Space>
      </div>
    </Card>
  );
};
