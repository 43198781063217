import React from 'react';
import { Row, Col, Button, Space, Select } from 'antd';


const { Option } = Select;

interface VehiclesSearchProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onSelectVehicle: (vehicleId: string) => void;
  onResetSelectedVehicle: () => void;
  plates: string[];
  vehicleIds: string[];
}

export const VehiclesSearch: React.FC<VehiclesSearchProps> = ({
  searchTerm,
  setSearchTerm,
  onSelectVehicle,
  onResetSelectedVehicle,
  plates,
  vehicleIds,
}) => {
  return (
    <Row justify="end">
      <Col>
        <Space size="middle">
          <Select
            showSearch
            placeholder="Targa, esempio: ET390WD"
            value={searchTerm}
            onChange={(value: string) => {
              setSearchTerm(value);
              
              const index = plates.indexOf(value);
              onSelectVehicle(vehicleIds[index]);
            }}
            style={{ width: 200 }}
          >
            {plates.map((plate, index) => (
              <Option key={plate} value={plate}>
                {plate}
              </Option>
            ))}
          </Select>
          <Button onClick={() => {
            setSearchTerm('');
            onResetSelectedVehicle();
          }}>Reset</Button>
        </Space>
      </Col>
    </Row>
  );
};

export default VehiclesSearch;
