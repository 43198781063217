import React from 'react';
import { Form, InputNumber } from 'antd';
import { FormListFieldData } from 'antd/lib';


interface RackFormProps {
  field: FormListFieldData;
  // disabled?: boolean;
  isFirst: boolean;
}

const RackForm: React.FC<RackFormProps> = ({ field, isFirst }) => (
  <>
    <Form.Item
      name={[field.name, 'total']}
      label="Numero Totale Rastrelliere"
      rules={isFirst ? [{ required: true, message: "Inserire il numero di rastrelliere" }] : []}
    >
      <InputNumber placeholder="Numero di Isole" min={1} style={{ width: '100%' }} />
    </Form.Item>
    <Form.Item
      name={[field.name, 'slots']}
      label="Slot per Rastrelliera"
      rules={isFirst ? [{ required: true, message: "Inserire il numero di slot per rastrelliera" }] : []}
    >
      <InputNumber placeholder="Numero di Slot" min={1} style={{ width: '100%' }} />
    </Form.Item>
  </>
);

export default RackForm;
