import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Card, Col, Modal, Row, Tabs, notification } from 'antd';
import type { FormInstance, TabsProps } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { fetchAuthSession } from 'aws-amplify/auth';
import { get, post, put, del } from 'aws-amplify/api';

import { Customer, CustomerForm, CustomersSearch, CustomersTable } from '../components/customers';
import { AddUserIcon, AddVehicleIcon, EditUserIcon, EmailIcon } from '../components/IconWrapper';
import { PageTabs } from '../constants/NavigationTabs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { applicationModuleId } from '../config/config';


const { confirm } = Modal;

export function Customers() {
  const [cardActions, setCardActions] = useState<JSX.Element[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const formRef = useRef<FormInstance>(null);

  const { customerId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const isEditPage = location.pathname.includes('/edit');
  const isNewPage = location.pathname.endsWith('/new');

  const getActiveTabKeyFromPath = () => {
    if (isNewPage) return PageTabs.New;
    return PageTabs.List;
  };

  const [activeTabKey, setActiveTabKey] = useState<string>(getActiveTabKeyFromPath());

  useEffect(() => {
    let actions: JSX.Element[] = [];

    if (isNewPage) {
      actions = [
        <div className='action-icon' onClick={() => handleSubmit()} >
          <AddUserIcon size='xl' />
        </div>,
        <div className='action-icon' onClick={() => console.log('Aggiungi Veicolo')} >
          <AddVehicleIcon size='xl' />
        </div>
      ];
    } else if (isEditPage) {
      actions = [
        <div className='action-icon' onClick={() => handleSubmit()} >
          <EditUserIcon size='xl' />
        </div>,
        <div 
          className='action-icon'
          onClick={() => console.log('Copia E-mail')
        }>
          <EmailIcon size='xl' />
        </div>
      ];
    }

    setCardActions(actions);

    if (location.pathname === '/customers') {
      fetchCustomers();
    }

  }, [location.pathname, isNewPage, isEditPage]);

  const fetchCustomers = async () => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      const operation = get({
        apiName: 'customers',
        path: `/customers${(searchTerm !== '') ? `?search=${encodeURIComponent(searchTerm)}` : ''}`,
        options: {
          headers: {
            Authorization: accessToken
          }
        }
      });

      const { body } = await operation.response;
      const response = await body.json();

      setCustomers(response as unknown as Customer[]);
    } catch (error) {
      console.error('Failed to fetch customers:', error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const createCustomer = async (data: Customer) => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      data['applicationModuleId'] = applicationModuleId;

      const operation = post({
        apiName: 'customers',
        path: '/customers',
        options: {
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json'
          },
          body: data as any
        }
      });

      await operation.response;

      notification.success({
        message: 'Cliente Aggiunto',
        description: 'Il cliente è stato aggiunto con successo.',
      });
    } catch (error) {
      console.error('Failed to add customer:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'aggiunta del cliente.',
      });
    } finally {
      setLoading(false);
    }
  };

  const editCustomer = async (data: Customer) => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';

      const operation = put({
        apiName: 'customers',
        path: `/customers/${customerId}`,
        options: {
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json'
          },
          body: data as any
        }
      });

      await operation.response;

      notification.success({
        message: 'Cliente Modificato',
        description: 'Il cliente è stato modificato con successo.',
      });
    } catch (error) {
      console.error('Failed to edit customers:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante la modifica del cliente.',
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteCustomer = async (id: string) => {
    setLoading(true);

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken.toString() ?? '';
  
      const operation = del({
        apiName: 'customers',
        path: `/customers/${id}`,
        options: {
          headers: {
            Authorization: accessToken,
          },
        },
      });

      await operation.response;
  
      notification.success({
        message: 'Cliente Eliminato',
        description: 'Il Cliente è stato eliminato con successo.',
      });
  
      fetchCustomers();
    } catch (error) {
      console.error('Failed to delete customer:', error);
      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'eliminazione del cliente.',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection: TableRowSelection<Customer> = {
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    selectedRowKeys,
  };

  const handleClickAdd = (): void => {
    setActiveTabKey(PageTabs.New);
    navigate('/customers/new');
  };

  const handleClickEdit = (id: string) => {
    navigate(`/customers/edit/${id}`);
  };

  const handleClickContact = (id: string) => {
    console.log("🚀 ~ handleContact:", id)
  };

  const handleClickDelete = (id: string) => {
    confirm({
      title: 'Sei sicuro di voler cancellare questo Cliente?',
      icon: <ExclamationCircleOutlined />,
      content: 'Questa azione è irreversibile',
      okText: 'Sì, cancella',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await deleteCustomer(id);
      },
      onCancel() {
        console.log('Delete Cancelled');
      },
    });
  };

  const tabItems: TabsProps['items'] = [
    {
      label: 'Anagrafica Clienti',
      key: PageTabs.List,
      children: (
        <>
          <CustomersSearch 
            searchTerm={searchTerm} 
            setSearchTerm={setSearchTerm} 
            onSearch={fetchCustomers}
            onAdd={handleClickAdd}
          />
          <CustomersTable 
            dataSource={customers}
            loading={loading}
            onEdit={handleClickEdit}
            onContact={handleClickContact}
            onDelete={handleClickDelete}
            rowSelection={rowSelection}
          />
        </>
      ),
    },
    {
      label: 'Aggiungi Cliente',
      key: PageTabs.New
    },
  ];

  const onTabChange: TabsProps['onChange'] = (key: string) => {

    setActiveTabKey(key);

    switch (key) {
      case PageTabs.List:
        navigate('/customers');
        break;
      case PageTabs.New:
        navigate('/customers/new');
        break;
      default:
        break;
    }
  };

  const handleAddCustomerFormSubmit = async (formData: Customer) => {
    await createCustomer(formData);
  };

  const handleEditCustomerFormSubmit = async (formData: Customer) => {
    await editCustomer(formData);
  };

  const handleSubmit = () => {
    formRef.current?.submit();
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title={isEditPage ? 'Modifica Cliente' : undefined}
          extra={isEditPage ? <Link to="/customers">Torna all'anagrafica</Link> : undefined}
          actions={cardActions}
        >
          {!isEditPage && (
            <Tabs defaultActiveKey={PageTabs.List} activeKey={activeTabKey} onChange={onTabChange} items={tabItems} />
          )}
          {isNewPage && <CustomerForm mode='add' ref={formRef} onSubmit={handleAddCustomerFormSubmit} />}
          {isEditPage && <CustomerForm mode='edit' ref={formRef} customerId={customerId} initialData={customers.find(customer => customer.id === customerId)} onSubmit={handleEditCustomerFormSubmit} />}
        </Card>
      </Col>
    </Row>
  );
}
