import { useState } from 'react';
import { Col, Flex } from 'antd';
import { useSetRecoilState } from 'recoil';
import { confirmResetPassword, confirmSignIn,  resetPassword, signIn } from 'aws-amplify/auth';
import { createUser, fetchUser } from '../api/SettingsApi';
import { userState } from '../core/user';
import { isLoggedInState } from '../core/auth';
import { LoginForm } from '../components/forms/LoginForm';
import { FirstAccessForm } from '../components/forms/ChangePasswordForm';
import { ForgotPasswordForm } from '../components/forms/ForgotPasswordForm';
import { ResetPasswordForm } from '../components/forms/ResetPasswordForm';

interface SignInFormValues {
  email: string;
  password: string;
}

interface ChangePasswordFormValues {
  oldPassword: string;
  newPassword: string;
}

interface CustomError {
  code?: string;
  message?: string;
  name?: string;
}

export function Login() {
  const [currentStep, setCurrentStep] = useState('login');  // 'login', 'forgotPassword', 'resetPassword'

  const [email, setEmail] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const setIsLoggedIn = useSetRecoilState(isLoggedInState);
  const setUser = useSetRecoilState(userState);

  const onSubmitLogin = async (values: SignInFormValues) => {

    if (errorMessage !== '') {
      setErrorMessage('');
    }

    try {
      const result = await signIn({
        username: values.email,
        password: values.password,
      });

      console.log('[signIn] result', result);
      const nextStep = result.nextStep;

      if (nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        setCurrentStep('changePassword');
      }

      if (nextStep?.signInStep === 'DONE') {
        const user = await fetchUser() as any;
        if (Array.isArray(user.result)) {
          if ( user.result.length === 0) {
            const createdUser = await createUser({ email: values.email }) as any;
            
            if (Array.isArray(createdUser.result) && user.result.length > 0) {
              setUser(createdUser.result[0]);
            }
          } else if (user.result.length > 0) {
            setUser(user.result[0]);
          } 
        }
      }

      const isSignedIn = result.isSignedIn;
      if (isSignedIn) {
        setIsLoggedIn(true);
      }
    } catch (err) {
      console.error('[signIn] error', err);
      setErrorMessage('E-mail o password errati! Riprova');
    }
  };

  const onChangePassword = async (values: ChangePasswordFormValues) => {
    if (errorMessage !== '') {
      setErrorMessage('');
    }

    try {
      const result = await confirmSignIn({
        challengeResponse: values.newPassword,
      });

      const isSignedIn = result.isSignedIn;
      const nextStep = result.nextStep;

      if (isSignedIn && nextStep?.signInStep === 'DONE') {
        console.log('Password changed successfully', result);

        setCurrentStep('login'); 
      }

      // setIsChangingPassword(false);
    } catch (err) {
      console.error('[onChangePassword] error', err);
      setErrorMessage('Change Password failed. Please check your passwords and try again.');
    }
  };

  const onForgotPassword = async () => {

    if (errorMessage !== '') {
      setErrorMessage('');
    }

    setCurrentStep('forgotPassword');
  }

  const onSendEmail = async (email: string) => {
    try {
      const result = await resetPassword({
        username: email
      });

      const nextStep = result.nextStep;

      if (nextStep?.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        setEmail(email);

        if (errorMessage !== '') {
          setErrorMessage('');
        }

        setCurrentStep('resetPassword');
      }

    } catch (err) {
      handlePasswordResetErrors(err as CustomError);
    }
  };

  const onResetPassword = async (code: string, newPassword: string) => {
    try {
      const result = await confirmResetPassword({
        username: email, 
        newPassword: newPassword, 
        confirmationCode: code
      });

      console.log('const nextStep =', result);

      if (errorMessage !== '') {
        setErrorMessage('');
      }

      setCurrentStep('login');
    } catch (error) {
      handlePasswordResetErrors(error as CustomError);
    }
  };

  const handlePasswordResetErrors = (error: CustomError) => {
    let message = "";

    if (error.name) {
      switch (error.name) {
        case 'CodeMismatchException':
          message = "Il codice di verifica inserito non è corretto. Riprova.";
          break;
        case 'ExpiredCodeException':
          message = "Il codice di verifica è scaduto. Richiedi un nuovo codice.";
          break;
        case 'UserNotFoundException':
          message = "Errore durante l'invio del codice. Controlla la tua email o riprova.";
          break;
        case 'InvalidParameterException':
          message = "Parametri non validi. Assicurati che la tua email sia verificata se stai usando l'email come username.";
          break;
        case 'LimitExceededException':
          message = "Hai superato il limite massimo di tentativi. Aspetta un po' e riprova.";
          break;
        default:
          message = "Errore durante il reset della password. Controlla i dettagli e riprova.";
          break;
      }
    }

    setErrorMessage(message);
  };

  const renderForm = () => {
    switch (currentStep) {
      case 'login':
        return <LoginForm onLogin={onSubmitLogin} onForgotPassword={onForgotPassword} errorMessage={errorMessage} />;
      case 'changePassword':
        return <FirstAccessForm onChangePassword={onChangePassword} errorMessage={errorMessage} />;
      case 'forgotPassword':
        return <ForgotPasswordForm onSendEmail={onSendEmail} errorMessage={errorMessage} />;
      case 'resetPassword':
        return <ResetPasswordForm onResetPassword={onResetPassword} errorMessage={errorMessage} />;
      default:
        return null;
    }
  };

  return (
    <Flex align="center" justify="center" style={{ height: '100vh' }}>
      <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
        {renderForm()}
      </Col>
    </Flex>
  );
}
