import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { ChangePasswordUser } from '../types';


interface ChangePasswordFormProps {
  onSubmit: (values: ChangePasswordUser) => void;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const [isOldPasswordValid, setIsOldPasswordValid] = useState(false);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);

  const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOldPasswordValid(e.target.value.length > 0);
    setFormTouched(true);
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    setIsNewPasswordValid(newValue.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/) !== null);
    setFormTouched(true);
  };  
  
  const onFinish = (values: ChangePasswordUser) => {
    onSubmit(values);

    // TODO: Evaluate use
    form.setFieldsValue({ oldPassword: '', newPassword: '' });

    setIsOldPasswordValid(false);
    setIsNewPasswordValid(false);
    setFormTouched(false);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="change_password_form"
      onFinish={onFinish}
      initialValues={{ oldPassword: '', newPassword: '' }}
    >
      <Form.Item
        name="oldPassword"
        label="Vecchia Password"
        rules={[{ required: true, message: 'Inserisci la vecchia password' }]}
        validateStatus={formTouched && isOldPasswordValid ? 'success' : ''}
      >
        <Input.Password placeholder="Vecchia Password" onChange={handleOldPasswordChange} />
      </Form.Item>
      <Form.Item
        name="newPassword"
        label="Nuova Password"
        rules={[
          { required: true, message: 'Inserisci la tua nuova password!' },
          { min: 8, message: 'La password deve essere lunga almeno 8 caratteri.' },
          {
            pattern: new RegExp("(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)"),
            message: "La password deve contenere numeri, lettere maiuscole, minuscole e simboli.",
          }
        ]}
        validateStatus={formTouched ? (isNewPasswordValid ? 'success' : 'error') : ''}
      >
        <Input.Password placeholder="Nuova Password" onChange={handleNewPasswordChange} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={!(isOldPasswordValid && isNewPasswordValid)}>
          Cambia Password
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ChangePasswordForm;
