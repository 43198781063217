import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Select, Button, Space, Typography, notification } from 'antd';

import { fetchWarehouseCostsStats, fetchWarehouseTyresStats } from '../../api';
import { QuickQuoteCard } from './QuickQuoteCard';
import { WarehouseDistributionCard } from './WarehouseDistributionCard';

// import { UpcomingUnloadCard, WarehouseAvailabilityCard, WarehouseValueCard } from '../warehouses/stats';
import { Service } from '../preventives';
import { WarehouseCostsStats, WarehouseTyresStats, WarehouseWithStats } from '../warehouses/types';


interface WarehousesStatsProps {
  services: Service[];
  warehouses: WarehouseWithStats[];
  loading: boolean;
}

const { Text, Title } = Typography;

export function WarehousesStats({ services, warehouses }: WarehousesStatsProps): JSX.Element {

  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseWithStats | undefined>(undefined);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<string>(warehouses[0]?.id);

  const [tyresStats, setTyresStats] = useState<WarehouseTyresStats | null>(null);
  const [costsStats, setCostsStats] = useState<WarehouseCostsStats | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  // const selectedWarehouse = warehouses.find(warehouse => warehouse.id === selectedWarehouseId);

  useEffect(() => {
    if (warehouses.length > 0) {

      const _selectedWarehouseId = warehouses[0]?.id;
      if (_selectedWarehouseId) {
        const _selectedWarehouse = warehouses.find(warehouse => warehouse.id === _selectedWarehouseId);

        setSelectedWarehouseId(_selectedWarehouseId);
        setSelectedWarehouse(_selectedWarehouse);
      }
    }
  }, [warehouses]);

  useEffect(() => {
    const loadStatistics = async () => {
      if (!selectedWarehouseId) return;

      try {
        setLoading(true);

        const [tyresStatsResponse, costsStatsResponse] = await Promise.all([
          fetchWarehouseTyresStats(selectedWarehouseId),
          fetchWarehouseCostsStats(selectedWarehouseId)
        ]);

        const tyresStatsData = (tyresStatsResponse && Array.isArray(tyresStatsResponse) && tyresStatsResponse.length > 0) ? tyresStatsResponse[0] : null;
        const costsStatsData = (costsStatsResponse && Array.isArray(costsStatsResponse) && costsStatsResponse.length > 0) ? costsStatsResponse[0] : null;

        setTyresStats(tyresStatsData as unknown as WarehouseTyresStats);
        setCostsStats(costsStatsData as unknown as WarehouseCostsStats);
        
      } catch (error) {
        console.error('Failed to fetch Warehouse Stats:', error);
      } finally {
        setLoading(false);
      }
    };

    loadStatistics();
  }, [selectedWarehouseId]);

  const handleWarehouseChange = (warehouseId: string) => {
    const _selectedWarehouse = warehouses.find(warehouse => warehouse.id === warehouseId);

    if (_selectedWarehouse) {
      setSelectedWarehouse(_selectedWarehouse);
      setSelectedWarehouseId(_selectedWarehouse.id)
    }
  };

  return (
    <>
      {selectedWarehouse && <Space direction="vertical" size={24} style={{ width: '100%' }}>
        <Row gutter={[16, 32]}>
          {/* <Col xs={24} sm={24} md={6} span={12} style={{ display: 'flex' }}></Col> */}
          <Col xs={24} sm={24} md={24} lg={12} span={12} style={{ display: 'flex' }}>
            {services.length > 0 && <QuickQuoteCard services={services} loading={loading} ></QuickQuoteCard>}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} span={12} style={{ display: 'flex' }}>
            {costsStats && tyresStats && <WarehouseDistributionCard 
              costsStats={costsStats} 
              stats={selectedWarehouse.statistics || { total_slots: 0, occupied_slots: 0, free_slots: 0 }}
              tyresStats={tyresStats}
              warehouses={warehouses}
              warehouseName={selectedWarehouse.name} 
              onWarehouseChange={handleWarehouseChange}
            ></WarehouseDistributionCard>}
          </Col>
        </Row>
      </Space>}
    </>
  );
};
