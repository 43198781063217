import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Row, Space, notification } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import { 
  fetchCustomers,
  fetchVehicles,
  fetchVehicleManufacturers,
  fetchServices,
  createService
} from '../api/index';
import { NewService, Service, ServicesTable } from '../components/preventives';
import { VehiclesSearch } from '../components/preventives';
import { Vehicle, VehicleManufacturer } from '../components/vehicles';
import { Customer } from '../components/customers';
import VehicleDetail from '../components/preventives/VehicleInfo';
import { ServiceForm } from '../components/preventives/ServiceForm';
import { formatName } from '../utils/formatName';


export function Preventives(): JSX.Element {

  const [searchTerm, setSearchTerm] = useState('');

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [vehicleManufacturers, setVehicleManufacturers] = useState<VehicleManufacturer[]>([]);
  const [services, setServices] = useState<Service[]>([]);

  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(false);

  const servicesTableRef = useRef<any>();

  // useEffect(() => {

  //   if (location.pathname === '/vehicles') {
  //     _fetchVehicles();
  //   }

  // }, [location.pathname]);

  const _fetchCustomers = async () => {
    const response = await fetchCustomers('');

    setCustomers(response as unknown as Customer[]);
  };

  const _fetchVehicles = async () => {
    const response = await fetchVehicles(searchTerm);

    setVehicles(response as unknown as Vehicle[]);
  };

  const _fetchVehicleManufacturers = async () => {
    const response = await fetchVehicleManufacturers();

    setVehicleManufacturers(response as unknown as VehicleManufacturer[]);
  };

  const _fetchServices = async () => {
    const response = await fetchServices();

    setServices(response as unknown as Service[]);
  };

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      
      try {
        await Promise.all([
          _fetchCustomers(),
          _fetchVehicles(),
          _fetchVehicleManufacturers(),
          _fetchServices(),
        ]);
      } catch (error) {
        console.error("Si è verificato un errore", error);
        // notification.error({ message: 'Errore nel recupero dei dati', description: 'Non è stato possibile recuperare i dati. Riprova più tardi.' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddServiceFormSubmit = async (data: NewService) => {
    setLoading(true);

    try {
      const serviceCreated = await createService(data);

      if (serviceCreated) {
        notification.success({
          message: 'Servizio Aggiunto',
          description: 'Il Servizio è stato aggiunto con successo.',
        });

        await _fetchServices();
      }
    } catch (error) {

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'aggiunta del Servizio.',
      });

    } finally {
      setLoading(false);
    }
  };

  const handleSelectVehicle = (vehicleId: string) => {
    const vehicle = vehicles.find(v => v.id === vehicleId);

    setSelectedVehicle(vehicle);
  };

  const handleResetSelectedVehicle = () => {
    setSelectedVehicle(undefined);
  };

  const handlePrintPreventive = () => {
    const data = servicesTableRef.current.getUpdatedData().filter((service: Service) => service.quantity > 0);
    const { subtotal, discountRate, total } = servicesTableRef.current.getTotals();

    if (data.length > 0) {
      const doc = new jsPDF();

      const title = 'Preventivo Servizi';

      doc.setFontSize(18);
      doc.setFont('helvetica', 'bold');

      const titleWidth = doc.getTextWidth(title);
      const margin = 14;

      doc.text(title, margin, 20);

      const pageWidth = doc.internal.pageSize.getWidth();
      
      const date = new Intl.DateTimeFormat('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date());

      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');

      const dateWidth = doc.getTextWidth(`Data: ${date}`);

      const dateX = pageWidth - dateWidth - margin;

      if (dateX > (titleWidth + 2 * margin)) {
        doc.text(`Data: ${date}`, dateX, 20);
      } else {
        doc.text(`Data: ${date}`, titleWidth + 2 * margin, 20);
      }

      if (selectedVehicle) {
        const vehicleDetails = `Veicolo: ${(vehicleManufacturers.find((vehicleManufacturer) => vehicleManufacturer.id === selectedVehicle?.manufacturerId))?.name || 'N/D'}, Targa: ${selectedVehicle.plate || 'N/D'}`;
        const customer = customers.find((customer) => customer.id === selectedVehicle?.customerId);
        const customerDetails = `Cliente: ${formatName(customer?.name, customer?.surname)}, codice fiscale: ${customer?.fiscalCode || 'N/D'}, e-mail: ${customer?.email || 'N/D'}`;
        
        doc.setFontSize(10);

        doc.text(vehicleDetails, 14, 30);
        doc.text(customerDetails, 14, 35);
      }

      const headers = [['Nome', 'Prezzo', 'IVA', 'Quantità', 'Totale']];

      autoTable(doc, {
        head: headers,
        body: data.map((service: Service) => [
          service.name,
          `${service.price.toFixed(2)} €`,
          `${service.vat}%`,
          service.quantity.toString(),
          `${((service.price * service.quantity) * (1 + service.vat / 100)).toFixed(2)} €`
        ]),
        foot: [
          ['Subtotale', '', '', '', `${subtotal.toFixed(2)} €`],
          ['Sconto (%)', '', '', '', `${discountRate.toFixed(2)} %`],
          ['Totale', '', '', '', `${total.toFixed(2)} €`]
        ],
        startY: 40,
        theme: 'grid',
        headStyles: { fontStyle: 'bold' },
        styles: { fontSize: 10, cellPadding: 2 },
        columnStyles: { text: { cellWidth: 'wrap' } },
        margin: { top: 30 }
      });
  
      doc.save('preventivo.pdf');
    } else {
      notification.error({
        message: 'Nessun Servizio Selezionato',
        description: 'Non ci sono servizi con quantità selezionata per la stampa del preventivo.'
      });
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title={'Crea Nuovo Preventivo'}
        >
          <Space direction="vertical" size={24} style={{ width: '100%' }}>

            <VehiclesSearch
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              plates={vehicles.map(vehicle => vehicle.plate)}
              vehicleIds={vehicles.map(vehicle => vehicle.id)}
              onSelectVehicle={handleSelectVehicle}
              onResetSelectedVehicle={handleResetSelectedVehicle}
            />

            <VehicleDetail
              vehicle={selectedVehicle}
              customer={customers.find((customer) => customer.id === selectedVehicle?.customerId)}
              vehicleManufacturer={vehicleManufacturers.find((vehicleManufacturer) => vehicleManufacturer.id === selectedVehicle?.manufacturerId)}
            ></VehicleDetail>

            <ServiceForm onSubmit={handleAddServiceFormSubmit}></ServiceForm>

            <ServicesTable 
              ref={servicesTableRef}
              dataSource={services}
              loading={loading}
            />

            <Button 
              type="default" 
              icon={<PrinterOutlined />}
              style={{ display: 'block', margin: '0 auto' }}
              onClick={() => handlePrintPreventive()}
            >
              Stampa Preventivo
            </Button>

          </Space>
        </Card>
      </Col>
    </Row>
  );
}
