import { fetchAuthSession } from 'aws-amplify/auth';
import { get, post, put, del } from 'aws-amplify/api';
import { Appointment, Station } from '../components/appointments';
import { applicationModuleId } from '../config/config';

export const fetchAppointments = async () => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'appointments',
      path: '/appointments',
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch Appointments:', error);
    throw error;
  }
};

export const createAppointment = async (data: Appointment) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    data['applicationModuleId'] = applicationModuleId;

    const operation = post({
      apiName: 'appointments',
      path: '/appointments',
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    const { body } = await operation.response;

    const response = await body.json();

    return response;

  } catch (error) {
    console.error('Failed to create Appointment:', error);
    throw error;
  }
};

export const editAppointment = async (id: string, data: Appointment) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = put({
      apiName: 'appointments',
      path: `/appointments/${id}`,
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to edit Appointment:', error);
    throw error;
  }
};

export const deleteAppointment = async (id: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = del({
      apiName: 'appointments',
      path: `/appointments/${id}`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to delete Appointment:', error);
    throw error;
  }
};

export const fetchStations = async () => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'appointments',
      path: '/stations',
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch Stations:', error);
    throw error;
  }
};

export const createStation = async (data: Partial<Station>) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    data['applicationModuleId'] = applicationModuleId;

    const operation = post({
      apiName: 'appointments',
      path: '/stations',
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    const { body } = await operation.response;

    const stations = await body.json();

    return Array.isArray(stations) && stations.length > 0 ? stations : [];

  } catch (error) {
    console.error('Failed to add Station:', error);
    throw error;
  }
};
