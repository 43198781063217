import React from 'react';
import { Alert, Button, Form, Input, Row } from 'antd';
import { UserIcon, PasswordIcon } from '../IconWrapper';

interface LoginFormProps {
  onLogin: (values: { email: string; password: string }) => Promise<void>;
  onForgotPassword: () => void;
  errorMessage?: string;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  onLogin,
  onForgotPassword,
  errorMessage,
}) => {
  const onFinish = (values: { email: string; password: string }) => {
    onLogin({ email: values.email, password: values.password });
  };

  return (
    <>
      <h2>Login</h2>
      
      <Form name="login_form" layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
          label="Email"
        >
          <Input
            prefix={<UserIcon />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
          label="Password"
        >
          <Input
            prefix={<PasswordIcon />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <div style={{ minHeight: '50px', margin: '8px 0 16px' }}>
          <Alert
            message={errorMessage || ' '}
            type="error"
            style={{ visibility: errorMessage ? 'visible' : 'hidden', margin: 0 }}
            showIcon
          />
        </div>

        <Form.Item>
          <Row justify="space-between" align="middle">
            <a onClick={onForgotPassword} style={{ float: 'right' }}>
              Forgot password?
            </a>

            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
