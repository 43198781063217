import React from 'react';
import { Form, Input, Button, Alert } from 'antd';

interface ResetPasswordFormProps {
  onResetPassword: (code: string, newPassword: string) => void;
  errorMessage?: string;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onResetPassword,
  errorMessage,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: { code: string; newPassword: string; confirmNewPassword: string }) => {

    if (values.newPassword !== values.confirmNewPassword) {
      form.setFields([
        {
          name: 'confirmNewPassword',
          errors: ['Le password non corrispondono!'],
        },
      ]);

      return;
    }

    onResetPassword(values.code, values.newPassword);
  };

  return (
    <>
      <h2>Reimposta la tua password</h2>

      <Form form={form} onFinish={onFinish} name="reset_password_form" layout="vertical">
        <Form.Item
          name="code"
          rules={[{ required: true, message: 'Inserisci il codice ricevuto!' }]}
          label="Codice di Verifica"
          style={{ marginBottom: '16px' }}
        >
          <Input placeholder="Codice di Verifica" />
        </Form.Item>

        <Form.Item
          name="newPassword"
          rules={[
            { required: true, message: 'Inserisci la tua nuova password!' },
            { min: 8, message: 'La password deve essere lunga almeno 8 caratteri.' },
            {
              pattern: new RegExp("(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)"),
              message: "La password deve contenere numeri, lettere maiuscole, minuscole e simboli.",
            }
          ]}
          label="Nuova Password"
        >
          <Input.Password placeholder="Nuova Password" />
        </Form.Item>

        <Form.Item
          name="confirmNewPassword"
          rules={[{ required: true, message: 'Conferma la tua nuova password!' }]}
          label="Conferma Nuova Password"
        >
          <Input.Password placeholder="Conferma Nuova Password" />
        </Form.Item>

        <div style={{ minHeight: '50px', margin: '8px 0 16px' }}>
          <Alert
            message={errorMessage || ' '}
            type="error"
            style={{ visibility: errorMessage ? 'visible' : 'hidden', margin: 0 }}
            showIcon
          />
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Reimposta Password
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
