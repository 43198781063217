import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Row, notification } from 'antd';
import { deleteRack, fetchWarehouses, getWarehouseDetails } from '../api';
import { Rack, WarehouseWithStats } from '../components/warehouses';
import { RacksTable } from '../components/warehouse-detail/RacksTable';
import WarehouseDetail from '../components/warehouse-detail/WarehouseInfo';
import WarehouseRacksSearch from '../components/warehouse-detail/WarehouseRacksSearch';

export function WarehouseDetails() {

  const { warehouseId } = useParams<{ warehouseId: string }>();

  const [warehouseData, setWarehouseData] = useState<WarehouseWithStats | undefined>(undefined);
  const [currentWarehouse, setCurrentWarehouse] = useState<WarehouseWithStats | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  // useEffect(() => {
    
  // }, []);

  const loadWarehouseDetails = async () => {
    if (warehouseId) {  
      setLoading(true);

      try {
        const warehousesResponse = await fetchWarehouses();
        if (!warehousesResponse ) {
          throw new Error("Warehouse not found");
        }

        const warehouse = (warehousesResponse as unknown as WarehouseWithStats[]).find(warehouse => warehouse.id === warehouseId);
        setWarehouseData(warehouse);

        const response = await getWarehouseDetails(warehouseId, searchTerm);

        if (response && Array.isArray(response) && response.length > 0) {
          const firstItem = response[0];
  
          if (typeof firstItem === 'object' && firstItem !== null && 'Warehouse' in firstItem) {
            const warehouseDetails = firstItem.Warehouse;
  
            if (typeof warehouseDetails === 'object' && warehouseDetails !== null) {
              const warehouse: any = {
                ...warehouseDetails,
                racks: [],
                islands: []
              };
  
              const racksInfo: Rack[] = [];
  
              response.forEach((item: any) => {
                const { Rack: rack, RackSlot: slot } = item;

                const { id: rackId, name: rackName, totalSlots: rackTotalSlots, type: rackType } = rack;

                const index = racksInfo.findIndex(rackInfo => rackInfo.id === rackId);
                if (index === -1) {
                  racksInfo.push({
                    id: rackId,
                    name: rackName,
                    type: rackType,
                    slots: rackTotalSlots,
                    occupiedSlots: !slot ? 0 : 1
                  });
                } else {
                  const currentRack = racksInfo ? racksInfo[index] : undefined;

                  if (currentRack !== undefined && currentRack.occupiedSlots !== undefined) {
                    currentRack.occupiedSlots += 1;
                  }
                }
              });
  
              racksInfo.forEach((item: Rack) => {
                switch (item.type) {
                  case 'standard':
                    warehouse.racks.push(item);
                    break;
                  case 'island':
                    warehouse.islands.push(item);
                    break;
                  default:
                    break;
                }
              });
  
              if (warehouse.racks.length === 0) {
                warehouse.racks.push({
                  name: undefined,
                  slots: 0
                });
              }
  
              if (warehouse.islands.length === 0) {
                warehouse.islands.push({
                  name: undefined,
                  slots: 0
                });
              }
  
              setCurrentWarehouse(warehouse);
            }
          }
        } else {
          // console.error('Empty response or response format is not as expected');
          setCurrentWarehouse({ racks: [], islands: [] } as unknown as WarehouseWithStats);
        }
      } catch (error) {
        console.error('Failed to fetch warehouse details:', error);
      }
  
      setLoading(false);
    }
  };

  useEffect(() => {
    loadWarehouseDetails();
  }, [warehouseId]);

  const handleClickShow = (id: string) => {
    if (warehouseId) {
      navigate(`/warehouse/show/${warehouseId}/rack/${id}/slots`);
    }
  };

  const handleClickCreateSlot = (id: string, totalSlots: number, occupiedSlots: number, warehouse: WarehouseWithStats | undefined) => {
    if (warehouseId && warehouse) {
      navigate(`/warehouse/show/${warehouseId}/rack/${id}/slots/new`, { state: { totalSlots, occupiedSlots, defaultSlotCost: warehouse.defaultSlotCost, defaultSaleSlotCost: warehouse.defaultSaleSlotCost } });
    }
  };

  const handleClickRemove = (id: string) => {
    if (warehouseId) {
      _deleteRack(warehouseId, id);
    }
  };

  const _deleteRack = async (warehouseId: string, rackId: string) => {
    setLoading(true);

    try {
      await deleteRack(warehouseId, rackId);

      notification.success({
        message: 'Rack Eliminato',
        description: 'Il Rack è stato eliminato con successo.',
      });

      await loadWarehouseDetails();

    } catch (error) {
      console.error('Failed to remove Rack:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante  l\'eliminazione del Rack.',
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleClickShowRack = (id: string, rackId: string) => {
  //   navigate(`/warehouse/show/${id}/rack/${rackId}/slots`);
  // };

  // const handleClickCreateSlot = (id: string, rackId: string) => {
  //   navigate(`/warehouse/show/${id}/rack/${rackId}/slots/new`);
  // };

  // const handleClickEdit = (id: string) => {
  //   // navigate(`/warehouse/edit/${id}`);
  // };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title='Scheda Magazzino'
          extra={<Link to="/warehouse">Torna alla lista</Link>}
        >
          <WarehouseDetail warehouse={warehouseData as WarehouseWithStats} />
          <WarehouseRacksSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} onSearch={loadWarehouseDetails} />
          <RacksTable dataSource={currentWarehouse} loading={loading} onShow={handleClickShow} onCreateSlot={handleClickCreateSlot} onRemove={handleClickRemove} />
        </Card>
      </Col>
    </Row>
  );
};
