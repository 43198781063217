import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Button, Space, Typography } from 'antd';
import { fetchWarehouseCostsStats, fetchWarehouseTyresStats } from '../../api';
import { UpcomingUnloadCard, WarehouseAvailabilityCard, WarehouseDistributionCard, WarehouseValueCard } from './stats/index';
import { WarehouseCostsStats, WarehouseTyresStats, WarehouseWithStats } from './types';


interface WarehousesStatsProps {
  warehouses: WarehouseWithStats[];
  onShow: (id: string) => void;
  onEdit: (id: string) => void;
}

const { Text, Title } = Typography;

export function WarehousesStats({ warehouses, onShow, onEdit }: WarehousesStatsProps): JSX.Element {

  const [selectedWarehouseId, setSelectedWarehouseId] = useState<string>(warehouses[0]?.id);

  const [tyresStats, setTyresStats] = useState<WarehouseTyresStats | null>(null);
  const [costsStats, setCostsStats] = useState<WarehouseCostsStats | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const selectedWarehouse = warehouses.find(warehouse => warehouse.id === selectedWarehouseId);

  useEffect(() => {
    const loadStatistics = async () => {
      if (!selectedWarehouseId) return;

      try {
        setLoading(true);

        const [tyresStatsResponse, costsStatsResponse] = await Promise.all([
          fetchWarehouseTyresStats(selectedWarehouseId),
          fetchWarehouseCostsStats(selectedWarehouseId)
        ]);

        const tyresStatsData = (tyresStatsResponse && Array.isArray(tyresStatsResponse) && tyresStatsResponse.length > 0) ? tyresStatsResponse[0] : null;
        const costsStatsData = (costsStatsResponse && Array.isArray(costsStatsResponse) && costsStatsResponse.length > 0) ? costsStatsResponse[0] : null;

        setTyresStats(tyresStatsData as unknown as WarehouseTyresStats);
        setCostsStats(costsStatsData as unknown as WarehouseCostsStats);
        
      } catch (error) {
        console.error('Failed to fetch Warehouse Stats:', error);
      } finally {
        setLoading(false);
      }
    };

    loadStatistics();
  }, [selectedWarehouseId]);

  const handleWarehouseChange = (value: string) => {
    setSelectedWarehouseId(value);
  }

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginTop: 8, marginBottom: 16 }}>
        <Col>
          <div style={{ textAlign: 'left' }}>
            <Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
              Statistiche {selectedWarehouse?.name && ` - ${selectedWarehouse?.name}`}
            </Title>
            <Text style={{ marginTop: 4 }}>Valore Magazzino e Percentuale di Stoccaggio</Text>
          </div>
        </Col>
        <Col>
          <Select placeholder="Seleziona un Magazzino" onChange={handleWarehouseChange} defaultValue={selectedWarehouseId} style={{ width: '320px' }}>
            {warehouses.map((warehouse) => (
              <Select.Option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>

      {selectedWarehouse && <Space direction="vertical" size={24} style={{ width: '100%' }}>
        <Row gutter={[16, 32]}>
          <Col xs={24} sm={24} md={12} span={12} style={{ display: 'flex' }}>
            {tyresStats && <WarehouseDistributionCard warehouseName={selectedWarehouse.name} tyresStats={tyresStats}></WarehouseDistributionCard>}
          </Col>
          <Col xs={24} sm={24} md={12} span={12} style={{ display: 'flex' }}>
            {costsStats && <WarehouseValueCard costsStats={costsStats}></WarehouseValueCard>}
          </Col>
        </Row>

        <Row gutter={[16, 32]}>
          <Col xs={24} sm={24} md={12} span={12} style={{ display: 'flex' }}>
            <WarehouseAvailabilityCard stats={selectedWarehouse.statistics || { total_slots: 0, occupied_slots: 0, free_slots: 0 }}></WarehouseAvailabilityCard>
          </Col>
          <Col xs={24} sm={24} md={12} span={12} style={{ display: 'flex' }}>
            <UpcomingUnloadCard></UpcomingUnloadCard>
          </Col>
        </Row>
      </Space>}

      <Row justify="end" style={{ marginTop: '36px', marginBottom: '24px' }}>
        <Col>
          <Space size="middle">
            <Button onClick={() => console.log('stampa')}>
              Stampa
            </Button>
            <Button type="primary" onClick={() => onShow(selectedWarehouseId)}>
              Visualizza
            </Button>
            <Button type="primary" onClick={() => onEdit(selectedWarehouseId)}>
              Modifica
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
