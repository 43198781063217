import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { SideNavigation } from '../components/sidenav/SideNavigation';
import { useAuth } from '../hooks/auth';

const { Content, Footer } = Layout;

export function MainLayout() {
  const { signOut } = useAuth();

  const [theme, setTheme] = useState<'light' | 'dark'>('light');
 
  const handleThemeChange = (checked: boolean) => {
    setTheme(checked ? 'dark' : 'light');
  };

  const handleLogout = () => {
    signOut();
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SideNavigation
        onLogout={handleLogout}
        theme={theme}
        changeTheme={handleThemeChange}
      />
      <Layout>
        <Content style={{ margin: '24px 16px', overflow: 'initial' }} >
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          UI Design ©{new Date().getFullYear()} Created by Aternum Software 🚀
        </Footer>
      </Layout>
    </Layout>
  );
}
