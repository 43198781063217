import { VehicleType } from '../constants/VehicleType';

export function getVehicleTypeLabel (type: VehicleType): string {
  switch (type) {
    case VehicleType.Car:
      return "Auto";
    case VehicleType.Motorcycle:
      return "Moto";
    case VehicleType.Truck:
      return "Camion";
    default:
      return "Tipo non definito";
  }
}