import React, { forwardRef, useImperativeHandle } from 'react';
import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import { Customer } from './types/type';


interface CustomerFormProps {
  mode: 'add' | 'edit';
  onSubmit: (values: Customer) => void;
  customerId?: string;
  initialData?: Customer;
  // errorMessage?: string;
}

export const CustomerForm = forwardRef<FormInstance, CustomerFormProps>(({
  mode,
  onSubmit,
  customerId,
  initialData,
}, ref) => {
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => form);

  const onFinish = (values: Customer) => {
    onSubmit(values);
  };

  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 };
  const rowStyle = { marginBottom: '16px' };

  return (
    <>
      {/* {errorMessage && <Alert message={errorMessage} type="error" />} */}
      
      <Form
        form={form}
        initialValues={ customerId ? initialData : undefined }
        layout="vertical"
        name="add_customer_form"
        onFinish={onFinish}
      >
        <Row gutter={rowGutter} style={rowStyle}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="name"
              label="Nome"
            >
              <Input placeholder="Nome" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="surname"
              label="Cognome"
            >
              <Input placeholder="Cognome" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter} style={rowStyle}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ type: 'email', message: 'L\'email non è valida!' }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="phoneNumber"
              label="Numero di Telefono"
            >
              <Input placeholder="Numero di Telefono" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter} style={rowStyle}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="fiscalCode"
              label="Codice Fiscale"
            >
              <Input placeholder="Codice Fiscale" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="vatNumber"
              label="Partita IVA"
            >
              <Input placeholder="Partita IVA" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter} style={rowStyle}>
          <Col xs={24} sm={6}>
            <Form.Item
              name="birthDate"
              label="Data di Nascita"
            >
              <DatePicker 
                style={{ width: '100%' }}
                placeholder="Seleziona"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item
              name="gender"
              label="Sesso"
            >
              <Select placeholder="Seleziona">
                <Select.Option value="male">Maschio</Select.Option>
                <Select.Option value="female">Femmina</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="address"
              label="Indirizzo"
            >
              <Input placeholder="Indirizzo" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter} style={rowStyle}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="city"
              label="Città"
            >
              <Input placeholder="Città" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="cap"
              label="CAP"
            >
              <Input placeholder="CAP" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter} style={rowStyle}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="region"
              label="Regione"
            >
              <Input placeholder="Regione" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="nation"
              label="Nazione"
            >
              <Input placeholder="Nazione" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
});
