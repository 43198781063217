import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Modal, Row, Space, notification } from 'antd';
import { fetchAppointments, fetchCustomers, fetchStations, fetchVehicles, fetchVehicleManufacturers, createAppointment, createStation, deleteAppointment } from '../api/index';
import { Appointment, AppointmentForm, NewStation, Station } from '../components/appointments';
import { Customer } from '../components/customers';
import { Vehicle, VehicleManufacturer } from '../components/vehicles';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { formatName } from '../utils';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';


interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  color: string;
}

// const { confirm } = Modal;

export function Appointments() {

  const [searchTerm, setSearchTerm] = useState('');
  const [isEventModalVisible, setIsModalVisible] = useState(false);

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [events, setEvents] = useState<CalendarEvent[]>([
    // { id: '1', title: 'MS training', start: new Date(), end: new Date(), color: 'red' },
    // { id: '2', title: 'Board meeting', start: new Date(), end: new Date(), color: 'red' },
    // { id: '3', title: 'Team lead meeting', start: new Date(), end: new Date(), color: 'red' },

    // More events...
  ]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [vehicleManufacturers, setVehicleManufacturers] = useState<VehicleManufacturer[]>([]);
  const [stations, setStations] = useState<Station[]>([]);

  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(undefined);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const _fetchCustomers = async () => {
    const response = await fetchCustomers('');

    setCustomers(response as unknown as Customer[]);
  };

  const _fetchVehicles = async () => {
    const response = await fetchVehicles(searchTerm);

    setVehicles(response as unknown as Vehicle[]);
  };

  const _fetchVehicleManufacturers = async () => {
    const response = await fetchVehicleManufacturers();

    setVehicleManufacturers(response as unknown as VehicleManufacturer[]);
  };

  const _fetchStations = async () => {
    const response = await fetchStations();

    setStations(response as unknown as Station[]);
  };

  const _fetchAppointments = async () => {
    const appointmentsData = await fetchAppointments();

    setAppointments(appointmentsData as unknown as Appointment[]);

    if (Array.isArray(appointmentsData)) {  
      const _events = appointmentsData.map((appt: any) => {

        const startDate = new Date(appt.date);
        const startTimeParts = appt.startTime.split(':');
        const endDate = new Date(appt.date);
        const endTimeParts = appt.endTime.split(':');

        startDate.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]));
        endDate.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]));
        
        const station = stations.find(station => station.id === appt.stationId);

        return {
          id: appt.id,
          title: appt.name,
          start: startDate,
          end: endDate,
          color: station ? station.colorHex : '#3788D8'
        };
      });

      setEvents(_events as unknown as CalendarEvent[]);
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      
      try {

        await Promise.all([
          _fetchStations(),
          _fetchCustomers(),
          _fetchVehicles(),
          _fetchVehicleManufacturers()
        ]);

      } catch (error) {
        console.error("Si è verificato un errore", error);
        // notification.error({ message: 'Errore nel recupero dei dati', description: 'Non è stato possibile recuperare i dati. Riprova più tardi.' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {

    _fetchAppointments();

  }, [stations]);

  const _createAppointment = async (data: Appointment) => {
    setLoading(true);

    try {      
      const appointmentCreated = await createAppointment(data);

      if (appointmentCreated) {
        notification.success({
          message: 'Appuntamento Aggiunto',
          description: "L'Appuntamento è stato aggiunto con successo.",
        });
      }

      await _fetchAppointments();

    } catch (error) {
      if ((error as any).response.statusCode === 400) {
        notification.error({
          message: 'Errore',
          description: "Il nome dell'appuntamento è già in uso. Si prega di scegliere un nome diverso."
        });
      } else {
        notification.error({
          message: 'Errore',
          description: "Si è verificato un errore durante l'aggiunta dell'appuntamento.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const _createStation = async (data: Partial<NewStation>) => {
    setLoading(true);

    try {
      const stationCreated = await createStation(data);

      if (stationCreated) {
        notification.success({
          message: 'Postazione Aggiunta',
          description: "La Postazione è stata aggiunta con successo.",
        });
      }

      await _fetchStations();

    } catch (error) {
      console.error('Failed to add Station:', error);

      notification.error({
        message: 'Errore',
        description: "Si è verificato un errore durante l'aggiunta della postazione.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectVehicle = (vehicleId: string) => {
    const vehicle = vehicles.find(v => v.id === vehicleId);

    setSelectedVehicle(vehicle);
  };

  const handleResetSelectedVehicle = () => {
    setSelectedVehicle(undefined);
  };

  const handleDateSelect = (selectInfo: any) => {
    console.log("🚀 ~ selectInfo:",  selectInfo);

    let title = prompt('Please enter a new title for your event');
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: String(new Date().getTime()),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });      
    }

    // showModal();
  };

  const handleEventClick = (clickInfo: any) => {
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'?`)) {
    //   clickInfo.event.remove();
    // }

    const event = events.find(event => event.id === clickInfo.event.id) || null;

    if (event) {
      setSelectedEvent(event);

      const appointment = appointments.find(appointment => appointment.id === event.id);

      const selectedVehicle = vehicles.find(vehicle => vehicle.id === appointment?.vehicleId);

      const customer = customers.find((customer) => customer.id === selectedVehicle?.customerId);
      const customerDetails = `Cliente: ${formatName(customer?.name, customer?.surname)}, codice fiscale: ${customer?.fiscalCode || 'N/D'}, e-mail: ${customer?.email || 'N/D'}`;
      const vehicleDetails = `Veicolo: ${(vehicleManufacturers.find((vehicleManufacturer) => vehicleManufacturer.id === selectedVehicle?.manufacturerId))?.name || 'N/D'}, Targa: ${selectedVehicle?.plate || 'N/D'}`;
  
      // vehicleManufacturer={vehicleManufacturers.find((vehicleManufacturer) => vehicleManufacturer.id === selectedVehicle?.manufacturerId)}

      // clickInfo.event.remove();

      setIsModalVisible(true);
    }
  };

  const handleDeleteEventClick = (eventId: string) => {
    Modal.confirm({
      title: 'Sei sicuro di voler cancellare questo Appuntamento?',
      icon: <ExclamationCircleOutlined />,
      content: 'Questa azione è irreversibile',
      okText: 'Sì, cancella',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {

        if (selectedEvent) {
          await deleteAppointment(selectedEvent.id);
          await _fetchAppointments();
          setIsModalVisible(false); // Close the modal upon deletion
        }
      },
      onCancel() {
        console.log('Delete Cancelled');
      },
    });
  };

  // const handleEvents = (events: CalendarEvent[]) => {
  //   setEvents(events);
  // };

  const handleAddStationFormSubmit = async (newStation: { name: string, colorHex: string }) => {
    await _createStation(newStation);
  };

  const handleAddEventFormSubmit = async (newEvent: { title: string, date: Date, start: Date, end: Date, stationId?: string }) => {

    await _createAppointment({
      stationId: newEvent.stationId,
      vehicleId: selectedVehicle?.id,
      name: newEvent.title,
      date: newEvent.date,
      startTime: newEvent.start.toTimeString().split(' ')[0],
      endTime: newEvent.end.toTimeString().split(' ')[0],
    } as unknown as Appointment);

    // form.resetFields();
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title={'Calendario Appuntamenti'}
        >
          <Space direction="vertical" size={24} style={{ width: '100%' }}>

            <AppointmentForm
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              plates={vehicles.map(vehicle => vehicle.plate)}
              stations={stations}
              vehicleIds={vehicles.map(vehicle => vehicle.id)}
              onSelectVehicle={handleSelectVehicle}
              onResetSelectedVehicle={handleResetSelectedVehicle}
              onSubmit={handleAddEventFormSubmit}
              onAddNewStation={handleAddStationFormSubmit}
            />

            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
              }}
              initialView="dayGridMonth"
              dayMaxEvents={true}
              editable={true}
              selectable={true}
              selectMirror={true}
              weekends={true}
              select={handleDateSelect}
              eventClick={handleEventClick}
              events={events}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                meridiem: false
              }}
              snapDuration={'00:05:00'}

              // eventContent={renderEventContent} // custom render function
              // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
              /* you can update a remote database when these fire:

                eventAdd={function(){}}
                eventChange={function(){}}
                eventRemove={function(){}}
              */
            />

            {selectedEvent &&<Modal
              title="Dettagli dell'Evento"
              visible={isEventModalVisible}
              onCancel={() => setIsModalVisible(false)}
              footer={[
                <Button key="delete" type="primary" onClick={() => handleDeleteEventClick(selectedEvent?.id || '')}>
                  Elimina Evento
                </Button>,
                <Button key="back" onClick={() => {
                  setSelectedEvent(null);
                  setIsModalVisible(false);
                }}>
                  Chiudi
                </Button>,
              ]}
            >
              {/* {selectedEvent && ( */}
              <div>
                <p>Targa: {selectedEvent.title}</p>
                <p>Inizio: {selectedEvent.start.toLocaleString()}</p>
                <p>Fine: {selectedEvent.end.toLocaleString()}</p>

                <p>Colore: <span style={{ color: selectedEvent.color }}>{selectedEvent.color}</span></p>
              </div>
              {/* )} */}        
            </Modal>}
          </Space>
        </Card>
      </Col>
    </Row>
  );
};
