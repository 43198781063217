import React, { useImperativeHandle, forwardRef, useEffect, useMemo, useState } from 'react';
import { InputNumber, Spin, Table, Typography } from 'antd';
import { Service } from './types';


const { Text } = Typography;

interface ServicesTableProps {
  dataSource: Service[];
  loading: boolean;
}

export const ServicesTable = forwardRef(({ dataSource, loading }: ServicesTableProps, ref) => {

  const [data, setData] = useState<Service[]>([]);
  const [discount, setDiscount] = useState<number>(0);

  useEffect(() => {
    setData(dataSource.map((service) => ({
      ...service,
      key: service.id,
      price: typeof service.price === 'string' ? parseFloat(service.price) : service.price,
      vat: typeof service.vat === 'string' ? parseFloat(service.vat) : service.vat,
      quantity: 0,
      total: 0,
    })));
  }, [dataSource]);

  const columns = [
    {
      title: 'Tipologia Servizio',
      dataIndex: 'name',
      key: 'name',
      flex: 2
    },
    {
      title: 'Prezzo (€)',
      dataIndex: 'price',
      key: 'price',
      flex: 1,
      render: (price: number) => <Text>{Number.isNaN(price) ? "N/A" : price.toFixed(2)}</Text>
    },
    {
      title: 'IVA (%)',
      dataIndex: 'vat',
      key: 'vat',
      flex: 1,
      render: (iva: number, record: Service) => (
        <InputNumber
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => parseFloat(value?.replace('%', '') ?? '0')}
          value={iva}
          onChange={(value) => handleIvaChange(record.id, value ?? 0)}
        />
      ),
    },
    {
      title: 'Quantità',
      dataIndex: 'quantity',
      key: 'quantity',
      flex: 1,
      render: (quantity: number, record: Service) => (
        <InputNumber
          min={0}
          value={quantity}
          onChange={(value) => handleQuantityChange(record.id, value ?? 0)}
        />
      )
    },
    {
      title: 'Totale (€)',
      dataIndex: 'total',
      key: 'total',
      flex: 1,
      render: (_: number, record: Service) => {
        const price = parseFloat(record.price as any);
        const quantity = parseInt(record.quantity as any);

        if (isNaN(price) || isNaN(quantity)) {
          return <Text>N/A</Text>;
        }

        const totalWithVAT = (price * quantity) * (1 + (record.vat / 100));

        return <Text>{totalWithVAT.toFixed(2)}</Text>;
      }
    },
  ];

  useImperativeHandle(ref, () => ({
    getUpdatedData: () => data,
    getTotals: () => {
      const subtotal = data.reduce((sum, item) => sum + item.total, 0);
      const discountRate = discount;
      const total = subtotal * (1 - discountRate / 100);
      
      return { subtotal, discountRate, total };
    }
  }));

  const handleIvaChange = (key: string, vat?: number) => {
    const newData = data.map(item => {
      if (item.id === key) {
        const updatedTotal = calculateTotalWithVAT(item.price, item.quantity, vat);

        return {
          ...item,
          vat: vat ?? item.vat,
          total: updatedTotal,
        };
      }
      
      return item;
    });

    setData(newData);
  };

  const handleQuantityChange = (key: string, quantity?: number) => {
    const newData = data.map(item => {
      if (item.id === key) {
        const updatedTotal = calculateTotalWithVAT(item.price, quantity, item.vat);

        return {
          ...item,
          quantity: quantity ?? 0,
          total: updatedTotal,
        };
      }

      return item;
    });

    setData(newData);
  };

  const calculateTotalWithVAT = (price: number, quantity: number | undefined, iva: number | undefined) => {
    return parseFloat(((price * (quantity ?? 0)) * (1 + (iva ?? 0) / 100)).toFixed(2));
  };

  const subtotal = useMemo(() => 
    data.reduce((sum, item) => sum + item.total, 0), [data]
  );

  const grandTotal = useMemo(() => 
    subtotal * (1 - discount / 100), [subtotal, discount]
  );

  const handleDiscountChange = (value?: number | null) => {
    setDiscount(value ?? 0);
  };

  const summary = () => (
    <Table.Summary fixed>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={4}>Subtotale</Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={1}>
          <Text type="secondary">{subtotal.toFixed(2)} €</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={4}>Sconto (%)</Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={1}>
          <InputNumber
            min={0}
            max={100}
            formatter={value => `${value}%`}
            parser={value => value ? parseInt(value.replace('%', ''), 10) : 0}
            value={discount}
            onChange={handleDiscountChange}
            style={{ width: '100px' }}
          />
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={4}>Totale</Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={1}>
          <Text type="success">{grandTotal.toFixed(2)} €</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );

  return (
    <Spin spinning={loading}>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        summary={summary}
        size="small"
      />
    </Spin>
  );
});
