import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { FormListFieldData } from 'antd/lib';
import { makeRackNameIsUnique } from '../../../routes';


interface AddIslandFormProps {
  field: FormListFieldData;
  isSubmitting?: boolean;
  disabled?: boolean;
  warehouseId?: string;
}

const AddIslandForm: React.FC<AddIslandFormProps> = ({ field, isSubmitting = false, disabled = false, warehouseId }) => (
  <>
    <Form.Item
      name={[field.name, 'name']}
      label="Nome dell'Isola"
      {...(disabled ? {
        hasFeedback: true,
        validateTrigger: ['onBlur', 'onChange'],
        validateDebounce: 1000,
        rules: (isSubmitting && warehouseId) ? [{ validator: makeRackNameIsUnique(warehouseId) }] : []
      } : { validateDebounce: 0 })}
    >
      <Input placeholder="Nome dell'Isola" style={{ width: '100%' }} />
    </Form.Item>
    <Form.Item
      name={[field.name, 'slots']}
      label="Numero Totale Slot"
    >
      <InputNumber placeholder="Numero di Slot" min={1} style={{ width: '100%' }} disabled={disabled} />
    </Form.Item>
  </>
);

export default AddIslandForm;
