import React, { useEffect, useRef, useState } from 'react';
import { Card, Row, Col, List, Badge, Typography, Statistic, Space, Progress, Button, Select, notification } from 'antd';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import { WarehouseCostsStats, WarehouseStats, WarehouseTyresStats, WarehouseWithStats } from '../warehouses';
import { Service, ServicesTable } from '../preventives';
import { PrinterOutlined } from '@ant-design/icons';

// import './WarehousesStats.css';


const COLORS = ['#1890ff', '#52c41a', '#ff4d4f', '#BABCC3'];

const { Text } = Typography;

interface QuickQuoteCardProps {
  services: Service[];
  loading: boolean;
}

export const QuickQuoteCard = ({
  services,
  loading
}: QuickQuoteCardProps): JSX.Element => {

  const servicesTableRef = useRef<any>();

  const handlePrintPreventive = () => {
    const data = servicesTableRef.current.getUpdatedData().filter((service: Service) => service.quantity > 0);
    const { subtotal, discountRate, total } = servicesTableRef.current.getTotals();

    if (data.length > 0) {
      const doc = new jsPDF();

      const title = 'Preventivo Servizi';

      doc.setFontSize(18);
      doc.setFont('helvetica', 'bold');

      const titleWidth = doc.getTextWidth(title);
      const margin = 14;

      doc.text(title, margin, 20);

      const pageWidth = doc.internal.pageSize.getWidth();
      
      const date = new Intl.DateTimeFormat('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date());

      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');

      const dateWidth = doc.getTextWidth(`Data: ${date}`);

      const dateX = pageWidth - dateWidth - margin;

      if (dateX > (titleWidth + 2 * margin)) {
        doc.text(`Data: ${date}`, dateX, 20);
      } else {
        doc.text(`Data: ${date}`, titleWidth + 2 * margin, 20);
      }

      // if (selectedVehicle) {
      //   const vehicleDetails = `Veicolo: ${(vehicleManufacturers.find((vehicleManufacturer) => vehicleManufacturer.id === selectedVehicle?.manufacturerId))?.name || 'N/D'}, Targa: ${selectedVehicle.plate || 'N/D'}`;
      //   const customer = customers.find((customer) => customer.id === selectedVehicle?.customerId);
      //   const customerDetails = `Cliente: ${formatName(customer?.name, customer?.surname)}, codice fiscale: ${customer?.fiscalCode || 'N/D'}, e-mail: ${customer?.email || 'N/D'}`;
        
      //   doc.setFontSize(10);

      //   doc.text(vehicleDetails, 14, 30);
      //   doc.text(customerDetails, 14, 35);
      // }

      const headers = [['Nome', 'Prezzo', 'IVA', 'Quantità', 'Totale']];

      autoTable(doc, {
        head: headers,
        body: data.map((service: Service) => [
          service.name,
          `${service.price.toFixed(2)} €`,
          `${service.vat}%`,
          service.quantity.toString(),
          `${((service.price * service.quantity) * (1 + service.vat / 100)).toFixed(2)} €`
        ]),
        foot: [
          ['Subtotale', '', '', '', `${subtotal.toFixed(2)} €`],
          ['Sconto (%)', '', '', '', `${discountRate.toFixed(2)} %`],
          ['Totale', '', '', '', `${total.toFixed(2)} €`]
        ],
        startY: 40,
        theme: 'grid',
        headStyles: { fontStyle: 'bold' },
        styles: { fontSize: 10, cellPadding: 2 },
        columnStyles: { text: { cellWidth: 'wrap' } },
        margin: { top: 30 }
      });
  
      doc.save('preventivo.pdf');
    } else {
      notification.error({
        message: 'Nessun Servizio Selezionato',
        description: 'Non ci sono servizi con quantità selezionata per la stampa del preventivo.'
      });
    }
  };

  return (
    <Card 
      bordered={false}
      className='responsive-card'
      title='Preventivo Rapido' // {`Statistiche Magazzino ${warehouseName}`}
      // extra={
      //   <Select placeholder="Seleziona un Magazzino" value={selectedWarehouseId} defaultValue={selectedWarehouseId} onChange={handleWarehouseChange} style={{ width: '100px' }}>
      //     {warehouses.map((warehouse) => (
      //       <Select.Option key={warehouse.id} value={warehouse.id}>
      //         {warehouse.name}
      //       </Select.Option>
      //     ))}
      //   </Select>
      // } 
      style={{ flex: 1 }}
    >
      <Space direction="vertical" size={24} style={{ width: '100%' }}>

        <ServicesTable 
          ref={servicesTableRef}
          dataSource={services}
          loading={loading}
        />

        <Button 
          type="default" 
          icon={<PrinterOutlined />}
          style={{ display: 'block', margin: '0 auto' }}
          onClick={() => handlePrintPreventive()}
        >
          Stampa Preventivo
        </Button>

      </Space>
    </Card>
  );
};
