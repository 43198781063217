import React, { useEffect, useState } from 'react';
import { Table, Spin, Space, Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { RackConfiguration, Warehouse, WarehouseWithStats } from '../warehouses';


interface RacksTableProps {
  dataSource: WarehouseWithStats | undefined;  // RackConfiguration[] | null;
  loading: boolean;
  onShow: (id: string) => void;
  onCreateSlot: (id: string, totalSlots: number, occupiedSlots: number, warehouse: WarehouseWithStats | undefined) => void;
  onRemove: (id: string) => void;
}

const getRackTypeLabel = (type: string) => {
  switch (type) {
    case 'standard':
      return 'Rastrelliera';
    case 'island':
      return 'Isola';
    default:
      return 'Tipo non specificato';
  }
};

export function RacksTable({
  dataSource,
  loading,
  onShow,
  onCreateSlot,
  onRemove,
}: RacksTableProps): JSX.Element {

  const [computedData, setComputedData] = useState<RackConfiguration[] | undefined>(undefined);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const columns: ColumnProps<RackConfiguration>[] = [
    {
      title: 'Nome ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tipo Rack',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => getRackTypeLabel(type),
    },
    {
      title: 'Totale Slot',
      dataIndex: 'slots',
      key: 'slots',
    },
    {
      title: 'Totale Slot Occupati',
      dataIndex: 'occupiedSlots',
      key: 'occupiedSlots',
    },
    {
      title: 'Rack ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record: any) => (
        <Space size="middle">
          <Button type="link" onClick={() => onShow(record.id)} disabled={record.occupiedSlots === 0}>Visualizza</Button>
          <Button type="link" onClick={() => onCreateSlot(record.id, record.slots, record.occupiedSlots, dataSource)} disabled={record.slots === record.occupiedSlots}>Crea Slot</Button>
          <Button type="link" onClick={() => onRemove(record.id)}>Rimuovi</Button>
        </Space>
      ),
    }
  ];

  const rowSelection: TableRowSelection<RackConfiguration> = {
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    selectedRowKeys,
  };

  useEffect(() => {
    if (dataSource) {
      const filteredRacks = dataSource.racks?.filter(rack => rack.slots > 0) || [];
      const filteredIslands = dataSource.islands?.filter(island => island.slots > 0) || [];

      const data = [...filteredRacks, ...filteredIslands];
      if (data.length > 0) {
        setComputedData(data);
      } else {
        setComputedData([]);
      }
    }
  }, [dataSource]);

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={computedData}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: computedData ? computedData.length : 0,
        }}
        rowKey="id"
        rowClassName={(record: any) => (record.id && `row-${record.id}`)}
        rowSelection={rowSelection}
        size='small'
      />
    </Spin>
  );
}
