import React, { useEffect } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { MainLayout } from '../../layouts/MainLayout';
import { Appointments, Articles, Customers, Dashboard, DeliveriesAndPickups, Login, Preventives, Settings, Suppliers, Tyres, Search, Vehicles, Warehouses, WarehouseDetails, WarehouseRackDetails } from '../../routes';
import { useAuth } from '../../hooks/auth';
import { fetchUser } from '../../api/SettingsApi';
import { useSetRecoilState } from 'recoil';
import { userState } from '../../core/user';


const getPrivateRoutes =
  createBrowserRouter([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <Dashboard /> },
        { 
          path: 'customers', 
          element: <Customers />,
          children: [
            { path: 'new' },
            { path: 'edit/:customerId' },
          ],
        },
        { path: 'articles', element: <Articles /> },
        { 
          path: 'vehicles', 
          element: <Vehicles />,
          children: [
            { path: 'new' },
            { path: 'edit/:vehicleId' },
          ],
        },
        { 
          path: 'suppliers', 
          element: <Suppliers />,
          children: [
            { path: 'new' },
            { path: 'edit/:supplierId' },
          ],
        },
        { 
          path: 'tyres', 
          element: <Tyres />,
          children: [
            { path: 'new' },
            { path: 'edit/:tyreId' },
          ],
        },
        { 
          path: 'warehouse/show/:warehouseId/rack/:rackId/slots', 
          element: <WarehouseRackDetails />,
          children: [
            { path: 'new', element: <WarehouseRackDetails /> },
          ],
        },
        { 
          path: 'warehouse/show', 
          element: <WarehouseDetails />,
          children: [
            { path: ':warehouseId', element: <WarehouseRackDetails /> },
          ],
        },
        { 
          path: 'warehouse', 
          element: <Warehouses />,
          children: [
            { path: 'new' },
            { path: 'edit/:warehouseId' },
            { path: 'stats' },
          ],
        },
        { path: 'deliveries-and-pickups', element: <DeliveriesAndPickups /> },
        { path: 'appointments', element: <Appointments /> },
        { path: 'new-preventive', element: <Preventives /> },
        { path: 'search', element: <Search /> },
        { 
          path: 'settings', 
          element: <Settings />,
          children: [
            { path: 'user' },
          ]
        },
      ],
    },
  ]);

const getPublicRoutes =
  createBrowserRouter([
    {
      path: '/',
      element: <Login />,
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    }
  ]);

export const RootRoutes = () => {
  const { checkUserAuth, isLoggedIn, isLoggedInLoading } = useAuth();

  const setUser = useSetRecoilState(userState);

  useEffect(() => {
    const initializeApp = async () => {
      checkUserAuth();

      if (isLoggedIn) {
        try {
          const user = await fetchUser() as any;
          
          if (Array.isArray(user.result)) {
            if ( user.result.length > 0) {
              setUser(user.result[0]);
            }
          }
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      }
    };

    initializeApp();
  }, [checkUserAuth, isLoggedIn, setUser]);

  if (isLoggedInLoading) {
    return null;
  }

  return (
    <RouterProvider router={isLoggedIn ? getPrivateRoutes : getPublicRoutes} />
  );
};
