import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { isLoggedInState } from '../core';
import { getCurrentUser, signOut as signOutAmplify } from 'aws-amplify/auth';

export function useAuth() {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [isLoggedInLoading, setIsLoggedInLoading] = useState(true);

  const checkUserAuth = useCallback(() => {
    getCurrentUser()
      .then(user => {
        setIsLoggedIn(!!user);
      })
      .catch(() => {
        setIsLoggedIn(false);
      })
      .finally(() => {
        setIsLoggedInLoading(false);
      });
  }, [setIsLoggedIn]);

  const signOut = useCallback(() => {
    signOutAmplify()
      .then(() => {
        setIsLoggedIn(false);
      })
      .catch(err => {
        console.log('[signOut] error', err);
      });
  }, [setIsLoggedIn]);

  return {
    checkUserAuth,
    isLoggedIn,
    isLoggedInLoading,
    signOut,
  };
}
