import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, Row, Col, Modal, Tabs, notification } from 'antd';
import type { FormInstance, TabsProps } from 'antd';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { fetchTyres, fetchTyreBrands, fetchVehicles, createTyre, editTyre, deleteTyre, createTyreBrand } from '../api';
import { Tyre, TyreBrand, TyreForm, TyresSearch, TyresTable } from '../components/tyres';
import { Vehicle } from '../components/vehicles';
import { CirclePlusIcon } from '../components/IconWrapper';
import { PageTabs } from '../constants/NavigationTabs';
import { TyreSeasonsSelectValues, TyreStatusSelectValues, TyreNoiseLevelsSelectValues } from '../components/tyres/constants';
import { TableRowSelection } from 'antd/es/table/interface';

// import { Tyre } from '../types'; // TODO: typing


const { confirm } = Modal;

export function Tyres() {
  const [cardActions, setCardActions] = useState<JSX.Element[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [tyres, setTyres] = useState<Tyre[]>([]);
  const [tyreBrands, setTyreBrands] = useState<TyreBrand[]>([]);
  const [tyreBrand, setTyreBrand] = useState<TyreBrand | undefined>(undefined);

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const formRef = useRef<FormInstance>(null);

  const { tyreId } = useParams();
  
  const tyreSeasons = TyreSeasonsSelectValues;
  const tyreStatus = TyreStatusSelectValues;
  const tyreNoiseLevels = TyreNoiseLevelsSelectValues;

  const location = useLocation();
  const navigate = useNavigate();

  const isEditPage = location.pathname.includes('/edit');
  const isNewPage = location.pathname.endsWith('/new');

  const getActiveTabKeyFromPath = () => {
    if (isNewPage) return PageTabs.New;
    return PageTabs.List;
  };

  const [activeTabKey, setActiveTabKey] = useState<string>(getActiveTabKeyFromPath());

  useEffect(() => {
    let actions: JSX.Element[] = [];

    if (isNewPage) {
      actions = [
        <div key="add1" className='action-icon' onClick={() => handleSubmit(1)} >
          <CirclePlusIcon size='xl' />
          <span style={{ marginLeft: 8 }}>Aggiungi 1 Pneumatico</span>
        </div>,
        <div key="add2" className='action-icon' onClick={() => handleSubmit(2)} >
          <CirclePlusIcon size='xl' />
          <span style={{ marginLeft: 8 }}>Aggiungi 2 Pneumatici</span>
        </div>,
        <div key="add4" className='action-icon' onClick={() => handleSubmit(4)} >
          <CirclePlusIcon size='xl' />
          <span style={{ marginLeft: 8 }}>Aggiungi 4 Pneumatici</span>
        </div>
      ];
    } else if (isEditPage) {
      actions = [
        <div className='action-icon' onClick={() => handleSubmit()} >
          <EditOutlined />
        </div>
      ];
    }

    setCardActions(actions);

    if (location.pathname === '/tyres') {
      _fetchTyres();
    }

  }, [location.pathname, isNewPage, isEditPage]);

  const _fetchTyres = async () => {

    const response = await fetchTyres(searchTerm);

    setTyres(response as unknown as Tyre[]);
  };

  const _fetchTyreBrands = async () => {

    const response = await fetchTyreBrands();

    setTyreBrands(response as unknown as TyreBrand[]);
  };

  const _fetchVehicles = async () => {

    const response = await fetchVehicles('');

    setVehicles(response as unknown as Vehicle[]);
  };

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      
      try {
        await Promise.all([
          _fetchVehicles(),
          _fetchTyreBrands(),
          _fetchTyres(),
        ]);
      } catch (error) {
        console.error("Si è verificato un errore", error);
        // notification.error({ message: 'Errore nel recupero dei dati', description: 'Non è stato possibile recuperare i dati. Riprova più tardi.' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const _createTyre = async (data: Tyre) => {
    setLoading(true);

    try {      
      const tyreCreated = await createTyre(data);

      if (tyreCreated) {
        notification.success({
          message: 'Pneumatico Aggiunto',
          description: 'Il Pneumatico è stato aggiunto con successo.',
        });

        await _fetchTyres();
      }

    } catch (error) {
      console.error('Failed to add Tyre:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'aggiunta del Pneumatico.',
      });
    } finally {
      setLoading(false);
    }
  };

  const _editTyre = async (data: Tyre) => {
    
    if (tyreId) {
      
      setLoading(true);

      try {      
        const tyreEdited = await editTyre(tyreId, data);
  
        if (tyreEdited) {
          notification.success({
            message: 'Pneumatico Modificato',
            description: 'Il Pneumatico è stato modificato con successo.',
          });
  
          await _fetchTyres();
        }
  
      } catch (error) {
        console.error('Failed to edit Tyre:', error);
  
        notification.error({
          message: 'Errore',
          description: 'Si è verificato un errore durante la modifica del Pneumatico.',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const _deleteTyre = async (id: string) => {
    setLoading(true);

    try {      
      const tyreDeleted = await deleteTyre(id);

      if (tyreDeleted) {
        notification.success({
          message: 'Pneumatico Eliminato',
          description: 'Il Pneumatico è stato eliminato con successo.',
        });

        await _fetchTyres();
      }

    } catch (error) {
      console.error('Failed to remove Tyre:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante la rimozione del Pneumatico.',
      });
    } finally {
      setLoading(false);
    }
  };

  const _createTyreBrand = async (name: string) => {
    
    setLoading(true);

    try {      
      const tyreBrandCreated = (await createTyreBrand({ name, country: '' }))[0];

      if (tyreBrandCreated) {
        notification.success({
          message: 'Marca Pneumatico Aggiunta',
          description: 'La marca del Pneumatico è stata aggiunta con successo.',
        });

        await _fetchTyreBrands();

        setTyreBrand(tyreBrandCreated as unknown as TyreBrand);
      }

    } catch (error) {
      console.error('Failed to edit Tyre:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante la modifica del Pneumatico.',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection: TableRowSelection<Tyre> = {
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    selectedRowKeys,
  };

  const handleClickAdd = (): void => {
    setActiveTabKey(PageTabs.New);
    navigate('/tyres/new');
  };

  const handleAddNewTyreBrand = (name: string): void => {
    _createTyreBrand(name);
  };

  const handleClickEdit = (id: string) => {
    console.log("🚀 ~ handleClickEdit ~ id:", id);
    
    navigate(`/tyres/edit/${id}`);
  };

  const handleClickDelete = (id: string) => {
    confirm({
      title: 'Sei sicuro di voler cancellare questo Pneumatico?',
      icon: <ExclamationCircleOutlined />,
      content: 'Questa azione è irreversibile',
      okText: 'Sì, cancella',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        if (id) { await _deleteTyre(id); }
      },
      onCancel() {
        console.log('Delete Cancelled');
      },
    });
  };

  //

  const tabItems: TabsProps['items'] = [
    {
      label: 'Lista Pneumatici',
      key: PageTabs.List,
      children: (
        <>
          <TyresSearch 
            searchTerm={searchTerm} 
            setSearchTerm={setSearchTerm} 
            onSearch={_fetchTyres}
            onAdd={handleClickAdd}
          />

          <TyresTable 
            dataSource={tyres}
            tyreBrands={tyreBrands}
            tyreSeasons={tyreSeasons}
            vehicles={vehicles}
            loading={loading}
            onEdit={handleClickEdit}
            onDelete={handleClickDelete}
            rowSelection={rowSelection}
          />
        </>
      ),
    },
    {
      label: 'Aggiungi Pneumatico',
      key: PageTabs.New
    },
  ];

  const onTabChange: TabsProps['onChange'] = (key: string) => {

    setActiveTabKey(key);

    switch (key) {
      case PageTabs.List:
        navigate('/tyres');
        break;
      case PageTabs.New:
        navigate('/tyres/new');
        break;
      default:
        break;
    }
  };

  const handleAddTyreFormSubmit = async (formData: Tyre) => {

    if (formData.year) {
      formData.year = new Date(formData.year).getFullYear();
    }

    await _createTyre(formData);
  };

  const handleEditTyreFormSubmit = async (formData: Tyre) => {

    if (formData.year) {
      formData.year = new Date(formData.year).getFullYear();
    }

    await _editTyre(formData);
  };

  const handleSubmit = async (numTyres: number = 1) => {

    const formData = formRef.current?.getFieldsValue();

    if (formData) {
      const promises = [];
  
      for (let i = 0; i < numTyres; i++) {
        promises.push(handleAddTyreFormSubmit({ ...formData }));
      }
  
      await Promise.all(promises);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title={isEditPage ? 'Modifica Pneumatico' : 'Gestione dei Pneumatici'}
          extra={isEditPage ? <Link to="/tyres">Torna alla lista</Link> : <Link to="/vehicles">Torna alla lista Veicoli</Link>}
          actions={cardActions}
        >
          {!isEditPage && (
            <Tabs defaultActiveKey={PageTabs.List} activeKey={activeTabKey} onChange={onTabChange} items={tabItems} />
          )}

          {isNewPage && 
            <TyreForm
              mode='add'
              ref={formRef} 
              tyreBrands={tyreBrands}
              tyreNoiseLevelOptions={tyreNoiseLevels}
              tyreSeasonOptions={tyreSeasons}
              tyreStatusOptions={tyreStatus}
              vehicles={vehicles}
              vehicle={location.state?.vehicle}
              onSubmit={handleAddTyreFormSubmit} 
              onAddNewTyreBrand={handleAddNewTyreBrand}
              tyreBrand={tyreBrand} />}

          {isEditPage && 
            <TyreForm
              mode='edit'
              ref={formRef} 
              tyreId={tyreId} 
              initialData={tyres.find(tyre => tyre.id === tyreId)} 
              tyreBrands={tyreBrands}
              tyreNoiseLevelOptions={tyreNoiseLevels}
              tyreSeasonOptions={tyreSeasons}
              tyreStatusOptions={tyreStatus}
              vehicles={vehicles}
              onSubmit={handleEditTyreFormSubmit} 
              onAddNewTyreBrand={handleAddNewTyreBrand}
              tyreBrand={tyreBrand} />}
        </Card>
      </Col>
    </Row>
  );
}
