import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SunOutlined, MoonOutlined } from '@ant-design/icons';
import { Avatar, AvatarProps, Card, Col, Dropdown, Input, Layout, Menu, Row, Switch } from 'antd';
import { MenuProps } from 'antd/lib';
import { useRecoilValue } from 'recoil';
import { userState } from '../../core/user';
import { SuppliersIcon, WarehouseIcon, UsersIcon, SearchIcon, UserIcon, ArticlesIcon, VehiclesIcon, SettingsIcon, DeliveriesIcon, CreateQuoteIcon, AppointmentsCalendarIcon, TyreListIcon } from '../IconWrapper';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import logo from '../../assets/images/logo.png';
import './SideNavigation.css';

const { Sider } = Layout;

const Logo = () => <img src={logo} alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />;

interface AvatarDropdownProps {
  avatarSize: AvatarProps['size'];
  iconSize: SizeProp;
  onLogout: () => void;
}

const AvatarDropdown: React.FC<AvatarDropdownProps> = ({ onLogout, avatarSize, iconSize }) => (
  <Dropdown
    trigger={['click']}
    placement="bottomLeft"
    menu={{
      items: [
        {
          label: 'Logout',
          key: 'logout',
          onClick: () => onLogout(),
        },
      ],
    }}
  >
    <Avatar size={avatarSize} icon={<UserIcon size={iconSize} />} style={{ cursor: 'pointer', marginRight: '8px' }} />
  </Dropdown>
);

const createMenuItems = (navigate: ReturnType<typeof useNavigate>): MenuProps['items'] => [
  {
    key: '/articles',
    label: <Link to="/articles">Articoli</Link>,
    icon: <ArticlesIcon size="lg" style={{ marginRight: '8px' }} />,
  },
  {
    key: '/customers',
    label: <Link to='/customers'>Anagrafica Clienti</Link>,
    icon: <UsersIcon style={{ marginRight: '8px' }}/>,
  },
  {
    key: '/vehicles',
    label: <Link to="/vehicles">Veicoli</Link>,
    icon: <VehiclesIcon style={{ marginRight: '8px' }}/>,
  },
  {
    key: '/tyres',
    label: <Link to="/tyres">Lista Pneumatici</Link>,
    icon: <TyreListIcon style={{ marginRight: '8px' }}/>,
  },
  {
    key: '/suppliers',
    label: <Link to='/suppliers'>Fornitori</Link>,
    icon: <SuppliersIcon style={{ marginRight: '8px' }}/>,
  },
  {
    key: '/warehouse',
    label: <Link to="/warehouse">Magazzino</Link>,
    icon: <WarehouseIcon style={{ marginRight: '8px' }}/>,
  },
  {
    key: '/deliveries-and-pickups',
    label: <Link to="/deliveries-and-pickups">Ritiri e Consegne</Link>,
    icon: <DeliveriesIcon style={{ marginRight: '8px' }}/>,
  },
  {
    key: '/appointments',
    label: <Link to="/appointments">Calendario Appuntamenti</Link>,
    icon: <AppointmentsCalendarIcon style={{ marginRight: '8px' }}/>,
  },
  {
    key: '/new-preventive',
    label: <Link to="/new-preventive">Crea Nuovo Preventivo</Link>,
    icon: <CreateQuoteIcon size="lg" style={{ marginRight: '8px' }} />,
  },
  {
    key: '/search',
    label: (
      <Row align="middle">
        <Col span={24}>
          <Input 
            placeholder="Cerca Targa"
            onPressEnter={(e) => {
              const query = e.currentTarget.value;
              navigate(`/search?q=${query}`);
            }}
          />
        </Col>
      </Row>
    ),
    icon: <SearchIcon size="lg"/>,
  },
  {
    key: '/settings',
    label: <Link to="/settings/user">Impostazioni Applicazione</Link>,
    icon: <SettingsIcon size="lg" style={{ marginRight: '8px' }} />,
  },
];

interface SideNavigationProps {
  onLogout: () => void;
  theme: 'light' | 'dark';
  changeTheme: (checked: boolean) => void;
}

export const SideNavigation: React.FC<SideNavigationProps> = ({
  onLogout,
  theme,
  changeTheme,
}) => {
  const user = useRecoilValue(userState);

  const location = useLocation();
  const navigate = useNavigate();

  const items = createMenuItems(navigate);

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  const onChangeTheme = (checked: boolean) => {
    changeTheme(checked);
  };

  const handleClickLogout = () => {
    onLogout();
  };

  const handleAvatarClick = () => {
    if (collapsed) {
      setCollapsed((prev) => !prev);
    }
  };

  return (
    <Sider 
      width={340} 
      theme={theme}
      style={{ 
        display: 'flex', 
        flexDirection: 'column',
        color: 'white',
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed) => onCollapse(collapsed)}
      breakpoint='lg'
      onBreakpoint={broken => {
        console.log('broken', broken);
      }}
      className="menu-gradient-color"
    >
      <div
        style={{
          marginTop: collapsed ? 8 : 24,
          paddingRight: collapsed ? 16 : 32,
          paddingLeft: collapsed ? 16 : 32,
        }}
      >
        <div className="logo" style={{ 
          margin: collapsed ? '8px 0px' : '8px 32px',
          textAlign: 'center'
        }}>
          <Logo />
        </div>

        <div style={{
          display: 'flex', 
          justifyContent: 'center',
          marginTop: collapsed ? 16 : 24,
        }}>
          {
            collapsed ? 
              <Avatar
                size="large"
                icon={<UserIcon size='lg' />}
                style={{
                  cursor: 'pointer',
                  marginTop: '8px'
                }}
                onClick={handleAvatarClick}
              />
            :
              <Card
                style={{
                  width: '100%',
                }}
                className='profile-card menu-font-family'
                bordered={false}
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <AvatarDropdown onLogout={handleClickLogout} avatarSize="large" iconSize="lg" />
                  <div style={{ alignSelf: 'center' }}>
                    <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}>Operatore</div>
                    <div style={{ fontSize: '16px' }}>{user?.name || 'Erik'} {user?.surname || 'Cartman'}</div>
                  </div>
                </div>
              </Card>
          }
        </div>

        <Menu
          defaultSelectedKeys={[location.pathname]}
          items={items}
          mode='inline'
          theme={theme}
          style={{
            marginTop: '32px',
            marginBottom: '24px',
            paddingTop: collapsed ? 0 : 8,
            paddingBottom: collapsed ? 0 : 8,
            border: 0,
            backgroundColor: 'transparent',
            fontSize: '18px',
            lineHeight: '22px',
          }}
          className='menu menu-font-family'
        />

        <div 
          style={{
            padding: '16px 0',
            textAlign: 'center', 
          }}
          className='menu-font-family'
        >
          <span 
            style={{ 
              marginRight: 8,
              display: collapsed ? 'none' : 'inline',
            }}>Light</span>
          <Switch
            checked={theme === 'dark'}
            onChange={onChangeTheme}
            checkedChildren={<MoonOutlined />}
            unCheckedChildren={<SunOutlined />}
          />
          <span style={{ marginLeft: 8, display: collapsed ? 'none' : 'inline' }}>Dark</span>
        </div>
      </div>
    </Sider>
  );
}
