import React, { useEffect, useState } from 'react';
import { Card, Row, Col, List, Badge, Typography, Statistic, Space, Progress, Button, Select } from 'antd';
import { WarehouseCostsStats, WarehouseStats, WarehouseTyresStats, WarehouseWithStats } from '../../components/warehouses';

// import './WarehousesStats.css';


const COLORS = ['#1890ff', '#52c41a', '#ff4d4f', '#BABCC3'];

const { Text } = Typography;

interface WarehouseDistributionCardProps {
  warehouses: WarehouseWithStats[];
  warehouseName: string;
  onWarehouseChange: (warehouseId: string) => void;
  costsStats: WarehouseCostsStats;
  stats: WarehouseStats;
  tyresStats: WarehouseTyresStats;
}

export const WarehouseDistributionCard = ({
  warehouses,
  warehouseName,
  onWarehouseChange,
  costsStats,
  stats,
  tyresStats
}: WarehouseDistributionCardProps): JSX.Element => {

  const [selectedWarehouseId, setSelectedWarehouseId] = useState<string>(warehouses[0]?.id);
  
  const selectedWarehouse = warehouses.find(warehouse => warehouse.id === selectedWarehouseId);

  const redDot = <Badge color="#ff4d4f" dot />;

  const totalCapacity = tyresStats.total_warehouse_capacity;

  const { total_slots: totalSlots, occupied_slots: occupiedSlots, free_slots: freeSlots } = stats;

  const occupiedPercentage = Math.round((occupiedSlots / totalSlots) * 100);
  const freePercentage = 100 - occupiedPercentage;

  // const data = [
  //   {
  //     name: 'Pneumatici Nuovi',
  //     value: (totalCapacity > 0 && tyresStats.total_new_tyres > 0) ? Math.round((tyresStats.total_new_tyres / totalCapacity) * 100) : 0,
  //     color: '#1890ff',
  //   },
  //   {
  //     name: 'Pneumatici in Deposito',
  //     value: (totalCapacity > 0 && tyresStats.total_tyres_to_be_conserved > 0) ? Math.round((tyresStats.total_tyres_to_be_conserved / totalCapacity) * 100) : 0,
  //     color: '#52c41a',
  //   },
  //   {
  //     name: 'Pneumatici da Smaltire',
  //     value: (totalCapacity > 0 && tyresStats.total_tyres_to_be_disposed_of > 0) ? Math.round((tyresStats.total_tyres_to_be_disposed_of / totalCapacity) * 100) : 0,
  //     color: '#ff4d4f',
  //   },
  //   {
  //     name: 'Spazio Disponibile',
  //     value: (totalCapacity > 0 && tyresStats.available_warehouse_capacity > 0) ? Math.round((tyresStats.available_warehouse_capacity / totalCapacity) * 100) : 0,
  //     color: '#BABCC3',
  //   }
  // ];

  const fillPercentage = 75;

  const handleWarehouseChange = (value: string) => {
    setSelectedWarehouseId(value);
    onWarehouseChange(value);
  }

  return (
    <Card 
      title={`Statistiche Magazzino ${warehouseName}`}
      extra={
        <Select placeholder="Seleziona un Magazzino" value={selectedWarehouseId} defaultValue={selectedWarehouseId} onChange={handleWarehouseChange} style={{ width: '100px' }}>
          {warehouses.map((warehouse) => (
            <Select.Option key={warehouse.id} value={warehouse.id}>
              {warehouse.name}
            </Select.Option>
          ))}
        </Select>
      } 
      style={{ flex: 1 }}
      className='stats-card'
    >
      <Row align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '75%' }}>
            <Space direction="vertical" size="large" style={{ textAlign: 'center' }}>
              <Progress 
                type="circle" 
                percent={fillPercentage} 
                format={percent => `${percent}%`}
              />
              <Progress percent={occupiedPercentage} status="exception" format={percent => <strong>{percent}%</strong>} />
              <Progress percent={freePercentage} status="success" format={percent => <strong>{percent}%</strong>} />
            </Space>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Space direction="vertical" size="large" style={{ textAlign: 'center' }}>
            <Statistic 
              title={<><span style={{ paddingRight: '8px' }}>{redDot}</span>Valore Netto Magazzino</>} 
              value={(costsStats && costsStats.total_slot_cost) ? costsStats.total_slot_cost : 0} 
              prefix={'€'} 
            />
            <Statistic 
              title={<><span style={{ paddingRight: '8px' }}>{redDot}</span>Valore Lordo Magazzino</>} 
              value={(costsStats && costsStats.total_sale_slot_cost) ? costsStats.total_sale_slot_cost : 0} 
              prefix={'€'}
            />

            <Button type="primary">Dettagli Magazzino</Button>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};
