import React from 'react';
import { Table, Spin, Space, Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { Supplier } from './types';


interface SuppliersTableProps {
  dataSource: Supplier[];
  loading: boolean;
  rowSelection: TableRowSelection<Supplier>;
  onEdit: (id: string) => void;
  onContact: (id: string) => void;
  onDelete: (id: string) => void;
}

export function SuppliersTable({
  dataSource,
  loading,
  rowSelection,
  onEdit,
  onContact,
  onDelete,
}: SuppliersTableProps): JSX.Element {

  const columns: ColumnProps<Supplier>[] = [
    {
      title: 'Denominazione',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Telefono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Partita Iva',
      dataIndex: 'vatNumber',
      key: 'vatNumber',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record.id)}>Modifica</Button>
          <Button type="link" onClick={() => onContact(record.id)}>Contatta</Button>
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
    }
  ];

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: dataSource.length,
        }}
        rowKey="id"
        rowClassName={(record: Supplier) => (record.id && `row-${record.id}`)}
        rowSelection={rowSelection}
        size='small'
      />
    </Spin>
  );
}
