import React from 'react';
import { Table, Spin, Space, Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { Customer } from './types/type';


interface CustomersTableProps {
  dataSource: Customer[];
  loading: boolean;
  rowSelection: TableRowSelection<Customer>;
  onEdit: (id: string) => void;
  onContact: (id: string) => void;
  onDelete: (id: string) => void;
}

// const pageSize: number = 10;

export function CustomersTable({
  dataSource,
  loading,
  rowSelection,
  onEdit,
  onContact,
  onDelete,
}: CustomersTableProps): JSX.Element {

  const columns: ColumnProps<Customer>[] = [
    {
      title: 'Nome e Cognome',
      dataIndex: 'name',
      render: (_, record) => `${record.name} ${record.surname}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Codice Fiscale',
      dataIndex: 'fiscalCode',
      key: 'fiscalCode',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record.id)}>Modifica</Button>
          <Button type="link" onClick={() => onContact(record.id)}>Contatta</Button>
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
    }
  ];

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: dataSource.length,
        }}
        rowKey="id"
        rowClassName={(record: Customer) => (record.id && `row-${record.id}`)}
        rowSelection={rowSelection}
        size='small'
      />
    </Spin>
  );
}
