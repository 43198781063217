import { fetchAuthSession } from 'aws-amplify/auth';
import { get, post, put, del } from 'aws-amplify/api';
import { Vehicle, VehicleManufacturer } from '../components/vehicles';
import { applicationModuleId } from '../config/config';

export const fetchVehicles = async (searchTerm: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'vehicles',
      path: `/vehicles${searchTerm ? `?search=${encodeURIComponent(searchTerm)}` : ''}`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch Vehicles:', error);
    throw error;
  }
};

export const createVehicle = async (data: Vehicle) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    data['applicationModuleId'] = applicationModuleId;      

    const operation = post({
      apiName: 'vehicles',
      path: '/vehicles',
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to add Vehicle:', error);
    throw error;
  }
};

/* 
 * In react component, param vehicleId is ok. 
 * But in this method this param in possible undefined. 
 */
export const editVehicle = async (id: string, data: Vehicle) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = put({
      apiName: 'vehicles',
      path: `/vehicles/${id}`,
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to edit Vehicle:', error);
    throw error;
  }
};

export const deleteVehicle = async (id: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = del({
      apiName: 'vehicles',
      path: `/vehicles/${id}`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to delete Vehicle:', error);
    throw error;
  }
};

export const searchVehicleDetails = async (searchTerm: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'vehicles',
      path: `/search${searchTerm ? `?search=${encodeURIComponent(searchTerm)}` : ''}`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch Vehicles:', error);
    throw error;
  }
};

export const fetchVehicleManufacturers = async () => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'vehicles',
      path: '/vehicle-manufacturers',
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch Vehicles Manufacturers:', error);
    throw error;
  }
};

export const createVehicleManufacturer = async (data: Partial<VehicleManufacturer>) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    data['applicationModuleId'] = applicationModuleId;

    const operation = post({
      apiName: 'vehicles',
      path: '/vehicle-manufacturers',
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    const { body } = await operation.response;

    const vehicleManufacturers = await body.json();

    return Array.isArray(vehicleManufacturers) && vehicleManufacturers.length > 0 ? vehicleManufacturers : [];

  } catch (error) {
    console.error('Failed to add Vehicle Manufacturer:', error);
    throw error;
  }
};

