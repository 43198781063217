import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Card, Checkbox, Col, DatePicker, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Tyre, TyreBrand } from './types';
import { Vehicle } from '../vehicles';
import { ISelect } from '../../types';


interface TyreFormProps {
  mode: 'add' | 'edit';
  onSubmit: (values: Tyre) => void;
  onAddNewTyreBrand: (name: string) => void;
  vehicleId?: string;
  initialData?: Tyre;
  tyreId?: string;
  tyreBrands: TyreBrand[];
  tyreBrand: TyreBrand | undefined;
  tyreNoiseLevelOptions: ISelect[];
  tyreSeasonOptions: ISelect[];
  tyreStatusOptions: ISelect[];
  vehicles: Vehicle[];
  vehicle?: Vehicle;
}

dayjs.extend(customParseFormat);

export const TyreForm = forwardRef<FormInstance, TyreFormProps>(({
  mode,
  onSubmit,
  onAddNewTyreBrand,
  vehicleId,
  initialData,
  tyreId,
  tyreBrands,
  tyreBrand,
  tyreNoiseLevelOptions,
  tyreSeasonOptions,
  tyreStatusOptions,
  // selectedVehicleId = undefined,
  vehicles,
  vehicle
}, ref) => {
  const [form] = Form.useForm();

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [newBrand, setNewBrand] = useState('');
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);
  const [selectedTyreBrand, setSelectedTyreBrand] = useState<string | undefined>(undefined);

  const defaultTyreStatus = tyreStatusOptions[1].value;

  const onDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      setNewBrand('');
    }
  };

  const handleAddNewOption = () => {
    onAddNewTyreBrand(newBrand);
    setNewBrand('');
    setDropdownVisible(false);
  };

  useImperativeHandle(ref, () => form);

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        tyreBrandId: tyreBrands.find((brand: TyreBrand) => brand.id === initialData.tyreBrandId)?.name || null,
        status: initialData.status ? [initialData.status] : [],
        season: initialData.season ? [initialData.season] : [], 
        noiseLevel: initialData.noiseLevel ? [initialData.noiseLevel] : [],
        vehicleId: (vehicles.find((vehicle) => vehicle.id === initialData.vehicleId))?.id || null,
        circle: initialData.circle,
        pattern: initialData?.pattern || null,
        year: initialData?.year ? dayjs(`${initialData?.year}/01/01`, 'YYYY/MM/DD') : undefined,
        width: initialData.width,
        aspectRatio: initialData.aspectRatio,
        diameter: initialData.diameter,
        speedCode: initialData?.speedCode || null,
      });

      setSelectedYear(initialData?.year);

    } else if (mode === 'add') {

      const initialValues: { status: string, vehicleId?: string } = {
        status: defaultTyreStatus,
      };

      if (vehicle) {
        initialValues['vehicleId'] = vehicle.id;
      }

      form.setFieldsValue(initialValues);
    }
  }, [form]);

  useEffect(() => {
    if (tyreBrand && tyreBrand.id) {
      form.setFieldsValue({ tyreBrandId: tyreBrand.id });
      setSelectedTyreBrand(tyreBrand.id);
    }
  }, [form, tyreBrand]);

  const handleYearChange = (date: any) => {    
    if (date) {
      setSelectedYear(date.year());
    }
  };

  const onFinish = (values: Tyre) => {

    if (values.tyreBrandId ?? false) {
      values.tyreBrandId = (tyreBrands.find((brand) => brand.id === values.tyreBrandId))!.id;
    }
    if (values.year ?? false) {
      values.year = selectedYear;
    }
    
    onSubmit(values);
  };

  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 };
  const rowStyle = { marginTop: '8px', marginBottom: '8px' };

  return (
    <Form
      form={form}
      initialValues={ vehicleId ? initialData : undefined }
      layout="vertical"
      name="add_tyre_form"
      onFinish={onFinish}
    >
      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item name="tyreBrandId" label="Marca Pneumatico">
            <Select
              placeholder="Seleziona o aggiungi un marchio"
              onDropdownVisibleChange={onDropdownVisibleChange}
              dropdownRender={menu => (
                <>
                  {menu}
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <Input value={newBrand} onChange={e => setNewBrand(e.target.value)} style={{ width: 'calc(100% - 60px)', marginRight: 8 }} />
                    <Button type="primary" onClick={handleAddNewOption} disabled={!newBrand.trim()}>
                      Aggiungi
                    </Button>
                  </div>
                </>
              )}
              value={selectedTyreBrand}
            >
              {tyreBrands.map((brand: TyreBrand) => (
                <Select.Option key={brand.id} value={brand.id}>
                  {brand.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="vehicleId" label="Targa Veicolo">
            <Select placeholder="Seleziona una targa">
              {vehicles.map((vehicle: Vehicle) => (
                <Select.Option key={vehicle.id} value={vehicle.id}>
                  {vehicle.plate}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Card title="Stagioni Pneumatico" style={rowStyle}>
            <Form.Item 
              name="season" 
              rules={
                [
                  {
                    validator: (_, value) => {
                      if (value && value.length > 1) {
                        return Promise.reject(new Error('Seleziona esattamente una stagione'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]
              } 
              style={{ margin: 0 }}
            >
              <Checkbox.Group options={tyreSeasonOptions} />
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card title="Indice di rumoriosità pneumatico" style={rowStyle}>
            <Form.Item 
              name="noiseLevel" 
              rules={
                [
                  {
                    validator: (_, value) => {
                      if (value && value.length > 1) {
                        return Promise.reject(new Error('Seleziona esattamente un Indice di rumoriosità pneumatico'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]
              } 
              style={{ margin: 0 }}
            >
              <Checkbox.Group options={tyreNoiseLevelOptions} />
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Card style={rowStyle}>
            <Form.Item 
              name="circle"
              style={{ margin: 0 }}
            >
              <Radio.Group>
                <Radio value={true}>Cerchio Sì</Radio>
                <Radio value={false}>Cerchio No</Radio>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card style={rowStyle}>
            <Form.Item 
              name="status" 
              rules={
                [
                  {
                    validator: (_, value) => {
                      if (value && value.length > 1) {
                        return Promise.reject(new Error('Seleziona esattamente uno stato'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]
              } 
              style={{ margin: 0 }}
            >
              <Checkbox.Group options={tyreStatusOptions} />
            </Form.Item>
          </Card> 
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item name="year" label="Anno">
            <DatePicker picker="year" format={'YYYY'} onChange={handleYearChange} placeholder={'Seleziona l\'anno del pneumatico'} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="width" label="Larghezza">
            <InputNumber placeholder="es. 255" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name="aspectRatio" label="Altezza">
            <InputNumber type="number" placeholder="es. 55" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name="diameter" label="Diametro">
            <InputNumber type="number" placeholder="es. R17" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="speedCode"
            label={
              <>
                Indice di Carico e Indice di Velocità
                <Tooltip title="L'indice di carico indica il peso massimo che il pneumatico può sostenere. L'indice di velocità rappresenta la massima velocità a cui il pneumatico può viaggiare in sicurezza.">
                <InfoCircleOutlined style={{ marginLeft: 8 }} />
                </Tooltip>
              </>
            }
            // rules={[{ required: true, message: 'Gli indici di Carico e di Velocità del pneumatico sono obbligatori' }]}
          >
            <Input placeholder="es. 88H (dove 88 è l'indice di carico e H è l'indice di velocità)" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
