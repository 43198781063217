import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import type { FormInstance, TabsProps } from 'antd';

import { fetchServices, fetchWarehouses } from '../api';
import { WarehousesStats } from '../components/dashboard';
import { WarehouseWithStats } from '../components/warehouses';
import { Service } from '../components/preventives';


export function Dashboard(): JSX.Element {

  const [warehouses, setWarehouses] = useState<WarehouseWithStats[]>([]);
  const [services, setServices] = useState<Service[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const formRef = useRef<FormInstance>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const event = {
    title : "test larger",
    start : '2024-04-25T13:00:00.000Z',
    end : '2024-04-25T21:00:00.000Z',
    allDay : false
  };

  useEffect(() => {
    _fetchWarehouses();
    _fetchServices();
  }, [location.pathname,]);

  const _fetchWarehouses = async () => {
    // setLoading(true);

    try {
      const response = await fetchWarehouses();

      setWarehouses(response as unknown as WarehouseWithStats[]);
    } catch (error) {
      console.error('Failed to fetch warehouses:', error);
    }

    // setLoading(false);
  };

  const _fetchServices = async () => {
    setLoading(true);

    try {
      const response = await fetchServices();

      setServices(response as unknown as Service[]);
    } catch (error) {
      console.error('Failed to fetch services:', error);
    }

    setLoading(false);
  };

  return (
    <div>
      <h1>Dashboard</h1>

      <WarehousesStats 
        warehouses={warehouses}
        services={services}
        loading={loading}
      />
    </div>
  );
}
