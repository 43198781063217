import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from '@react-hook/debounce';
import { AutoComplete, Button, Card, Col, Form, InputNumber, Row, Space, notification } from 'antd';
import { FormInstance } from 'antd/lib';
import { TableRowSelection } from 'antd/es/table/interface';
import { createRackSlot, deleteRackSlot, fetchCustomers, fetchRackSlots, fetchTyreBrands, fetchTyres, fetchVehicles, searchTyresByPlate } from '../api';
import { Customer } from '../components/customers';
import { Vehicle } from '../components/vehicles';
import { Tyre, TyreBrand } from '../components/tyres';
import { TyresTable, RackSlotsTable } from '../components/warehouse-slots';
import { RackSlot, Slot } from '../components/warehouse-slots/types';
import { TyreNoiseLevelsSelectValues, TyreSeasonsSelectValues, TyreStatusSelectValues } from '../components/tyres/constants';
import { CirclePlusIcon } from '../components/IconWrapper';
import { applicationModuleId } from '../config/config';


export function WarehouseRackDetails() {

  const { warehouseId, rackId } = useParams<{ warehouseId: string, rackId: string }>();

  const [cardActions, setCardActions] = useState<JSX.Element[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [plateSuggestions, setPlateSuggestions] = useState<string[]>([]);
  const [rackSlots, setRackSlots] = useState<RackSlot[]>([]);
  const [tyreBrands, setTyreBrands] = useState<TyreBrand[]>([]);
  const [tyres, setTyres] = useState<Tyre[]>([]);
  const [allTyres, setAllTyres] = useState<Tyre[]>([]);

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  const formRef = useRef<FormInstance>(null);

  const location = useLocation();
  const isEditPage = location.pathname.includes('/edit');
  const isNewPage = location.pathname.endsWith('/new');

  const defaultSlotCost = +location.state?.defaultSlotCost || 0;
  const defaultSaleSlotCost = +location.state?.defaultSaleSlotCost || 0;

  const totalSlots = location.state?.totalSlots;
  const occupiedSlots = location.state?.occupiedSlots;

  const [totalOccupiedSlots, setTotalOccupiedSlots] = useState<number>(occupiedSlots);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  let title;

  switch (true) {
    case isNewPage:
      title = 'Crea Slot';
      break;
    case isEditPage:
      title = 'Modifica Slot';
      break;
    case (!isNewPage && !isEditPage):
      title = 'Scheda Rack';
      break;
    default:
      title = undefined;
  }

  const tyreSeasons = TyreSeasonsSelectValues;
  const tyreStatus = TyreStatusSelectValues;
  const tyreNoiseLevels = TyreNoiseLevelsSelectValues;

  useEffect(() => {
    let actions: JSX.Element[] = [];

    if (isNewPage) {
      actions = [
        <div className='action-icon' onClick={() => handleSubmit()} >
          <CirclePlusIcon size='xl' />
        </div>
      ];
    }

    setCardActions(actions);

  }, [location.pathname, isNewPage]);

  const _fetchRackSlots = async (warehouseId: string, rackId: string) => {
    const rackSlotsResponse = await fetchRackSlots(warehouseId, rackId);

    setRackSlots(rackSlotsResponse as unknown as RackSlot[]);
  };

  useEffect(() => {
    const loadRackSlots = async () => {
      if (typeof warehouseId === 'string' && typeof rackId === 'string') {
        try {
          setLoading(true);

          // const slots = await fetchRackSlots(warehouseId, rackId);

          const [tyresResponse, allTyresResponse, tyreBrandsResponse, rackSlotsResponse, customersResponse, vehiclesResponse] = await Promise.all([
            searchTyresByPlate(''),
            fetchTyres(''),
            fetchTyreBrands(),
            fetchRackSlots(warehouseId, rackId),
            fetchCustomers(''),
            fetchVehicles('')
          ]);
          
          setCustomers(customersResponse as unknown as Customer[]);
          setTyres(tyresResponse as unknown as Tyre[]);
          setAllTyres(allTyresResponse as unknown as Tyre[]);
          setRackSlots(rackSlotsResponse as unknown as RackSlot[]);
          setTyreBrands(tyreBrandsResponse as unknown as TyreBrand[]);
          setVehicles(vehiclesResponse as unknown as Vehicle[]);
          setPlateSuggestions((vehiclesResponse as unknown as Vehicle[]).map(item => item.plate));
          
          formRef.current?.setFieldsValue({
            slotCost: defaultSlotCost || 0,
            saleSlotCost: defaultSaleSlotCost || 0,
          });

        } catch (error) {
          console.error('Failed to fetch rack slots:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadRackSlots();
  }, [warehouseId, rackId]);

  useEffect(() => {
    const loadTyres = async () => {
      if (debouncedSearchTerm) {
        try {
          setLoading(true);

          const response = await searchTyresByPlate(debouncedSearchTerm);

          setTyres(response as unknown as Tyre[]);
        } catch (error) {
          setTyres([]);
        } finally {
          setLoading(false);
        }
      } else {
        setTyres([]);
      }
    };

    loadTyres();

  }, [debouncedSearchTerm, searchTerm]); 

  if (typeof warehouseId !== 'string' || typeof rackId !== 'string') {
    return <Navigate to="/error" replace />;
  }

  // const handleSearch = async (value: string) => {

  //   // if (!value) {
  //   //   setTyres([]);

  //   //   return;
  //   // }

  //   setLoading(true);

  //   // setSearchTerm(value);

  //   // const response = await searchTyresByPlate(value);

  //   // setTyres(response as unknown as Tyre[]);

  //   setLoading(false);
  // };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleInputChange = (value: string) => {
    setSearchTerm(value);
    if (!value) {
      setTyres([]);
    }
    setSelectedRowKeys([]);
  };  

  const rowSelection: TableRowSelection<Tyre> = {
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    selectedRowKeys,
  };

  const handleSubmit = () => {
    formRef.current?.submit();
  };

  const handleAddSlotFormSubmit = async (formData: Partial<Slot>) => {
    try {
      const { slotCost, saleSlotCost } = formData;

      await createRackSlot(warehouseId, rackId, {
        slotCost,
        saleSlotCost,
        tyreId1: selectedRowKeys[0].toString(),
        tyreId2: selectedRowKeys[1].toString(),
        applicationModuleId: applicationModuleId
      });

      notification.success({
        message: 'Slot Aggiunto',
        description: 'Lo Slot è stato creato con successo.',
      });

      setTotalOccupiedSlots(totalOccupiedSlots + 1);

      if (totalOccupiedSlots + 1 === totalSlots) {
        goBack();

        notification.info({
          message: 'Capienza massima raggiunta',
          description: 'Lo Slot ha raggiunto la sua capienza massima',
        });  
      }

    } catch (error) {
      console.error('Failed to create slot:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante la creazione dello slot.',
      });
    }
  };

  const handleRemoveSlot = async (rackSlotId: string) => {
    setLoading(true);

    try {
      await deleteRackSlot(warehouseId, rackId, rackSlotId);

      notification.success({
        message: 'Slot Eliminato',
        description: 'Lo Slot è stato eliminato con successo.',
      });

      await _fetchRackSlots(warehouseId, rackId);

    } catch (error) {
      console.error('Failed to remove slot:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante l\'eliminazione dello slot.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = async (value: string) => {
    setLoading(true);

    try {
      const response = await searchTyresByPlate(value);

      setTyres(response as unknown as Tyre[]);
    } catch (error) {
      console.error('Error fetching tyres:', error);

      setTyres([]);
    } finally {
      setLoading(false);
    }
  };

  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 };
  const rowStyle = { marginTop: 32 };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title={title}
          extra={<Button type="link" onClick={goBack}>Torna alla lista</Button>}
          actions={cardActions}
        >
          {isNewPage && <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Form name="warehouse_slot_form" ref={formRef} onFinish={handleAddSlotFormSubmit} layout="vertical">
              <AutoComplete
                filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                onChange={handleInputChange}
                onSearch={handleSearch}
                onSelect={handleSelect}
                options={plateSuggestions.map(plate => ({ value: plate }))}
                placeholder="Inserisci targa"
                style={{ width: 220, marginBottom: 8  }}
              />
              <Form.Item
                name="selectedTyres"
                rules={[
                  {
                    validator: (_, value) => {
                      if (selectedRowKeys.length !== 2) {
                        return Promise.reject(new Error('Ogni slot deve avere associato due pneumatici'));
                      }

                      const [tyreOneId, tyreTwoId] = selectedRowKeys;

                      const tyreOne = tyres.find((tyre) => tyre!.id === tyreOneId);
                      const tyreTwo = tyres.find((tyre) => tyre!.id === tyreTwoId);

                      if (tyreOne && tyreTwo) {
                        const { id: id1, ...cleanTyre1 } = tyreOne;
                        const { id: id2, ...cleanTyre2 } = tyreTwo;

                        if (JSON.stringify(cleanTyre1) !== JSON.stringify(cleanTyre2)) {
                          return Promise.reject(new Error('Ogni slot deve avere associato due pneumatici uguali'));
                        }
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >

                <TyresTable 
                  dataSource={tyres}
                  customers={customers}
                  tyreBrands={tyreBrands}
                  tyreSeasons={tyreSeasons}
                  vehicles={vehicles}
                  loading={loading}
                  rowSelection={rowSelection}
                />
              </Form.Item>

              <Row gutter={rowGutter} style={rowStyle}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="slotCost"
                    label="Costo Slot"
                    rules={[{ required: true, message: "Inserisci il costo dello slot" }]}
                  >
                    <InputNumber 
                      placeholder="Costo Slot" 
                      min={0} 
                      precision={2} 
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="saleSlotCost"
                    label="Costo di Vendita Slot"
                    rules={[{ required: true, message: "Inserisci il costo di vendita dello slot" }]}
                  >
                    <InputNumber 
                      placeholder="Costo di Vendita Slot" 
                      min={0} 
                      precision={2} 
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Space>}
          
          {!isNewPage && !isEditPage && <RackSlotsTable dataSource={rackSlots} customers={customers} tyres={allTyres} vehicles={vehicles} loading={loading} onDelete={handleRemoveSlot}></RackSlotsTable>}
        </Card>
      </Col>
    </Row>
  );
};
