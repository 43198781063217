import React, { forwardRef, useEffect, useState } from 'react';
import { Form, FormInstance, Input, InputNumber, Button, Card, Row, Col } from 'antd';
import { NewService } from './types';


interface ServiceFormProps {
  onSubmit: (values: NewService) => void;
}

export const ServiceForm = forwardRef<FormInstance, ServiceFormProps>(({ onSubmit }, ref) => {

  const [form] = Form.useForm();
  const [isFormTouched, setIsFormTouched] = useState(false);

  const defaultVAT = 22;

  useEffect(() => {
    form.setFieldsValue({ vat: defaultVAT });
    setIsFormTouched(true);
  }, [form]);

  const handleFormSubmit = (values: NewService) => {
    onSubmit(values);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Card title="Aggiungi Servizio" bordered={false}>
      <Form form={form} layout="inline" onFinish={handleFormSubmit}>
        <Row gutter={[24, 16]}>
          <Col>
            <Form.Item
              name="name"
              label="Nome Servizio"
              rules={[{ required: true, message: 'Inserisci il nome del servizio!' }]}
            >
              <Input placeholder="Es: Cambio olio" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="price"
              label="Prezzo Lordo + Rincaro"
              rules={[{ required: true, message: 'Inserisci il prezzo del servizio!' }]}
            >
              <InputNumber min={0} placeholder="Es: 100" formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="vat"
              label="IVA"
              rules={[{ required: true, message: 'Inserisci l\'IVA applicabile!' }]}
              initialValue={defaultVAT}
            >
              <InputNumber<number> min={0} max={100} formatter={value => `${value}%`} parser={value => value ? parseInt(value.replace('%', ''), 10) : 0} />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Form.Item shouldUpdate={true}>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !isFormTouched ||
                    form.getFieldsError().some(({ errors }) => errors.length) ||
                    Object.values(form.getFieldsValue()).some(value => value === undefined || value === '')
                  }
                >
                  Aggiungi
                </Button>
              )}
            </Form.Item>
          </Col>
          <Col flex="none">
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
});
