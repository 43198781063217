import React from 'react';
import { Input, Col, Row, Button, Space } from 'antd';
import { PlusIcon } from '../IconWrapper';

interface WarehousesSearchProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onSearch: () => void;
  onAdd: () => void;
}

export const WarehousesSearch = ({
  searchTerm,
  setSearchTerm,
  onSearch,
  onAdd
}: WarehousesSearchProps): JSX.Element => {
  return (
    <Row justify="end" style={{ marginTop: '24px', marginBottom: '36px' }}>
      <Col>
        <Space size="middle">
          <Input.Search
            placeholder="Ricerca per nome, indirizzo, ..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onSearch={onSearch}
            enterButton
            style={{ minWidth: '300px' }}
          />
          <Button onClick={() => setSearchTerm('')}>Reset</Button>
          <Button type="primary" icon={<PlusIcon />} onClick={onAdd}>
            Aggiungi Magazzino
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default WarehousesSearch;
