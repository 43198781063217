import React from 'react';
import { Input, Col, Row, Button, Space } from 'antd';
import { PlusIcon } from '../IconWrapper';


interface VehiclesSearchProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onSearch: () => void;
  onAdd: () => void;
  onAddTyre: () => void;
}

export const VehiclesSearch = ({
  searchTerm,
  setSearchTerm,
  onSearch,
  onAdd,
  onAddTyre,
}: VehiclesSearchProps): JSX.Element => {
  return (
    <Row justify="end" style={{ marginTop: '24px', marginBottom: '36px' }}>
      <Col>
        <Space size="middle">
          <Input.Search
            placeholder="Targa, esempio: ET390WD"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onSearch={onSearch}
            enterButton
            style={{ minWidth: '200px' }}
          />
          <Button onClick={() => setSearchTerm('')}>Reset</Button>
          <Button type="primary" icon={<PlusIcon />} onClick={onAddTyre}>
            Aggiungi Pneumatico
          </Button>
          <Button type="primary" icon={<PlusIcon />} onClick={onAdd}>
            Aggiungi Veicolo
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default VehiclesSearch;
