import { fetchAuthSession } from 'aws-amplify/auth';
import { get, post, put } from 'aws-amplify/api';
import { EditUser, NewUser } from '../components/settings/types';
import { applicationModuleId } from '../config/config';


export const fetchUser = async () => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'settings',
      path: '/settings/user',
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch customers:', error);
    throw error;
  }
};

export const createUser = async (data: NewUser) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    data['applicationModuleId'] = applicationModuleId;

    const operation = post({
      apiName: 'settings',
      path: '/settings/user',
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to add Tyre:', error);
    throw error;
  }
};

export const editUser = async (data: EditUser) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    data['applicationModuleId'] = applicationModuleId;      

    const operation = put({
      apiName: 'settings',
      path: `/settings/user`,
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to edit User:', error);
    throw error;
  }
};
