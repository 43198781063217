import React from 'react';
import { Card, Descriptions, Typography } from 'antd';
import { Customer } from '../customers';
import { Vehicle, VehicleManufacturer } from '../vehicles/types';
import { formatName } from '../../utils/formatName';
import { getVehicleTypeLabel } from '../vehicles';


const { Text } = Typography;

interface VehicleDetailProps {
  vehicle: Vehicle | undefined;
  vehicleManufacturer: VehicleManufacturer | undefined;
  customer: Customer | undefined;
}

const VehicleDetail: React.FC<VehicleDetailProps> = ({
  vehicle,
  vehicleManufacturer,
  customer,
}) => {
  return (
    <Card bordered={false}>
      <Descriptions column={3}>
        <Descriptions.Item label={<Text strong>Tipo Veicolo</Text>}>
          {vehicle ? getVehicleTypeLabel(vehicle.type) : 'N/D'}
        </Descriptions.Item>

        <Descriptions.Item label={<Text strong>Veicolo</Text>}>
          {vehicleManufacturer?.name || 'N/D'}
        </Descriptions.Item>

        <Descriptions.Item label={<Text strong>Targa</Text>}>
          {vehicle?.plate || 'N/D'}
        </Descriptions.Item>

        <Descriptions.Item label={<Text strong>Cliente</Text>}>
          {customer ? `${formatName(customer?.name, customer?.surname)}` : 'N/D'}
        </Descriptions.Item>

        <Descriptions.Item label={<Text strong>Contatti</Text>}>
          {customer?.phoneNumber || 'Non disponibile'}
        </Descriptions.Item>

        <Descriptions.Item label={<Text strong>Email</Text>}>
          {customer?.email || 'Non disponibile'}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default VehicleDetail;
