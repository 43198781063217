import { fetchAuthSession } from 'aws-amplify/auth';
import { get, post } from 'aws-amplify/api';
import { NewService } from '../components/preventives';
import { applicationModuleId } from '../config/config';


export const fetchServices = async () => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'services',
      path: '/services',
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch services:', error);
    throw error;
  }
};

export const createService = async (data: NewService) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    data['applicationModuleId'] = applicationModuleId;

    const operation = post({
      apiName: 'services',
      path: '/services',
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to add Service:', error);
    throw error;
  }
};
