import React, { forwardRef, useImperativeHandle } from 'react';
import { Col, Form, FormInstance, Input, Row } from 'antd';
import { Supplier } from './types';


interface SupplierFormProps {
  mode: 'add' | 'edit';
  onSubmit: (values: Supplier) => void;
  supplierId?: string;
  initialData?: Supplier;
}

export const SupplierForm = forwardRef<FormInstance, SupplierFormProps>(({
  mode,
  onSubmit,
  supplierId,
  initialData,
}, ref) => {
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => form);

  const onFinish = (values: Supplier) => {
    onSubmit(values);
  };

  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 };
  const rowStyle = { marginBottom: '16px' };

  return (
    <Form
      form={form}
      initialValues={ supplierId ? initialData : undefined }
      layout="vertical"
      name="add_supplier_form"
      onFinish={onFinish}
    >
      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item name="name" label="Denominazione">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="socialReason" label="Ragione Sociale">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
        <Form.Item name="email" label="Email" rules={[{ type: 'email', required: true }]}>
          <Input />
        </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="phoneNumber" label="Telefono">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item name="fiscalCode" label="Codice Fiscale">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="vatNumber" label="Partita IVA" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item name="uniqueCode" label="Codice Univoco">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="pec" label="PEC" rules={[{ type: 'email' }]}>
            <Input placeholder="PEC" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item name="address" label="Indirizzo">
            <Input placeholder="Indirizzo" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="city" label="Città">
            <Input placeholder="Città" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item name="postalCode" label="CAP">
            <Input placeholder="CAP" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="region" label="Regione">
            <Input placeholder="Regione" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} style={rowStyle}>
        <Col xs={24} sm={12}>
          <Form.Item name="nation" label="Nazione">
            <Input placeholder="Nazione" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
