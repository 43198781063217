import { fetchAuthSession } from 'aws-amplify/auth';
import { get, post, del } from 'aws-amplify/api';
import { Slot } from '../components/warehouse-slots/types';
// import { Vehicle } from '../components/vehicles';


export const fetchWarehouses = async (searchTerm: string = '') => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'warehouses',
      path: `/warehouses${(searchTerm !== '') ? `?search=${encodeURIComponent(searchTerm)}` : ''}`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;
    
    return await body.json();

  } catch (error) {
    console.error('Failed to fetch warehouses:', error);
    throw error;
  }
};

export const getWarehouseDetails = async (warehouseId: string, searchTerm: string = '') => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'warehouses',
      path: `/warehouses/${warehouseId}${(searchTerm !== '') ? `?search=${encodeURIComponent(searchTerm)}` : ''}`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch warehouse details:', error);
    throw error;
  }
};

export const deleteRack = async (warehouseId: string, rackId: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = del({
      apiName: 'warehouses',
      path: `/warehouses/${warehouseId}/rack/${rackId}`,
      options: {
        headers: {
          Authorization: accessToken,
        },
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to delete warehouse rack:', error);
    throw error;
  }
};

export const fetchRackSlots = async (warehouseId: string, rackId: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'warehouses',
      path: `/warehouses/${warehouseId}/rack/${rackId}/slots`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch warehouse rack slots:', error);
    throw error;
  }
};

export const createRackSlot = async (warehouseId: string, rackId: string, data: Partial<Slot>) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = post({
      apiName: 'warehouses',
      path: `/warehouses/${warehouseId}/rack/${rackId}/slot`,
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to create warehouse rack slot:', error);
    throw error;
  }
};

export const deleteRackSlot = async (warehouseId: string, rackId: string, slotId: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = del({
      apiName: 'warehouses',
      path: `/warehouses/${warehouseId}/rack/${rackId}/slot/${slotId}`,
      options: {
        headers: {
          Authorization: accessToken,
        },
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to delete warehouse rack slot:', error);
    throw error;
  }
};

export const fetchWarehouseTyresStats = async (warehouseId: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'warehouses',
      path: `/warehouses/${warehouseId}/tyres-stats`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch warehouse tyres stats:', error);
    throw error;
  }
};

export const fetchWarehouseCostsStats = async (warehouseId: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'warehouses',
      path: `/warehouses/${warehouseId}/costs-stats`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch warehouse costs stats:', error);
    throw error;
  }
};
