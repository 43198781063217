import { ISelect } from "../../../types";
import { TyreNoiseLevel } from "../enums/TyreNoiseLevel";
import { TyreSeason } from "../enums/TyreSeasons";
import { TyreStatus } from "../enums/TyreStatus";


export const TyreSeasonsSelectValues: ISelect[] = [
  {
    value: TyreSeason.Summer,
    label: "Estivi"
  },
  {
    value: TyreSeason.Winter,
    label: "Invernali"
  },
  {
    value: TyreSeason.AllSeason,
    label: "4 Stagioni"
  }
];

export const TyreNoiseLevelsSelectValues: ISelect[] = [
  {
    value: TyreNoiseLevel.GradeA,
    label: "Grado A"
  },
  {
    value: TyreNoiseLevel.GradeB,
    label: "Grado B"
  },
  {
    value: TyreNoiseLevel.GradeC,
    label: "Grado C"
  }
];

export const TyreStatusSelectValues: ISelect[] = [
  {
    value: TyreStatus.New,
    label: "Nuovo"
  },
  {
    value: TyreStatus.ToBeConserved,
    label: "Da Conservare"
  },
  {
    value: TyreStatus.ToBeDisposedOf,
    label: "Da Smaltire"
  }
];