import { ISelect } from "../../../types";
import { VehicleType } from "./VehicleType";

/**
 * Vehicles Types select values.
 */
// export interface VehicleTypeSelectValues {
//   id: string;
//   applicationModuleId: string;
//   customer: Customer;
//   place: string;
// }

const VehicleTypesSelectValues: ISelect[] = [
  {
    value: VehicleType.Car,
    label: "Automobile"
  },
  {
    value: VehicleType.Motorcycle,
    label: "Motociclo"
  },
  {
    value: VehicleType.Truck,
    label: "Autocarro"
  }
];

export default VehicleTypesSelectValues;