import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Select, Form, Modal, Input, DatePicker, TimePicker, Card } from 'antd';
import { SketchPicker } from 'react-color';
import { Station } from './types';


const { Option } = Select;
const { RangePicker } = TimePicker;

interface AppointmentFormProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onSelectVehicle: (vehicleId: string) => void;
  onResetSelectedVehicle: () => void;
  plates: string[];
  stations: Station[];
  vehicleIds: string[];
  onSubmit: (event: { title: string, date: Date, start: Date, end: Date, stationId?: string }) => void;
  onAddNewStation: (event: { name: string, colorHex: string }) => void;
  // onSubmit: (values: NewService) => void;
}

export const AppointmentForm: React.FC<AppointmentFormProps> = ({
  searchTerm,
  setSearchTerm,
  onSelectVehicle,
  onResetSelectedVehicle,
  plates,
  stations,
  vehicleIds,
  onSubmit,
  onAddNewStation
}) => {
  const [form] = Form.useForm();
  
  // const [events, setEvents] = useState([]);
  // const [stations, setStations] = useState<any[]>([]);

  const [selectedColor, setSelectedColor] = useState('#FFFFFF');
  const [selectedStation, setSelectedStation] = useState<string | undefined>();

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ vat: 'prroof' });
    // 
  }, [form]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (values: any) => {    
    const newStation = {
      name: values.stationName,
      color: selectedColor
    };
    
    // setStations([...stations, newStation]);
    onAddNewStation({ name: values.stationName, colorHex: selectedColor });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleColorChange = (color: { hex: string }) => {
    console.log("🚀 ~ handleColorChange:", color);
    setSelectedColor(color.hex);
  };

  // const handleDateSelect = (selectInfo: any) => {
  //   const title = prompt('Enter a new title for your event:');
  //   const stationId = prompt('Enter the station ID:');
  //   const station = stations.find(s => s.id === stationId);

  //   if (title) {
  //       let calendarApi = selectInfo.view.calendar;
  //       calendarApi.unselect(); // clear date selection
  //       calendarApi.addEvent({
  //           title,
  //           start: selectInfo.startStr,
  //           end: selectInfo.endStr,
  //           allDay: selectInfo.allDay,
  //           color: station ? station.color : '#3788D8' // Default color if no station found
  //       });
  //   }
  // };

  const handleFormSubmit = (values: any) => {
    const { date, timeRange } = values;

    const [startTime, endTime] = timeRange;

    const station = stations.find(s => s.id === selectedStation);

    onSubmit({
      title: searchTerm,
      date: new Date(date.format('YYYY-MM-DD')),
      start: new Date(date.format('YYYY-MM-DD') + 'T' + startTime.format('HH:mm')),
      end: new Date(date.format('YYYY-MM-DD') + 'T' + endTime.format('HH:mm')),
      stationId: station?.id,
      // stationId: (station ?? false) ? station?.id : undefined,
      // color: station ? station.colorHex : '#3788D8' // Default color if no station found
    });
  };

  // const onReset = () => {
  //   form.resetFields();
  // };

  return (
    <Card title="Crea Appuntamento" bordered={false}>
      <Form form={form} layout="inline" onFinish={handleFormSubmit}>
        <Row gutter={[24, 16]}>

          <Col>
            <Form.Item label="Targa" name="plateName">
              <Select
                showSearch
                placeholder="Targa, esempio: ET390WD"
                value={searchTerm}
                onChange={(value: string) => {
                  setSearchTerm(value);
                  
                  const index = plates.indexOf(value);
                  onSelectVehicle(vehicleIds[index]);
                }}
                style={{ width: 200 }}
              >
                {plates.map((plate, index) => (
                  <Option key={plate} value={plate}>
                    {plate}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label="Postazione" name="station">
              <Select
                placeholder="Seleziona postazione"
                onChange={setSelectedStation}
                style={{ width: 200 }}
              >
                {stations.map(station => (
                  <Option key={station.id} value={station.id}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ height: '10px', width: '10px', borderRadius: '50%', backgroundColor: station.colorHex, marginRight: '10px' }}></span>
                      {station.name}
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col>
            {/* Create New Station */}
            <Form.Item>
              <Button onClick={showModal}>Crea Postazione</Button>
            </Form.Item>
          </Col>

          <Modal 
            title="Crea Nuova Postazione"
            visible={isModalVisible}
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" form="stationForm" htmlType="submit">
                OK
              </Button>
            ]}
          >
            <Form layout="vertical" onFinish={handleOk} id="stationForm">
              <Form.Item label="Nome Postazione" name="stationName">
                <Input />
              </Form.Item>
              <Form.Item label="Seleziona Colore">
                <SketchPicker color={selectedColor} onChangeComplete={handleColorChange} />
              </Form.Item>
            </Form>
          </Modal>

          <Col>
            <Form.Item name="date" label="Data" rules={[{ required: true }]}>
              <DatePicker />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item name="timeRange" label="Range" rules={[{ required: true }]}>
              <RangePicker format="HH:mm" />
            </Form.Item>
          </Col>

          <Col flex="none">
            <Form.Item>
              <Button onClick={() => {
                setSearchTerm('');
                onResetSelectedVehicle();
              }}>Reset</Button>
            </Form.Item>
          </Col>

          <Col flex="none">
            <Form.Item>
              <Button type="primary" htmlType="submit">Crea</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AppointmentForm;
