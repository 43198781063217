import { atom } from 'recoil';

interface User {
  id: string;
  name: string;
  surname: string;
  email: string;
}

export const userState = atom<User | null>({
  key: 'userLogged',
  default: null,
});
