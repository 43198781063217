import React from 'react';
import { Card, Row, Col, List, Badge, Typography } from 'antd';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { WarehouseTyresStats } from '../types';

import './WarehousesStats.css';


const COLORS = ['#1890ff', '#52c41a', '#ff4d4f', '#BABCC3'];

const { Text } = Typography;

interface WarehouseDistributionCardProps {
  warehouseName: string;
  tyresStats: WarehouseTyresStats;
}

export const WarehouseDistributionCard = ({
  warehouseName,
  tyresStats
}: WarehouseDistributionCardProps): JSX.Element => {

  const totalCapacity = tyresStats.total_warehouse_capacity;

  const data = [
    {
      name: 'Pneumatici Nuovi',
      value: (totalCapacity > 0 && tyresStats.total_new_tyres > 0) ? Math.round((tyresStats.total_new_tyres / totalCapacity) * 100) : 0,
      color: '#1890ff',
    },
    {
      name: 'Pneumatici in Deposito',
      value: (totalCapacity > 0 && tyresStats.total_tyres_to_be_conserved > 0) ? Math.round((tyresStats.total_tyres_to_be_conserved / totalCapacity) * 100) : 0,
      color: '#52c41a',
    },
    {
      name: 'Pneumatici da Smaltire',
      value: (totalCapacity > 0 && tyresStats.total_tyres_to_be_disposed_of > 0) ? Math.round((tyresStats.total_tyres_to_be_disposed_of / totalCapacity) * 100) : 0,
      color: '#ff4d4f',
    },
    {
      name: 'Spazio Disponibile',
      value: (totalCapacity > 0 && tyresStats.available_warehouse_capacity > 0) ? Math.round((tyresStats.available_warehouse_capacity / totalCapacity) * 100) : 0,
      color: '#BABCC3',
    }
  ];

  return (
    <Card title="Disposizione del Magazzino" className='stats-card' style={{ flex: 1 }}>
      <Row align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ alignItems: 'center', justifyContent: 'center' }}>
          <List
            dataSource={data.filter((item) => item.name !== '')}
            renderItem={(item, index) => (
              <List.Item style={{ padding: '5px 0' }}>
                <List.Item.Meta
                  avatar={<Badge color={COLORS[index % COLORS.length]} style={{ boxShadow: 'none' }} />}
                  title={
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Text type="secondary">{item.name}</Text>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text style={{ marginRight: 4 }}>Percentuale</Text>
                        <Text strong style={{ color: COLORS[index % COLORS.length] }}>{`${item.value}%`}</Text>
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ height: 200 }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={55}
                outerRadius={100}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
                startAngle={90}
                endAngle={450}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Card>
  );
};
