import { fetchAuthSession } from 'aws-amplify/auth';
import { del, get, post, put } from 'aws-amplify/api';
import { Tyre, NewTyreBrand } from '../components/tyres';
import { applicationModuleId } from '../config/config';


export const fetchTyres = async (searchTerm: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'tyres',
      path: `/tyres${searchTerm ? `?search=${encodeURIComponent(searchTerm)}` : ''}`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch Tyres:', error);
    throw error;
  }
};

export const createTyre = async (data: Tyre) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    data['applicationModuleId'] = applicationModuleId;

    const operation = post({
      apiName: 'tyres',
      path: '/tyres',
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to add Tyre:', error);
    throw error;
  }
};

export const editTyre = async (id: string, data: Tyre) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    data['applicationModuleId'] = applicationModuleId;      

    const operation = put({
      apiName: 'tyres',
      path: `/tyres/${id}`,
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to add Tyre:', error);
    throw error;
  }
};

export const deleteTyre = async (id: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = del({
      apiName: 'tyres',
      path: `/tyres/${id}`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    await operation.response;

    return true;

  } catch (error) {
    console.error('Failed to delete Tyre:', error);
    throw error;
  }
};

export const fetchTyreBrands = async () => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'tyres',
      path: '/tyre-brands',
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch tyre brands:', error);
    throw error;
  }
};

export const createTyreBrand = async (data: Partial<NewTyreBrand>) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    data['applicationModuleId'] = applicationModuleId;

    const operation = post({
      apiName: 'tyres',
      path: '/tyre-brands',
      options: {
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json'
        },
        body: data as any
      }
    });

    const { body } = await operation.response;

    const tyreBrands = await body.json();

    return Array.isArray(tyreBrands) && tyreBrands.length > 0 ? tyreBrands : [];

  } catch (error) {
    console.error('Failed to add Tyre Brand:', error);
    throw error;
  }
};

/* 
 * TODO: Implement /tyres for search by plate
 */
export const searchTyresByPlate = async (searchTerm: string) => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken.toString() ?? '';

    const operation = get({
      apiName: 'tyres',
      path: `/tyres/plate${searchTerm ? `?search=${encodeURIComponent(searchTerm)}` : ''}`,
      options: {
        headers: {
          Authorization: accessToken
        }
      }
    });

    const { body } = await operation.response;

    return await body.json();

  } catch (error) {
    console.error('Failed to fetch Tyres:', error);
    throw error;
  }
};
