import React from 'react';
import { Card, Typography } from 'antd';
import { CalendarOutlined, PhoneOutlined } from '@ant-design/icons';

import './WarehousesStats.css';


const { Title, Text } = Typography;

export const UpcomingUnloadCard = () => {

  const cardActions = [
    <div className='action-icon color-primary' onClick={() => console.log('Calendar Clicked')} >
      <CalendarOutlined />
    </div>,
    <div className='action-icon color-success' onClick={() => console.log('Phone Clicked')}>
      <PhoneOutlined />
    </div>
  ];

  return (
    <Card actions={cardActions} className='stats-card' style={{ flex: 1 }}>
      <div style={{ textAlign: 'center' }}>
        <Title level={4} style={{ marginBottom: '8px' }}>Prossimo Scarico</Title>
        <Text strong style={{ display: 'block', marginBottom: '4px' }}>GOMME Launch SRL</Text>
        <Text style={{ display: 'block', marginBottom: '8px' }}>24/01/2024 h17:30</Text>
      </div>
    </Card>
  );
};
