import React from 'react';
import { Alert, Button, Form, Input } from 'antd';


interface ForgotPasswordFormProps {
  onSendEmail: (email: string) => void;
  errorMessage?: string;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSendEmail, errorMessage }) => {
  const [form] = Form.useForm();

  const onFinish = (values: { email: string }) => {
    onSendEmail(values.email);
  };

  return (
    <>
      <h2>Richiedi codice di reset</h2>

      <Form form={form} onFinish={onFinish} name="forgot_password_form" layout="vertical">
        <Form.Item 
          name="email" 
          rules={[{ required: true, message: 'Inserisci il tuo indirizzo email!' }]}
          label="Email"
        >
          <Input placeholder="Email" />
        </Form.Item>

        <div style={{ minHeight: '50px', margin: '8px 0 16px' }}>
          <Alert
            message={errorMessage || ' '}
            type="error"
            style={{ visibility: errorMessage ? 'visible' : 'hidden', margin: 0 }}
            showIcon
          />
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Invia codice di reset
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
