import React from 'react';
import { Table, Spin } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TyreLocation } from './types/type';
import { WarehouseWithStats } from '../warehouses';


interface TyresLocationTableProps {
  dataSource: TyreLocation[];
  vehiclePlate?: string;
  warehouses: WarehouseWithStats[];
  loading: boolean;
}

export function TyresLocationTable({
  dataSource,
  vehiclePlate,
  warehouses,
  loading,
}: TyresLocationTableProps) {

  const columns: ColumnProps<TyreLocation>[] = [
    {
      title: 'Targa Veicolo',
      dataIndex: 'vehiclePlate',
      key: 'vehiclePlate',
      render: () => vehiclePlate || 'N/D',
    },
    {
      title: 'Nome Magazzino',
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      render: (_, record) => getWarehouseName(record.warehouseId),
    },
    {
      title: 'Nome Rastrelliera',
      dataIndex: 'rackName',
      key: 'rackName',
    },
    {
      title: 'Tipo Rastrelliera',
      dataIndex: 'rackType',
      key: 'rackType',
      render: (rackType) => {
        if (rackType === 'standard') {
          return 'Standard';
        } else if (rackType === 'island') {
          return 'Isola';
        } else {
          return null;
        }
      },
    },
    {
      title: 'Nome Slot',
      dataIndex: 'slotName',
      key: 'slotName',
    },
  ];

  const getWarehouseName = (warehouseId: string) => {
    const warehouse = warehouses.find(warehouse => warehouse.id === warehouseId);
    return warehouse ? warehouse.name : 'N/D';
  };

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: dataSource.length,
        }}
        rowKey="tyreId"
        rowClassName={(record: TyreLocation) => (record.id && `row-${record.id}`)}
        size='small'
      />
    </Spin>
  );
}
