import React from 'react';
import { Form, InputNumber } from 'antd';
import { FormListFieldData } from 'antd/lib';


interface IslandFormProps {
  field: FormListFieldData;
  disabled?: boolean;
  isFirst: boolean;
}

const IslandForm: React.FC<IslandFormProps> = ({ field, disabled, isFirst }) => (
  <>
    <Form.Item
      name={[field.name, 'total']}
      label="Numero Totale Isole"
      rules={isFirst ? [{ required: true, message: "Inserire il numero di isole" }] : []}
    >
      <InputNumber placeholder="Numero di Isole" min={1} style={{ width: '100%' }} disabled={disabled} />
    </Form.Item>
    <Form.Item
      name={[field.name, 'slots']}
      label="Numero Totale Slot"
      rules={isFirst ? [{ required: true, message: "Inserire il numero di slot" }] : []}
    >
      <InputNumber placeholder="Numero di Slot" min={1} style={{ width: '100%' }} disabled={disabled} />
    </Form.Item>
  </>
);

export default IslandForm;
