import React from 'react';
import { Table, Spin, Space, Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { WarehouseWithStats } from './types';


interface WarehousesTableProps {
  dataSource: WarehouseWithStats[];
  loading: boolean;
  onShow: (id: string) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export function WarehousesTable({
  dataSource,
  loading,
  onShow,
  onEdit,
  onDelete,
}: WarehousesTableProps): JSX.Element {

  const columns: ColumnProps<WarehouseWithStats>[] = [
    {
      title: 'Denominazione',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Indirizzo',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Rastrelliere',
      dataIndex: 'total_racks',
      key: 'total_racks',
    },
    {
      title: 'Isole',
      dataIndex: 'total_islands',
      key: 'total_islands',
    },
    {
      title: 'Totale Slot',
      render: (_, record) => record.statistics.total_slots
    },
    {
      title: 'Slot Occupate',
      render: (_, record) => record.statistics.occupied_slots,
    },
    {
      title: 'Slot Libere',
      render: (_, record) => record.statistics.free_slots,
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onShow(record.id)}>Visualizza</Button>
          <Button type="link" onClick={() => onEdit(record.id)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
    }
  ];

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: dataSource.length,
        }}
        rowKey="id"
        rowClassName={(record: WarehouseWithStats) => (record.id && `row-${record.id}`)}
        size='small'
      />
    </Spin>
  );
}
