import React, { useState } from 'react';
import { Row, Col, Card, notification } from 'antd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { updatePassword } from 'aws-amplify/auth';
import { editUser } from '../../api/SettingsApi';
import { userState } from '../../core/user';
import ChangePasswordForm from './forms/ChangePasswordForm';
import EditUserForm from './forms/EditUserForm';
import { ChangePasswordUser, EditUser } from './types';


const SettingsUser: React.FC = () => {
  const user = useRecoilValue(userState);
  const setUser = useSetRecoilState(userState);

  const [loading, setLoading] = useState<boolean>(false);

  const updateUser = async (data: EditUser) => {
    setLoading(true);

    try {
      const editedUser = await editUser(data) as any;

      if (Array.isArray(editedUser) && editedUser.length > 0) {
        const _user = editedUser[0];

        setUser(_user);
      }

      notification.success({
        message: 'Utente Modificato',
        description: 'L\'utente è stato modificato con successo.',
      });
    } catch (error) {
      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante la modifica dell\'utente.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditUserFormSubmit = async (values: EditUser) => {
    await updateUser(values);
  };

  const changePasswordUser = async (data: ChangePasswordUser) => {
    setLoading(true);

    try {
      const result = await updatePassword({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });

      console.log("🚀 ~ changePasswordUser:", result);

      notification.success({
        message: 'Password Cambiata',
        description: 'La tua Password è stata cambiata con successo.',
      });
    } catch (error) {
      console.error('Change Password Failed:', error);

      notification.error({
        message: 'Errore',
        description: 'Si è verificato un errore durante il cambio della password. Prova di nuovo.',
      });

      // setIsChangedPassword(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePasswordFormSubmit = async (values: ChangePasswordUser) => {
    await changePasswordUser(values);
  };

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Card title="Modifica Utente">
          <EditUserForm initialValues={{ name: user?.name || '', surname: user?.surname || '' }} onSubmit={handleEditUserFormSubmit} />
        </Card>
      </Col>
      <Col span={12}>
        <Card title="Cambia Password">
          <ChangePasswordForm onSubmit={handleChangePasswordFormSubmit} />
        </Card>
      </Col>
    </Row>
  );
};

export default SettingsUser;
