import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Empty, Input, List, Spin } from 'antd';
import { fetchWarehouses, searchVehicleDetails, fetchCustomers } from '../api';
import { Vehicle } from '../components/vehicles';
import { TyreLocation, TyresLocationTable, VehicleDetail, VehicleTyreResponse } from '../components/search';
import { WarehouseWithStats } from '../components/warehouses';
import { Customer } from '../components/customers';


export function Search(): JSX.Element {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [vehicleResults, setVehicleResults] = useState<Vehicle | undefined>(undefined);
  const [tyreResults, setTyreResults] = useState<TyreLocation[] | undefined>(undefined);
  const [warehouses, setWarehouses] = useState<WarehouseWithStats[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCustomers, setLoadingCustomers] = useState<boolean>(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchQuery = new URLSearchParams(location.search).get('q');
    console.log("🚀 ~ useEffect ~ searchQuery:", searchQuery)

    if (searchQuery) {
      setQuery(searchQuery);
      fetchSearchResults(searchQuery);
    }
  }, [location.search]);

  const fetchSearchResults = async (query: string) => {
    setLoading(true);

    try {
      const results = await searchVehicleDetails(query);
      const { vehicle, tyres } = results as unknown as VehicleTyreResponse;

      setVehicleResults(vehicle);
      setTyreResults(tyres);
    } catch (error) {
      console.error('Failed to fetch search results:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const _fetchWarehouses = async () => {
      setLoadingWarehouses(true);
      try {
        const response = await fetchWarehouses();
        setWarehouses(response as unknown as WarehouseWithStats[]);
      } catch (error) {
        console.error('Failed to fetch warehouses:', error);
      } finally {
        setLoadingWarehouses(false);
      }
    };

    _fetchWarehouses();
  }, []);

  useEffect(() => {
    const _fetchCustomers = async () => {
      setLoadingCustomers(true);
      try {
        const response = await fetchCustomers('');
        setCustomers(response as unknown as Customer[]);
      } catch (error) {
        console.error('Failed to fetch Customers:', error);
      } finally {
        setLoadingCustomers(false);
      }
    };

    _fetchCustomers();
  }, []);

  const handleSearch = (value: string) => {
    navigate(`/search?q=${value}`);
  };

  const noVehicleResults = !vehicleResults;
  const noTyreResults = !tyreResults || tyreResults.length === 0;

  return (
    <Card title={`Risultati di ricerca`}>
      <Input.Search
        placeholder="Cerca Targa"
        enterButton
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onSearch={handleSearch}
        style={{ marginBottom: 20 }}
      />
      <Spin spinning={loading || loadingWarehouses}>
        {noVehicleResults && noTyreResults ? (
          <Empty description="Nessun veicolo o pneumatico trovato" />
        ) : (
          <>
            {vehicleResults && (
              <VehicleDetail vehicle={vehicleResults} customers={customers} />
            )}

            {tyreResults && tyreResults.length > 0 && (
              <TyresLocationTable 
                dataSource={tyreResults}
                vehiclePlate={vehicleResults?.plate}
                warehouses={warehouses}
                loading={loading}
              />
            )}
          </>
        )}
      </Spin>
    </Card>
  );
}
